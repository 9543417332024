import React, { useState, useEffect, useRef } from 'react';
import { SketchPicker } from 'react-color';
import './ColorPicker.scss';
import { HexToRgb } from "_core/utils/common";

// User Component imports
import {Row, Button, TextInput, Stack, Card, Icon} from "_core/components/common/UI";

export const ColorPicker = (props) => {
  const [displayPalettePicker, setDisplayPalettePicker] = useState(false);
  const [displayPicker, setDisplayPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState(props.default);
  const targetRef = useRef(null); // 対象要素
  const [fixedPosition, setFixedPosition] = useState({ top: 0, left: 0 }); // 座標情報

  const handleColorChange = (color) => {
    setSelectedColor(color.hex);
    props.onchange && props.onchange(color.hex);
  };
  
  const handleColorChangeHEX = (hex) => {
    let color = HexToRgb(hex)
    color.hex = hex
    handleColorChange(color);
  };

  const handleButtonClick = () => {
    // setDisplayPicker(!displayPicker);
    updatePosition();
    setDisplayPalettePicker(!displayPalettePicker);
  };

  const handlePickerButtonClick = () => {
    updatePosition();
    setDisplayPalettePicker(!displayPalettePicker);
    setDisplayPicker(!displayPicker);
  };

  const updatePosition = () => {
    if (targetRef.current) {
      const rect = targetRef.current.getBoundingClientRect(); // 要素の位置を取得
      setFixedPosition({
        top: rect.top,
        left: rect.left,
      });
    }
  };

  useEffect(() => {
    // 初回の座標計算
    updatePosition();

    // スクロールやリサイズ時の座標更新
    window.addEventListener("scroll", updatePosition);
    window.addEventListener("resize", updatePosition);

    // クリーンアップ
    return () => {
      window.removeEventListener("scroll", updatePosition);
      window.removeEventListener("resize", updatePosition);
    };
  }, []);

  const PickButton = (hex) => {
    return (
      <Stack className={'pick-button-area ' + ((selectedColor.toUpperCase() === hex.toUpperCase()) ? "selected" : "")}>
        <button className='pick-button' onClick={() => {handleColorChangeHEX(hex)}} style={{backgroundColor: hex}}/>
        {
          (selectedColor === hex) &&
          <Icon icon="check margin-bottom-4px" className="pick-mark" size={26} />
        }
      </Stack>
    )
  }

  return (
    <div key={props.id} id={props.id} className={`color-picker ${props.className || ''}`}>
      <button
        className='handle-button'
        onClick={handleButtonClick}
        style={{backgroundColor: props.color}}
        ref={targetRef}
      />

      {displayPalettePicker && (
        <div className='picker-palette-area' style={{top : (fixedPosition.top - 95), left : (fixedPosition.left + 60)}}>
          <div className='close-button'
            onClick={() => setDisplayPalettePicker(false)}
          />
          <div className="picker-palette-area-group">
            <div className='picker-palette-shadow'></div>
            <Card className="picker-palette">
              <Stack className="spacing-12px">
                <Row className="spacing-12px">
                  {PickButton("#f54336")}
                  {PickButton("#ea2763")}
                  {PickButton("#9b27b0")}
                  {PickButton("#673ab8")}
                  {PickButton("#2096f3")}
                  {PickButton("#02bcd4")}
                </Row>
                <Row className="spacing-12px">
                  {PickButton("#029688")}
                  {PickButton("#4caf50")}
                  {PickButton("#8bc44a")}
                  {PickButton("#cddd39")}
                  {PickButton("#ffc206")}
                  {PickButton("#ff9800")}
                </Row>
                <Row className="spacing-12px">
                  {PickButton("#795648")}
                  {PickButton("#000000")}
                  {PickButton("#434343")}
                  {PickButton("#cccccc")}
                  {PickButton("#ffffff")}
                  <Stack className='pick-button-area'><button className='pick-button conic' onClick={() => {handlePickerButtonClick()}}/></Stack>
                </Row>
                <Row className="hex-input-area">
                  <Row className='label flex-center align-center text-size-medium'>#</Row>
                  <TextInput 
                    className = "hex-input text-size-light-medium height-full"
                    default={props.default && props.default.replace("#", "").toUpperCase()}
                    value = {selectedColor.replace("#", "").toUpperCase()}
                    onblur = {(e) => {
                      console.log(e.target.value)
                      const hexStr = e.target.value;
                      setSelectedColor("#" + hexStr.toUpperCase());
                      handleColorChangeHEX("#" + hexStr.toUpperCase())
                    }}
                    placeholder="FFFFFF"
                  />
                  <Button
                      className={"hex-input-button primary"}
                      onClick={() => {setDisplayPalettePicker(false)}}>
                      決定
                  </Button>
                </Row>
              </Stack>
            </Card>
          </div>
        </div>
      )}

      {displayPicker && (
        <div className='picker-palette-area' style={{top : (fixedPosition.top - 95), left : (fixedPosition.left + 60)}}>
          <div className='close-button'
            onClick={() => setDisplayPicker(false)}
          />
          <div className="picker-palette-area-group">
            <div className='picker-palette-shadow'></div>
            <Card className="picker-palette analog-picker">
              <Stack className="spacing-16px">
                <SketchPicker
                  color={props.color}
                  onChange={handleColorChange}
                  presetColors={[]}
                  disableAlpha={true}
                />
                <Row className="hex-input-area">
                  <Row className='label flex-center align-center text-size-medium'>#</Row>
                  <TextInput 
                    className = "hex-input text-size-light-medium height-full"
                    default={props.default && props.default.replace("#", "").toUpperCase()}
                    value = {selectedColor.replace("#", "").toUpperCase()}
                    onblur = {(e) => {
                      console.log(e.target.value)
                      const hexStr = e.target.value;
                      setSelectedColor("#" + hexStr.toUpperCase());
                    }}
                    placeholder="FFFFFF"
                  />
                  <Button
                      className={"hex-input-button primary"}
                      onClick={() => {
                        handleColorChangeHEX(selectedColor)
                        handlePickerButtonClick()
                        }}>
                      決定
                  </Button>
                </Row>
              </Stack>
            </Card>
          </div>
        </div>
      )}

    </div>
  );
};

export default ColorPicker;
