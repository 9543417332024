// styles imports
import './Icon.scss';

/*
アイコン表示を管理するコンポーネント
アイコン名は下記サイトに記載
https://icons.getbootstrap.jp/
*/
export default function Icon(props) {
  return (
    <div 
      className={"icon " + (props.className ? props.className : "")} 
      style={{
        width: props.size,
        height: props.size
      }}
      >
      <i className={"bi bi-" + props.icon} style={{fontSize: props.size}}></i>
    </div>
  );
}
