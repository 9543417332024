// ReactからuseEffectとuseStateをインポート
import { useEffect, useState } from 'react'

// メディアクエリを定義するオブジェクト
export const deviceType = {
  mobile:  // スマートフォン用: 画面幅が752px未満
  {
    type : 'Mobile',
    query : 'width < 752px'
  },      
  tablet:  // タブレット用: 画面幅が752px以上、1122px未満
  {
    type : 'Tablet',
    query : '752px <= width < 1122px',  
  },
  desktop:  // PC用: 画面幅が1122px以上
  {
    type : 'Desktop',
    query : '1122px <= width',
  }
}

// メディアクエリに基づいて、マッチするかどうかを返すカスタムフック
export const useMediaQuery = (query) => {
  const formattedQuery = `(${query})`  // メディアクエリを括弧で囲んでフォーマット
  // useStateを使用して、現在のクエリがマッチするかどうかの状態を保持
  const [match, setMatch] = useState(matchMedia(formattedQuery).matches)

  useEffect(() => {
    const mql = matchMedia(formattedQuery) // メディアクエリリスナーを作成

    // メディアクエリが無効な場合はエラーメッセージをコンソールに出力
    if (mql.media === 'not all' || mql.media === 'invalid') {
      console.error(`useMediaQuery Error: Invalid media query`)
    }

    // メディアクエリの結果が変わるたびに、matchの状態を更新
    mql.onchange = (e) => {
      setMatch(e.matches)
    }

    // コンポーネントがアンマウントされる時に、イベントリスナーをクリーンアップ
    return () => {
      mql.onchange = null
    }
  }, [formattedQuery, setMatch]) // 依存配列にformattedQueryとsetMatchを追加

  return match // 現在のマッチ状態を返す
}

// ユーザーエージェントからモバイルデバイスかどうかを判断する関数
const isMobileDevice = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|avantgo|blackberry|bolt|boost|cricket|docomo|fone|hiptop|mini|mobi|palm|phone|pie|plucker|pocket|psp|symbian|treo|up\.browser|up\.link|webos|wince/i.test(userAgent);
};
  
// ユーザーエージェントからタブレットかどうかを判断する関数
const isTabletDevice = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /ipad|playbook|tablet/i.test(userAgent) && !/mobile/i.test(userAgent);
};
  
// デバイスタイプを判定するフック
export const useDeviceType = (type) => {
    const [currentDeviceType, setDeviceType] = useState(deviceType.desktop); // デフォルトはデスクトップ

    useEffect(() => {
        if (isMobileDevice()) {
        setDeviceType(deviceType.mobile);
        } else if (isTabletDevice()) {
        setDeviceType(deviceType.tablet);
        } else {
        setDeviceType(deviceType.desktop);
        }
    }, []);

    return (currentDeviceType.type === type);
};

// デバイスタイプを判定するフック
export const useDevice = () => {
    const isMobileByMedia = useMediaQuery(deviceType.mobile.query);
    const isTabletByMedia = useMediaQuery(deviceType.tablet.query);
    const isDesktopByMedia = useMediaQuery(deviceType.desktop.query);
    
    const isMobileByAgent = isMobileDevice();
    const isTabletByAgent = isTabletDevice();

    // 状態を更新するためのフック
    const [device, setDevice] = useState("Desktop");

    useEffect(() => {
        if ((isMobileByMedia || isMobileByAgent) && !isTabletByAgent) {
            setDevice(deviceType.mobile.type);
        } else if (isTabletByMedia || isTabletByAgent) {
            setDevice(deviceType.tablet.type);
        } else if (isDesktopByMedia) {
            setDevice(deviceType.desktop.type);
        }
    }, [isMobileByMedia, isTabletByMedia, isDesktopByMedia, isMobileByAgent, isTabletByAgent]);

    return device; // 現在のデバイスタイプを文字列として返す
}
