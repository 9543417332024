// library imports
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// User Component imports
import dataManagerInstance from '_core/managers/DataManager';
import { setLoading, setMenuState, setErrorModal, setInitializeState, setCommonModal } from "_core/slices/scene/SceneSlice";
import { PageContent, PageHeader, PageSideContents, PageWrapper, PageContainer } from 'components/common/layout_element/LayoutElement';
import { InfoCard } from 'components/common/parts';
import { Button, Icon, Radio, Row, Stack, TextInput, Card, CommonModal } from "_core/components/common/UI";
import PlanCard from "./PlanCard";
import * as Constants from "_core/configs/Constants";

import PaymentInfoApi from "apis/PaymentInfoApi";
import PaymentContractApi from "apis/PaymentContractApi";
import PaymentContractChangeApi from "apis/PaymentContractChangeApi";

// styles imports
import './PaymentPlan.scss';
import * as SceneConfig from "_core/configs/SceneConfig";

const PaymentPlan = () => {
    const { mPayment } = useSelector(store => store.master)
    const { tPayment, tSubScription } = useSelector(store => store.transaction)
    const { initializeState } = useSelector((store) => store.scene);
    const [ modalInfo , setModalInfo] = useState( {
        show: false,
        text: '',
        onClick: null,
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ExecPaymentInfoApi = PaymentInfoApi();
    const ExecPaymentContractApi = PaymentContractApi();
    const ExecPaymentContractChangeApi = PaymentContractChangeApi();

    const [planList, setPlanList] = useState([
        {
            mPayment : null,
            tPayment : null,
            onClick : null,
            isLoading : (initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false
        },
        {
            mPayment : null,
            tPayment : null,
            onClick : null,
            isLoading : (initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false
        },
        {
            mPayment : null,
            tPayment : null,
            onClick : null,
            isLoading : (initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false
        },
    ]);

    useEffect(() => {
        dispatch(setMenuState({ isHeader: false, isMenu: true, isFooter: false, isFullScreen: false }));
    }, [dispatch]);

    useEffect(() => {
        if(initializeState !== Constants.INITIALIZE_STATE.allComplete)
        {
            return
        }

        if(mPayment)
        {
            dispatch(setInitializeState(Constants.INITIALIZE_STATE.pageDataFetchComplete))
            return
        }

        dispatch(setInitializeState(Constants.INITIALIZE_STATE.pageDataFetching))
        GetInfo();

    }, [initializeState]);

    const GetInfo = async () => {
        dispatch(setLoading(true));
        const result = await ExecPaymentInfoApi({});
        // 通信中の表示を消す
        dispatch(setLoading(false));
        if (result.error || (result.response && result.response.error_code > 0)) {
            // エラーモーダル表示
            dispatch(setErrorModal({ show: true, text: result.response.error_detail }));
        }
        else
        {
            dispatch(setInitializeState(Constants.INITIALIZE_STATE.pageDataFetchComplete))
        }
    }

    const handlePlanClick = async (planId) => {
        const currentTSubScription = dataManagerInstance.getTSubscription();

        const text = `\
プランを変更してもよろしいですか？\n\
契約中でのプラン変更を行った場合、請求日までの\n残り期間に基づき日割りでの請求が行われます。\n\
上位のプランでしか使用できない見た目の\n使用中は下位のプランへの変更は行うことができません。
        `;
        const startText = `\
プランを開始してもよろしいですか？\n\
プラン開始後、１ヶ月ごとに請求が行われます。\n\
契約中でのプラン変更を行った場合、請求日までの残り期間に基づき日割りでの請求が行われます。
        `;

        if(currentTSubScription)
        {
            setModalInfo({ show: true, title: "プラン変更", text: text, onclick: () => {
                CoStartPaymentContractChangeApi(planId);
            }});
        }
        else
        {
            setModalInfo({ show: true, title: "プラン開始", text: text, onclick: () => {
                CoStartPaymentContractApi(planId);
            }});
        }
    }

    const CoStartPaymentContractApi = async (planId) => {
        await StartPaymentContractApi(planId);
    }

    const StartPaymentContractApi = async (planId) => {
        dispatch(setLoading(true));
        const result = await ExecPaymentContractApi({planId:planId});
        // 通信中の表示を消す
        dispatch(setLoading(false));
        if (result.error || (result.response && result.response.error_code > 0)) {
            // エラーモーダル表示
            dispatch(setErrorModal({ show: true, text: result.response.error_detail }));
        }
        else
        {
            
        }
    }

    const CoStartPaymentContractChangeApi = async (planId) => {
        await StartPaymentContractChangeApi(planId);
    }

    const StartPaymentContractChangeApi = async (planId) => {
        dispatch(setLoading(true));
        const result = await ExecPaymentContractChangeApi({planId:planId});
        // 通信中の表示を消す
        dispatch(setLoading(false));
        if (result.error || (result.response && result.response.error_code > 0)) {
            // エラーモーダル表示
            dispatch(setErrorModal({ show: true, text: result.response.error_detail }));
        }
        else
        {
            
        }
    }

    useEffect(() => {
        if(! mPayment)
        {
            return;
        }
        const planListTemp = mPayment.map((mPayment) => {

            return {
                mPayment : mPayment,
                tPayment : tPayment,
                onClick : handlePlanClick,
                isLoading : (initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false
            }
        });

        setPlanList(planListTemp);
    }, [mPayment, tPayment, initializeState]);

    return (
    <PageContent pageId="PaymentPlan">
        <PageHeader>
            {/* ここにページ内ヘッダー部分のコンポーネントを記述 */}
            <Stack className="spacing-4px">
                <div className="page-header-title">プラン設定</div>
                <div className="page-header-detail">現在登録しているプランの確認/変更を行います</div>
            </Stack>
        </PageHeader>
        <PageWrapper>
            <PageContainer>
                <Card className="base-card">
                    <Stack className="spacing-16px">
                        <Row className="flex-center">
                        </Row>
                        <Row className="card-list spacing-16px flex-center">
                            {
                                //プランリストの表示
                                (planList && planList.length > 0) &&
                                planList.map((plan) => {
                                    return (
                                        <PlanCard {...plan}/>
                                    )
                                })
                            }
                        </Row>

                        <div></div>
                        <InfoCard> 
                            { ! tPayment &&
                                <div>利用開始にはクレジットカードの登録が必要です。</div>
                            }
                            <div>契約中でのプラン変更を行った場合、請求日までの残り期間に基づき日割りでの請求が行われます。</div>
                            <div>上位のプランでしか使用できない見た目の使用中は下位のプランへの変更は行うことができません。</div>
                        </InfoCard>
                    </Stack>
                </Card>
            </PageContainer>
        </PageWrapper>
        <CommonModal modalInfo={modalInfo} setModalInfo={setModalInfo}></CommonModal>
    </PageContent>
    );
};

export default PaymentPlan;