import React, { useEffect } from 'react';

import './DateRadio.scss'
import Icon from '../../../_core/components/common/UI/Icon';
import Row from '../../../_core/components/common/UI/Row';

export const Comment = `
/**
 *
 * props の定義
 *
 * [data-*]
 * 		dataset			... [act] で指定の処理から e.target.dataset.json として呼び出したいJSON文字列
 *
 * [set]
 *    	key           		...	key監視対象
 *		name				...	ラジオボタンに設定する id、および name 属性の指定の文字列
 *		value				... 項目として設定する内容
 *		layout 				... horizontal or vertical ラジオボタンのレイアウトタイプ
 *		class 				... ラジオボタンに適用するクラス名
 *
 * [act]
 * 		onchange			...	選択項目変更時に実行したい処理
 *
 *　例)
 *		<Radio
 *	 		name="radio"
 *	 		value={Object.keys(Constants.CHECK_VALUES)}
 *	 		title={Object.values(Constants.CHECK_VALUES)}
 *	 		default={0}
 *	 		onchange={onChange}
 *			className="radio"
 *			type="horizontal"
 * 		/>
 *
 */
`

export const DateRadio = (props) => {
	// 追加処理があれば実行
	const onChange = (e) => props.onchange && ((props.dataset) ? props.onchange(e) : props.onchange(e.target.value))
	const onClick = (e) => props.onclick && ((props.dataset) ? props.onclick(e) : props.onclick(e.target.value))

	// コンポーネントの返却
	return (
		<div id="date-radio" className={"date-radio-group"+ " " + (props.type && props.type) + " " + (props.className && props.className) + " "}>
			<Row className="spacing-8px">
				{props.value.map((value,index) => (
					<div key={index} className={"date-radio-wrapper radio-"+props.name}>
						{props.isLoading ? 
						<div className='date-radio-input loading'></div>
						: 
						<input
							className={"date-radio-input"}
							type="radio"
							name={props.name}
							id={props.name+index}
							value={value}
							checked={props.checked == value}
							disabled={props.disabled}
							/* act */
							onClick={onClick}
							onChange={onChange}
						/>
						}
						<label className="date-radio-label" htmlFor={props.name + index}>
							<Row className="spacing-4px">
								<span className="label-diff-value">{props.diffValue[index]}</span>
								{index === props.value.length - 1 && (
								<Icon className="label-icon" icon="chevron-down" size={14} />
								)}
							</Row>
						</label>
					</div>
				))}
			</Row>
		</div>
	);
}
export default DateRadio
