// library imports
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PageContent, PageHeader, PageWrapper, PageContainer } from 'components/common/layout_element/LayoutElement';
import * as Constants from "_core/configs/Constants";
import * as SceneConfig from "_core/configs/SceneConfig";

// User Component imports
import {Row, Button, TextInput, Stack, ColorPicker, Uploader, Card, Icon, Video, Stepper} from "_core/components/common/UI";
import { setLoading, setMenuState, setErrorModal, setInitializeState, setSceneParam } from "_core/slices/scene/SceneSlice";

// styles imports
import './AdminCharacterCreate.scss';

const AdminCharacterCreate = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { tUser } = useSelector((store) => store.transaction);
    const { tUserAdd } = useSelector((store) => store.transaction);
    const { tAccount } = useSelector((store) => store.transaction);
    const { tAppearanceList } = useSelector((store) => store.transaction);
    const { tAssetList } = useSelector((store) => store.transaction);
    const { initializeState } = useSelector((store) => store.scene);

    const [gender, setGender] = useState(0);
    const [imageId, setImageId] = useState(0);
    const [voiceId, setVoiceId] = useState(0);
    const [maleAppearanceList, setMaleAppearanceList] = useState([]);
    const [femaleAppearanceList, setFemaleAppearanceList] = useState([]);
    const [stepList, setStepList] = useState([]);


    useEffect(() => {
        dispatch(setMenuState({ isHeader: false, isMenu: true, isFooter: false, isFullScreen: false }));
    }, [dispatch]);

    useEffect(() => {
        const stepListTemp = [
            {
                isEnabled : gender > 0,
                label: "性別設定"
            },
            {
                isEnabled : imageId > 0,
                label: "見た目設定"
            },
            {
                isEnabled : voiceId > 0,
                label: "ボイス設定"
            }
        ]
        setStepList(stepListTemp)
    }, [gender, imageId, voiceId]);

    useEffect(() => {
        if(initializeState != Constants.INITIALIZE_STATE.allComplete) {
            return
        }
        // 初期設定
        dispatch(setInitializeState(Constants.INITIALIZE_STATE.pageDataFetchComplete))

        // リストのセット
        if (tAppearanceList)
        {
            setMaleAppearanceList(tAppearanceList.filter(tAppearance => tAppearance.gender === Constants.GENDER_TYPE.MALE))
            setFemaleAppearanceList(tAppearanceList.filter(tAppearance => tAppearance.gender === Constants.GENDER_TYPE.FEMALE))
        }
        
    }, [initializeState]);

    const getVideoButton = (tAppearance) => {
        const assetInfo = getAssetInfo(tAppearance)

        return (
            <Row className={"video-select select-option " + (imageId === assetInfo.tAppearance.id ? "selected" : "")}>
                <Button className="video-button hidden width-full" onClick={() => {setImageId(assetInfo.tAppearance.id)}}>
                    <Stack className="height-full width-full">
                        <Video className="horizontal-fit" src={assetInfo.idleSasUrl}/>
                    </Stack>
                </Button>
                <Stack className="check-mark">
                    <Icon className="label-icon" icon="check" size={20} />
                </Stack>
            </Row>
        )
    }

    // 見た目情報の関連情報を全て取得
    const getAssetInfo = (tAppearance) => {
        if (! tAppearance)
        {
            return
        }
        if (! tAssetList)
        {
            return
        }

        const idleTAsset = tAssetList.find(tAsset => tAsset.id === tAppearance.idle_asset)
        const idleSasUrl = getVideoUrl(idleTAsset.id)

        const talkTAsset = tAssetList.find(tAsset => tAsset.id === tAppearance.talk_asset)
        const talkSasUrl = getVideoUrl(talkTAsset.id)

        // 見た目情報に関する情報を収集して一つのオブジェクトとして返却する
        return {
            tAppearance : tAppearance,
            idleTAsset : idleTAsset,
            idleSasUrl : idleSasUrl,
            talkTAsset : talkTAsset,
            talkSasUrl : talkSasUrl,
        }
    }

    // IDLE動画変更検知
    const getVideoUrl = (tAssetId) => {
        try {
            const selectedIdleTAsset = tAssetList.find(tAsset => tAsset.id === tAssetId)
            let sasUrl = ""
            if (selectedIdleTAsset)
            {
                sasUrl = selectedIdleTAsset.sas
            }

            // videoUrlを設定
            return sasUrl
        } catch (error) {
            return null
        }
    };

    const getSoundButton = (selectedVoiceId, label) =>{
        return (
            <Row className={"width-full select-option " + (voiceId === selectedVoiceId ? "selected" : "")}>
                <Button
                    className={"long-button secondary-border"}
                    onClick={() => setVoiceId(selectedVoiceId)}
                >
                    <Row className="spacing-8px">
                        <Stack className="width-full flex-center align-center">
                            <div>{label}</div>
                        </Stack>
                        <Button className={"sound-button hidden"}>
                            <Stack className="flex-center align-center">
                                <Icon icon="volume-up" className="margin-bottom-4px" size={24} />
                            </Stack>
                        </Button>
                    </Row>
                </Button>
                <Stack className="check-mark">
                    <Icon className="label-icon" icon="check" size={20} />
                </Stack>
            </Row>
        )
    }

    const execCreate = () => {
        dispatch(setSceneParam({ selectedTAppearance : imageId }));
        navigate(SceneConfig.PAGES.PARTS_ADMIN_USER_PROFILE_EDIT);
    }

    const handleGenderChange = (changedGender) => {
        if (gender == changedGender)
        {
            return
        }

        setGender(changedGender)
        setImageId(0)
        setVoiceId(0)
    }

    return (
        <PageContent pageId="AdminCharacterCreate">
            <PageHeader>
                    <Stack className="spacing-4px">
                        <div className="page-header-title">広報担当作成</div>
                        <div className="page-header-detail">簡単な選択肢に答えて、好みの広報担当AIを作成できます</div>
                    </Stack>
            </PageHeader>
            <PageWrapper>
                <PageContainer>
                    <Stack className="stepper-wrapper padding-16px align-center width-full">
                        <Stepper stepList={stepList}></Stepper>
                    </Stack>
                    <Stack className="spacing-8px align-center">
                        <Card className="base-card create-select-area show">
                            <Stack className="spacing-16px">
                                <Stack className="spacing-8px">
                                    <Row className= "flex-both">
                                        <Row className="card-title-row">
                                            <div className="card-line"></div>
                                            <div className="card-title">性別設定</div>
                                        </Row>
                                        {(gender > 0) ? 
                                            <Button
                                                className="cancel-button width-150px hidden"
                                                onClick={() => {
                                                    setGender(0)
                                                    setImageId(0)
                                                    setVoiceId(0)
                                                }}
                                            >
                                                <Row className= "align-center spacing-16px">
                                                    <Icon
                                                        icon="x"
                                                        size={24}
                                                    />
                                                    <div>取り消し</div>
                                                </Row>
                                            </Button>
                                            :
                                            <div></div>
                                        }
                                    </Row>
                                    <div>広報担当の性別を設定します。</div>
                                </Stack>
                                <Row className= "input-row-area align-center spacing-16px">
                                    <Stack className={"select-option " + (gender === Constants.GENDER_TYPE.FEMALE ? "selected" : "")}>
                                        <Button
                                            className={"width-240px secondary-border"}
                                            onClick={() => handleGenderChange(Constants.GENDER_TYPE.FEMALE)}
                                        >
                                            女性
                                        </Button>
                                        <Stack className="check-mark">
                                            <Icon className="label-icon" icon="check" size={20} />
                                        </Stack>
                                    </Stack>
                                    <Stack className={"select-option " + (gender === Constants.GENDER_TYPE.MALE ? "selected" : "")}>
                                        <Button
                                            className={"width-240px secondary-border"}
                                            onClick={() => handleGenderChange(Constants.GENDER_TYPE.MALE)}
                                        >
                                            男性
                                        </Button>
                                        <Stack className="check-mark">
                                            <Icon className="label-icon" icon="check" size={20} />
                                        </Stack>
                                    </Stack>
                                </Row>
                            </Stack>
                        </Card>
                        
                        <Row className="width-full flex-center align-center">
                            <Icon className={"step-icon " + ((gender > 0) ? "show" : "")} icon="caret-down-fill" size={32}/>
                        </Row>

                        <Card className={"base-card create-select-area " + ((gender > 0) ? "show" : "")}>
                            <Stack>
                                <Stack className="spacing-8px margin-bottom-16px">
                                    <Row className= "flex-both">
                                        <Row className="card-title-row">
                                            <div className="card-line"></div>
                                            <div className="card-title">見た目設定</div>
                                        </Row>
                                        {(imageId > 0) ? 
                                            <Button
                                                className="cancel-button width-150px hidden"
                                                onClick={() => {
                                                    setImageId(0)
                                                    setVoiceId(0)
                                                }}
                                            >
                                                <Row className= "align-center spacing-16px">
                                                    <Icon
                                                        icon="x"
                                                        size={24}
                                                    />
                                                    <div>取り消し</div>
                                                </Row>
                                            </Button>
                                            :
                                            <div></div>
                                        }
                                    </Row>
                                    <div>広報担当の見た目を設定します。</div>
                                </Stack>
                                <Row className= {"input-row-area align-center spacing-16px create-select-row " + ((gender == Constants.GENDER_TYPE.MALE) ? "show" : "")}>
                                        {maleAppearanceList &&
                                            maleAppearanceList.map((tAppearance) => (
                                                getVideoButton(tAppearance)
                                            ))
                                        }
                                </Row>
                                <Row className={"input-row-area align-center spacing-16px create-select-row " + ((gender == Constants.GENDER_TYPE.FEMALE) ? "show" : "")}>
                                        {femaleAppearanceList &&
                                            femaleAppearanceList.map((tAppearance) => (
                                                getVideoButton(tAppearance)
                                            ))
                                        }
                                </Row>
                            </Stack>
                        </Card>

                        <Row className="width-full flex-center align-center">
                            <Icon className={"step-icon " + ((gender > 0 && imageId > 0)  ? "show" : "")} icon="caret-down-fill" size={32}/>
                        </Row>

                        <Card className={"base-card create-select-area " + ((gender > 0 && imageId > 0) ? "show" : "")}>
                            <Stack className="spacing-8px">
                                <Stack className="spacing-8px">
                                    <Row className= "flex-both">
                                        <Row className="card-title-row">
                                            <div className="card-line"></div>
                                            <div className="card-title">ボイス設定</div>
                                        </Row>
                                        {(voiceId > 0) ? 
                                            <Button
                                                className="cancel-button width-150px hidden"
                                                onClick={() => {
                                                    setVoiceId(0)
                                                }}
                                            >
                                                <Row className= "align-center spacing-16px">
                                                    <Icon
                                                        icon="x"
                                                        size={24}
                                                    />
                                                    <div>取り消し</div>
                                                </Row>
                                            </Button>
                                            :
                                            <div></div>
                                        }
                                    </Row>
                                    <div>広報担当のボイスを設定します。</div>
                                </Stack>
                                {gender === 2 &&
                                    <Row className= "input-row-area align-center spacing-16px">
                                        {getSoundButton(1, "女性1")}
                                        {getSoundButton(2, "女性2")}
                                        {getSoundButton(3, "女性3")}
                                        {getSoundButton(4, "女性4")}
                                    </Row>
                                }
                                {gender === 1 &&
                                    <Row className= "input-row-area align-center spacing-16px">
                                        {getSoundButton(1, "男性1")}
                                        {getSoundButton(2, "男性2")}
                                        {getSoundButton(3, "男性3")}
                                        {getSoundButton(4, "男性4")}
                                    </Row>
                                }
                            </Stack>
                        </Card>

                        <Row className="width-full flex-center align-center">
                            <Icon className={"step-icon " + ((gender > 0 && imageId > 0 && voiceId > 0)  ? "show" : "")} icon="caret-down-fill" size={32}/>
                        </Row>

                        {(gender > 0 && imageId > 0 && voiceId > 0) && 
                            <Row className= "button-row align-center flex-center spacing-32px">
                                <Button
                                    className={"width-240px primary"}
                                    onClick={() => execCreate()}
                                >
                                    作成
                                </Button>
                            </Row>
                        }
                    </Stack>
                </PageContainer>
            </PageWrapper>
        </PageContent>
    );
};

export default AdminCharacterCreate;