// library imports
import { createSlice } from "@reduxjs/toolkit";

import { timeCurrentIso8601 } from "_core/utils/common";
import * as Constants from "_core/configs/Constants";

// 初期化
const saveKey = Constants.APP_KEY + "-" + Constants.SaveAccount;
const saveData = localStorage.getItem(saveKey);

const initialState = saveData
    ? JSON.parse(saveData)
    : {
        isLogin: true,
        profile: "名前: さくら\n性別: 女性\n職業: プログラマ\n年齢: 23歳\n出身地: 東京都\n現在の住まい: 一人暮らし",
        lastdate: timeCurrentIso8601()
    };

const AccountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {

        /**
         * プロフィール更新
         * payload: string  新しいprofile
         */
        editAccount: (state, action) => {
            state.profile = action.payload;
            state.lastdate = timeCurrentIso8601();

            // 変更を保存
            const strings = JSON.stringify(state);
            localStorage.setItem(saveKey, strings);
        }
    },
});

export const { editAccount } = AccountSlice.actions;
export default AccountSlice.reducer;