// library imports
import { configureStore } from "@reduxjs/toolkit";

// User Component imports
import MasterReducer from "_core/slices/datas/MasterSlice";
import TransactionReducer from "_core/slices/datas/TransactionSlice";
import SceneReducer from "_core/slices/scene/SceneSlice";
import AccountReducer from "features/account/AccountSlice";


export const store = configureStore({
    reducer: {
        scene: SceneReducer,
        master: MasterReducer,
        transaction: TransactionReducer,
        account: AccountReducer,
    },
});

