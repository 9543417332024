// library imports
import { useNavigate, useLocation  } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// User Component imports
import { Button, Icon, Stack, Row } from "_core/components/common/UI";
import * as SceneConfig from "_core/configs/SceneConfig";
import { deviceType, useDevice } from '_core/managers/ResponsiveManager';
import { setMenuState, setInitializeState } from '_core/slices/scene/SceneSlice';
import * as Constants from "_core/configs/Constants";
import logo from 'images/common/app_logo_transparent.png';

// styles imports
import './Menu.scss';

function GetNavigatePath(destination){
  return "/"+ destination.join("/")
}

// メインのコンポーネント定義
export default function Menu(props) {
  const { scene, parts, isMenu, isMenuOpen } = useSelector((store) => store.scene);
  const { initializeState } = useSelector((store) => store.scene);
  const { tAccount } = useSelector((store) => store.transaction);
  const device = useDevice();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChangeMenu = () => {
    dispatch(setMenuState({ isMenuOpen: !isMenuOpen }));
  };

  const onClickSceneButton = (destination) => {
    dispatch(setInitializeState(Constants.INITIALIZE_STATE.ready))
    navigate(GetNavigatePath(destination));
  };

  if (! isMenu && initializeState > Constants.INITIALIZE_STATE.allComplete)
    return (
      <Stack className="menu-root">
      </Stack>
    );
  
  return (
    <div className={"menu-drawer " + (isMenuOpen && "open shadow-ease")} isMenuOpen={isMenuOpen}>
      <Stack className="flex-both height-full">
        <Stack>
          {! (device === deviceType.mobile.type ) && 
            <div className="menu-header">
              <Row className={"align-center " + isMenuOpen ? "flex-both" : "flex-center"}>
                <img src={logo} alt="" className="logo"/>
                <Button className={"menu-switch-button hidden flex align-center " + (isMenuOpen ? "flex-right" : "flex-center")} onClick={onChangeMenu}> 
                    <Icon icon={isMenuOpen ? "chevron-left" : "chevron-right"} size={16} />
                </Button>
              </Row>
            </div>
          }
          <div className="menu-list">
              <MenuItem title="基本情報"   isText={isMenuOpen} icon="info-circle"                 onClick={onClickSceneButton} destination={[SceneConfig.SCENE_ADMIN, SceneConfig.PARTS_ADMIN_USEREDIT]}></MenuItem>
              <MenuItem title="広報担当作成" isText={isMenuOpen} icon="person-plus-fill"          onClick={onClickSceneButton} destination={[SceneConfig.SCENE_ADMIN, SceneConfig.PARTS_ADMIN_CHARACTER_CREATE]}></MenuItem>
              <MenuItem title="表示設定"   isText={isMenuOpen} icon="person-vcard"                onClick={onClickSceneButton} destination={[SceneConfig.SCENE_ADMIN, SceneConfig.PARTS_ADMIN_USER_PROFILE_EDIT]}></MenuItem>
              <MenuItem title="RAG設定"   isText={isMenuOpen} icon="file-earmark-text"            onClick={onClickSceneButton} destination={[SceneConfig.SCENE_ADMIN, SceneConfig.PARTS_ADMIN_USER_LEARNING_EDIT]}></MenuItem>
              <MenuItem title="ログ"   isText={isMenuOpen} icon="chat-square-text"                onClick={onClickSceneButton} destination={[SceneConfig.SCENE_ADMIN, SceneConfig.PARTS_ADMIN_USER_CHATLOG]}></MenuItem>
              <MenuItem title="プラン設定"   isText={isMenuOpen} icon="currency-exchange"         onClick={onClickSceneButton} destination={[SceneConfig.SCENE_PAYMENT, SceneConfig.PARTS_PAYMENT_PLAN]}></MenuItem>
              <MenuItem title="お支払い設定" isText={isMenuOpen} icon="credit-card"               onClick={onClickSceneButton} destination={[SceneConfig.SCENE_PAYMENT, SceneConfig.PARTS_PAYMENT_SETTING]}></MenuItem>
              <MenuItem title="請求書" isText={isMenuOpen} icon="list-check"                      onClick={onClickSceneButton} destination={[SceneConfig.SCENE_PAYMENT, SceneConfig.PARTS_PAYMENT_HISTORY]}></MenuItem>
          </div>
        </Stack>
          <Stack className="edge-group align-center">
            {isMenuOpen ? 
              <div className="logout-item"> 
                <Button className="hidden flex flex-both no-padding" onClick={() => onClickSceneButton([SceneConfig.SCENE_ACCOUNT, SceneConfig.PARTS_TOP])}>
                  <div className="logout-item-icon-area padding-12px flex flex-center">
                    <Icon icon="box-arrow-left" size={24} />
                  </div>
                  <div className="logout-label padding-12px">ログアウト</div>
                </Button>
              </div>
              :
              <div className="logout-item shrink"> 
                <Button className="hidden flex flex-both no-padding" onClick={() => onClickSceneButton([SceneConfig.SCENE_ACCOUNT, SceneConfig.PARTS_TOP])}>
                  <div className="logout-item-icon-area padding-12px flex flex-center">
                    <Icon icon="box-arrow-left" size={24} />
                  </div>
                </Button>
              </div>
            }
          </Stack>
      </Stack>
    </div>
  );
}

// 子要素のコンポーネント定義

function MenuItem(props) {
  const location = useLocation();
  return (
    <div className={"menu-item " + ((GetNavigatePath(props.destination) === location.pathname) ? "selected" : "")}> 
      <Button className="hidden flex flex-both no-padding" onClick={() => props.onClick(props.destination)}>
        <div className="menu-item-icon-area padding-12px flex flex-center">
          <Icon icon={props.icon} size={24} />
        </div>
        <div className="padding-12px">{props.title}</div>
      </Button>
    </div>
  );
}
