import { useEffect, useState } from 'react'

import { setCommonModal } from '../../../slices/scene/SceneSlice';
import { Stack, Modal, HTMLRenderer, Text, Icon } from "_core/components/common/UI";

import './CommonModal.scss'
import Row from './Row';

const CommonModal = (props) => {
    const [modalInstance, setCommonModalInstance] = useState({ class: '', body: <></> });

    useEffect(() => {
        UpdateModal();
    }, [props.modalInfo.show]);

    // 編集モーダルを開く
	const UpdateModal = () => {
		setCommonModalInstance({
			class: props.modalInfo.show ? 'show' : '',
			body: modalBody()
		});
	};

       //モーダルのボディ
	const modalBody = () => {

        let modalTextElement = "";
        if (props.modalInfo.text){
            modalTextElement = props.modalInfo.text.replace(/\r\n/g, '\n').replace(/\r/g, '\n').replace(/\r/g, '</br>')
            console.log(modalTextElement);
        }

		return(
            <Stack className="common-modal-message spacing-16px padding-16px">
                <Row className="spacing-16px">
                    <Stack className="spacing-8px">
                        <HTMLRenderer htmlContent={modalTextElement}></HTMLRenderer>
                    </Stack>
                </Row>
            </Stack>
		)
	}

    const handleOnclick = () => {
        console.log("クリック処理を実行");

        if (props.modalInfo.onclick)
        {
            props.modalInfo.onclick()
        }
        props.setModalInfo({ show: false, title: "", text: "", onClick: null});
    }

    function ShowModal() {
        return (
            <Modal
                className={modalInstance.class}
                dialogclass="modal-dialog-centered"
                name="common-modal"
                title={props.modalInfo.title}

                showClose={false}
                close={() => {props.setModalInfo({ show: false, title: "", text: "", onClick: null});}}
                
                showCancel={true}
                cancel={() => {props.setModalInfo({ show: false, title: "", text: "", onClick: null});}}
                cancelbtnclass="secondary-border"
                cancelvalue={"キャンセル"}

                showConfirm={true}
                confirm={() => {handleOnclick()}}
                confirmbtnclass="primary"
                confirmvalue={"確認"}
                
                body={modalInstance.body}
            />
        );
    }

    return <ShowModal />
}

export default CommonModal