// library imports
import { useDispatch } from "react-redux";

// User Component imports
import { ApiRequest } from "_core/apis/ApiRequest";
import { setMasterData } from "_core/slices/datas/MasterSlice";
import { setApiTimestamp, setSid, setAdminSid, setTransactionData } from "_core/slices/datas/TransactionSlice";


const UsersLoginApi = () => {
    const dispatch = useDispatch();

    // API固有の設定、URLとリクエスト
    const url = "/users/login";

    // API実行
    const ExecUsersLoginApi = async ({ email, password }) => {
        // 必要なリクエストを定義
        const requestParams = {
            email: email,
            password: password
        };

        let response = null;
        let error = null;
    
        // console.log(url + "API開始");
        try {
            response = await ApiRequest({ url, requestParams, auth:false });
            // console.log(response);
        } catch (e) {
            // console.log(e);
            error = e;
        } finally {
            // console.log(url + "　API終了");
        }

        // レスポンスがあれば、データ更新
        if (response !== null && response.error_code === 0) {
            dispatch(setApiTimestamp(response['now_time']));
            dispatch(setSid(response['sid']));
            dispatch(setAdminSid(response['sid']));
            dispatch(setTransactionData({
                tUser:response['t_user'],
                tUserAdd:response['t_user_add'],
                tAccount:response['t_account'],
                tAppearanceList:response['t_appearance_list'],
                tAssetList:response['t_asset_list']
            }
            ));
        }
        return { response, error };
    };

    return ExecUsersLoginApi;
};

export default UsersLoginApi;