import React from 'react';
import Button from '../../../_core/components/common/UI/Button';
import Row from '../../../_core/components/common/UI/Row';
import TextInput from "../../../_core/components/common/UI/TextInput";


export const DocsItem = (props) => {
    const onDownload = () => props.onDownload && props.onDownload();
    const onDelete = () => props.onDelete && props.onDelete();

    return (
        <Row className={"docsitem "+(props.RowClassName ? props.RowClassName : "")}>
            <div className={(props.LabelClassName ? props.LabelClassName : "")}>{props.label}</div>
            <TextInput
                className={props.TextInputClassName}
                disabled={true}
                value={props.value}
                default={props.value}
                isLoading={props.isLoading}
            />
            <Button className={"primary "+(props.DlClassName ? props.DlClassName : "")} onClick={onDownload}>DL</Button>
            <Button className={"danger "+(props.DelClassName ? props.DelClassName : "")} onClick={onDelete}>削除</Button>
        </Row>
    );
};

export default DocsItem;
