// library imports
import { Route, Routes } from "react-router-dom";

// User Component imports
import * as SceneConfig from "_core/configs/SceneConfig";
import AccountLogin from "./parts/AccountLogin";
import AccountSignup from "./parts/AccountSignup";
import AccountTop from "./parts/AccountTop";
// import AccountChangePassword from "./parts/AccountChangePassword";
// import AccountResetPassword from "./parts/AccountResetPassword";
// import AccountCompleteChangePassword from "./parts/AccountCompleteChangePassword";
// import AccountCompleteSendMail from "./parts/AccountCompleteSendMail";

const AccountScene = () => {

    return (
        <div id="Account" className="scene-parent-root">
            <Routes>
                <Route path='' element={<AccountTop />} />
                <Route path={SceneConfig.PARTS_TOP} element={<AccountTop />} />
                <Route path={SceneConfig.PARTS_ACCOUNT_LOGIN} element={<AccountLogin />} />
                <Route path={SceneConfig.PARTS_ACCOUNT_SIGNUP} element={<AccountSignup />} />
                {/* <Route path={SceneConfig.PARTS_ACCOUNT_RESET_PASSWORD} element={<AccountResetPassword />} />
                <Route path={SceneConfig.PARTS_ACCOUNT_CHANGE_PASSWORD} element={<AccountChangePassword />} />
                <Route path={SceneConfig.PARTS_ACCOUNT_COMPLETE_CHANGE_PASSWORD} element={<AccountCompleteChangePassword />} />
                <Route path={SceneConfig.PARTS_ACCOUNT_COMPLETE_SEND_MAIL} element={<AccountCompleteSendMail />} /> */}
            </Routes>
        </div>
    );
};

export default AccountScene;