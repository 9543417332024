import * as Constants from "_core/configs/Constants";

export const GetStorageKey = (key) => {
    return Constants.APP_KEY + "-" + key;
};

export const GetItem = (key) => {
    if(! (typeof key === 'string' && key.length > 0))
    {
        console.log("ストレージキーが指定されていません")
        return ""
    }

    const appKey = GetStorageKey(key);
    return localStorage.getItem(appKey)
};

export const SetItem = (key, value) => {
    if(! (typeof key === 'string' && key.length > 0))
    {
        console.log("ストレージキーが指定されていません")
        return ""
    }

    const appKey = GetStorageKey(key);
    return localStorage.setItem(appKey, String(value))
};