import React , { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import 'react-image-crop/dist/ReactCrop.css';
import { Button, List, Row, Stack, TextInput } from "_core/components/common/UI";
import './Modal.scss'

export const Comment = `
	/**
	 *
	 * props の定義
	 *
	 * [data-*]
	 * 		dataset				... [act] で指定の処理から e.target.dataset.json として呼び出したいJSON文字列
	 *
	 * [set]
	 * 		class				...	モーダル全体に施したいスタイルシートクラス指定の文字列
	 * 		dialogclass			...	モーダルのダイアログを管理するスタイルシートクラス指定の文字列
	 * 		cancelbtnclass		...	モーダルのキャンセルに相当するボタンのスタイルシートクラス指定の文字列
	 * 		confirmbtnclass		...	モーダルのOK、YESに相当するボタンのスタイルシートクラス指定の文字列
	 * 		name				...	モーダルに設定する id、および name 属性の指定の文字列
	 * 		title				...	モーダルのタイトルに設定する文字列 指定が無い場合、ヘッダー部分は非表示
	 * 		body				...	ボディを表示する場合に指定する
	 *
	 * 		cancelvalue			...	モーダルのキャンセルに相当するボタンに設定する 文字列 デフォルトは "キャンセル"
	 * 		confirmvalue		...	モーダルのOK、YESに相当するボタンに設定する 文字列 デフォルトは "OK"

	 * 		showCancel	...	モーダルのキャンセルに相当するボタンの表示フラグ　この値がtrueの場合、非表示
	 * 		showConfirm	...	モーダルのOK、YESに相当するボタンの表示フラグ　この値がtrueの場合、非表示
	 * 		showClose		...	モーダル右上の閉じるボタンの表示フラグ　この値がtrueの場合、非表示
	 * 		bginvisible			...	モーダル背景を非表示にする際に指定するクラス　この値がtrueの場合、非表示
	 * 		notbgevents			...	モーダルの重なりが下の要素のイベントを発火させたい時に指定するクラス　この値がtrueの場合、発火可能
	 *
	 * 		confirmdisabled		...	モーダルのOK、YESに相当するボタンの disabled 属性の指定のフラグ
	 *
	 * 		cancelbtn	...	キャンセルボタンの状態を動的に変更したい時に使用する
	 * 		confirmbtn	...	OK、YESボタンの状態を動的に変更したい時に使用する
	 *
	 * 		footermessageleft	...	フッターエリアが表示されている場合に、左空き枠に要素を追加したい時に使用する
	 *
	 * [act]
	 * 		cancel				...	モーダルのキャンセルに相当するボタンの押下時に実行したい処理
	 * 		confirm				...	モーダルのOK、YESに相当するボタンの押下時に実行したい処理
	 *
	 */
	 `

export const Modal = (props) => {

	// モーダル全体のクラスを決定		 背景															下の要素を触れるか
	const modalclass= props.className + (props.bginvisible ? " bginvisible" : " modal-background") + (props.notbgevents ? " notbgevents" : "");
	// デフォルトのキャンセルボタンテキストの決定
	const canceltext = (props.cancelvalue) ? props.cancelvalue : "キャンセル";
	// デフォルトのOKボタンテキストの決定
	const confirmtext = (props.confirmvalue) ? props.confirmvalue : "OK";
	// ヘッダー（タイトル＋閉じるボタン）エリアの表示設定
	const showHeader = (props.title!==undefined || props.showClose) ? true : false;
	// フッターエリアの表示設定　ボタンの非表示フラグが立っていれば true
	const showFooter = (props.showCancel || props.showConfirm) ? true : false;
	// フッターエリアが表示されている場合に、左空き枠に要素を追加したい時に使用する
	const footermessageleft = props.footermessageleft ? props.footermessageleft : <></>;

	//決定ボタンの有効・無効のコントロール
	const confirmdisabled = props.confirmdisabled ? props.confirmdisabled : false;
	//キャンセルボタンの有効・無効のコントロール
	const canceldisabled = props.canceldisabled ? props.canceldisabled : false;

	useEffect(() => {
		// ウインドウにキー押下イベントを登録する
		props.cancel && window.addEventListener("keydown", onKeyDown)

		// コンポーネントの終了時処理
		return () => {
			// キー押下時のイベントを解除
			window.removeEventListener("keydown", onKeyDown)
		}

	}, [])

	// キー押下イベント
	const onKeyDown = (e) => {
		// Escapeキーの押下イベント
		if (e.keyCode==27) {
			// キャンセル処理を行う
			props.cancel()
		}
	}

	const renderCloseButton = () => {

		if(! props.showClose) 
		{
			return null;
		}

		return (
			
			<Button className="closebutton hidden"  dataset={props.dataset} onClick={props.cancel} >
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
					<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
				</svg>
			</Button>
		)
	}

	const renderCancelButton = () => {
		
		// キャンセルボタン非表示設定の場合は描画しない
		if(! props.showCancel) 
		{
			return null;
		}

		// ユーザー定義ボタンがある場合は表示
		if(props.cancelbtn)
		{
			return (
				<div className="button-element">
					{props.cancelbtn}
				</div>
			)
		}

		return (
			<div className="button-element">
				<Button id="cancel" className={props.cancelbtnclass} onClick={props.cancel} disabled={canceldisabled} dataset={props.dataset}>{canceltext}</Button>
			</div>
		)
	}

	const renderConfirmButton = () => {
		
		// キャンセルボタン非表示設定の場合は描画しない
		if(! props.showConfirm) 
		{
			return null;
		}

		// ユーザー定義ボタンがある場合は表示
		if(props.confirmbtn)
		{
			return (
				<div className="button-element">
					{props.confirmbtn}
				</div>
			)
		}

		// 通常のボタンを表示
		return (
			<div className="button-element">
				<Button id="confirm" className={props.confirmbtnclass} onClick={props.confirm} disabled={confirmdisabled} dataset={props.dataset}>{confirmtext}</Button>
			</div>
		)
	}

	return (<>
		<div id={props.name} className={"modal-parent fade " + modalclass} onClick={props.cancel}>
			<div className={"ev-modal-dialog " + props.dialogclass}>
				<div className="ev-modal-content ev-modal-container">
					{/* モーダルのヘッダー部分を表示 */}
					{(showHeader) ?
						<div className="ev-modal-header">
								<div id={props.name + "_title"} className={props.showClose ? "ev-modal-title left" : "ev-modal-title  center"}>
									{props.title}
								</div>
								{renderCloseButton()}
						</div>
					: null
					}

					{
						// ヘッダーを表示する場合は、区切り線を表示
						showHeader && <hr></hr>
					}
					
					{/* モーダルのボディ部分を表示 */}
					<div className='ev-modal-body'>
						{props.body ? props.body : null}
					</div>

					{
						// フッターを表示する場合は、区切り線を表示
						showFooter && <hr></hr>
					}

					{/* モーダルのフッター部分を表示 */}
					{(showFooter) ?
						<div className="ev-modal-footer">
							<div className="button-row">
								{footermessageleft}
								{renderCancelButton()}
								{renderConfirmButton()}
							</div>
						</div>
					: null
					}
				</div>
			</div>
		</div>
	</>);
}

export default Modal
