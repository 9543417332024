// Pagination.js

import React from 'react';
import './Pagination.scss';

export const Pagination = (props) => {
    // ローディング表示
    if (props.isLoading) {
        return (
            <div {...props} className={"Text loading " + (props.className ? props.className : "")}>
                {props.children}
            </div>
        );
    }

    const handlePageClick = (page) => {
        if (page !== props.currentPage && props.onChange) {
            props.onChange(page);
        }
    };

    const renderPageNumbers = () => {
        const pages = [];
        const { totalPages, currentPage } = props;

        // 最初と最後のページを必ず追加
        if (totalPages > 0) {
            pages.push(1);
        }
        if (totalPages === 2) {
            pages.push(2);
        } else if (totalPages > 2) {
            pages.push(totalPages);
        }

        // 選択中ページの前後1ページを追加
        if (totalPages > 2) {
            for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
                if (!pages.includes(i)) {
                    pages.push(i);
                }
            }
        }

        // ページ配列をソートして連続性を保つ
        pages.sort((a, b) => a - b);

        const pageButtons = [];
        pages.forEach((page, index) => {
            if (index > 0 && pages[index - 1] < page - 1) {
                pageButtons.push(<span key={`ellipsis-${index}`} className="ellipsis">...</span>);
            }

            pageButtons.push(
                <button
                    key={page}
                    className={`page-button ${page === currentPage ? 'active' : ''}`}
                    onClick={() => handlePageClick(page)}
                >
                    {page}
                </button>
            );
        });

        return pageButtons;
    };

    return (
        <div className="pagination">
            {renderPageNumbers()}
        </div>
    );
};

export default Pagination;
