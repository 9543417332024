// library imports
import { useDispatch } from "react-redux";

// User Component imports
import { ApiRequest } from "_core/apis/ApiRequest";
import { setApiTimestamp } from "_core/slices/datas/TransactionSlice";

const AdminUserChatLogInfoApi = () => {
    const dispatch = useDispatch();

    // API固有の設定、URLとリクエスト
    const url = "/admin/user_chat_log_info";

    // API実行
    const ExecAdminUserChatLogInfoApi = async ({t_user_id, page, is_limit, range_start_year, range_start_month, range_start_day, range_end_year, range_end_month, range_end_day}) => {
        // 必要なリクエストを定義

        const requestParams = {
            t_user_id: t_user_id,
            page: page,
            is_limit: is_limit,
            range_start_year: range_start_year,
            range_start_month: range_start_month,
            range_start_day: range_start_day,
            range_end_year: range_end_year,
            range_end_month: range_end_month,
            range_end_day: range_end_day,
        };
        let response = null;
        let error = null;
        
        // console.log(url + "API開始");
        try {
            response = await ApiRequest({ url, requestParams});
            // console.log(response);
        } catch (e) {
            // console.log(e);
            error = e;
        } finally {
            // console.log(url + "　API終了");
        }

        // レスポンスがあれば、データ更新
        if (response !== null && response.error_code === 0) {
            dispatch(setApiTimestamp(response['now_time']));
        }
        return { response, error };
    };

    return ExecAdminUserChatLogInfoApi;
};

export default AdminUserChatLogInfoApi;