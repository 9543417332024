// library imports
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

// User Component imports
import * as SceneConfig from '_core/configs/SceneConfig';
import { setMenuState } from '_core/slices/scene/SceneSlice';
import { Button } from "_core/components/common/UI";

const Error404 = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        // ヘッダー情報更新
        dispatch(setMenuState({ isHeader: false, isFooter: false, isMenu: false, isFullScreen: true  }));
    }, [dispatch]);

    return (
        <div>
            <h1>403 - Forbidden</h1>
            <p>許可されていないアクセスです。最初からやり直してください。</p>
            <Button className="primary-border" onClick={() => {navigate(SceneConfig.PAGES.ACCOUNT_TOP);}}>トップに戻る</Button>
        </div>
    );
};

export default Error404;


