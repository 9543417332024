// library imports
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// User Component imports
import * as SceneConfig from "_core/configs/SceneConfig";
import * as Constants from "_core/configs/Constants";
import { setLoading, setMenuState, setErrorModal, setInitializeState } from "_core/slices/scene/SceneSlice";
import UsersLoginApi from "apis/UsersLoginApi";

import logo from 'images/common/app_logo_transparent.png';
import {Stack, Button, TextInput, TextButton} from "_core/components/common/UI";

// styles imports
import './AccountLogin.scss';

const AccountLogin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ExecUsersLoginApi = UsersLoginApi();

    const { initializeState } = useSelector((store) => store.scene);
    const [email, setEMail] = useState('');
    const [password, setPassword] = useState('');
    // パスワード入力の表示を最初に表示するか
    const [showPassword, setShowPassword] = useState(true);

    useEffect(() => {
        dispatch(setMenuState({ isHeader: false, isMenu: false, isFooter: false , isFullScreen: true  }));
    }, [dispatch]);

    useEffect(() => {
        if(initializeState === Constants.INITIALIZE_STATE.allComplete)
        {
            dispatch(setInitializeState(Constants.INITIALIZE_STATE.pageDataFetchComplete))
        }
    }, [initializeState]);

    const UsersLoginApiRequest = async () => {
        // 通信中の表示を出す
        dispatch(setLoading(true));

        const result = await ExecUsersLoginApi({ email: email, password: password });
        // 通信中の表示を消す
        dispatch(setLoading(false));
        if (result.error || (result.response && result.response.error_code > 0)) {
            // エラーモーダル表示
            dispatch(setErrorModal({ show: true, text: result.response.error_detail }));
        }
        else 
        {
            navigate(SceneConfig.PAGES.ADMIN_USER_EDIT);
        }
    };

    // パスワードリセット画面に遷移するテキストボタン
    function onClickResetPasswordButton() {
        navigate(SceneConfig.PAGES.ACCOUNT_RESET_PASSWORD);
    }


    return (
        <div id="AccountLogin">
            <div className="paper-login">
                <Stack className="spacing-16px align-center">
                    {/* 上部 */}
                    <div className="block-center">
                        <img src={logo} alt="" className="logo"/>
                    </div>
                    <h4 className="block-center">ログイン</h4>
                    {/* 内部 */}
                    <TextInput className="width-320px" type="email" onchange={(e) => setEMail(e.target.value)} placeholder="メールアドレス"/>
                    {!showPassword && (<Button className="primary width-320px" onClick={() => setShowPassword(true)}>続ける</Button>)}
                    {showPassword && (
                        <>
                            <TextInput className="width-320px" type="password" onchange={(e) => setPassword(e.target.value)} placeholder="パスワード"/>
                            <Button className="primary width-320px" onClick={() => UsersLoginApiRequest()}>ログイン</Button>
                            {/* <TextButton className="resetpassword" onClick={() => onClickResetPasswordButton()}>パスワードをお忘れですか？</TextButton> */}
                        </>)}
                </Stack>
            </div>
        </div>
    );
};

export default AccountLogin;