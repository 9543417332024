// library imports
import { useDispatch } from "react-redux";

// User Component imports
import { ApiRequest } from "_core/apis/ApiRequest";
import { setApiTimestamp } from "_core/slices/datas/TransactionSlice";


const UsersLoginCheckApi = () => {
    const dispatch = useDispatch();
    // API固有の設定、URLとリクエスト
    const url = "/users/login_check";

    // API実行
    const ExecUsersLoginCheckApi = async ({ email }) => {
        // 必要なリクエストを定義
        const requestParams = {
            email : email ,
        };

        let response = null;
        let error = null;
    
        // console.log(url + "API開始");
        try {
            response = await ApiRequest({ url, requestParams, auth:false });
            // console.log(response);
        } catch (e) {
            // console.log(e);
            error = e;
        } finally {
            // console.log(url + "　API終了");
        }

        // レスポンスがあれば、データ更新
        if (response !== null && response.error_code === 0) {
            // 必要な処理があれば記述する
            dispatch(setApiTimestamp(response['now_time']));
        }
        return { response, error };
    };

    return ExecUsersLoginCheckApi;
};

export default UsersLoginCheckApi;