import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import * as SceneConfig from "_core/configs/SceneConfig";
import { Button, List, Row, Stack, TextInput, Modal, Divider, Text } from "_core/components/common/UI";

import './Header.scss';

const Header = () => {
    const { scene, parts, isHeader, headerText } = useSelector((store) => store.scene);
    const navigate = useNavigate();

    if(! isHeader)
    {
        return null
    }

    return (<div className="header">
        <Row className="height-full flex-both">
            <Row className="spacing-8px header-left-area align-center">
                {/* 左寄せ要素を下記に実装 */}
            </Row>
            <Row className="header-container">
                {/* ページコンテンツ幅に合わせる要素を下記に実装 */}
            </Row>
            <Row className="spacing-8px header-right-area align-center">
                {/* 右寄せ要素を下記に実装 */}
            </Row>
        </Row>
    </div>)
};

export default Header;
