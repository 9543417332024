// library imports
import { useSelector } from "react-redux";

// styles imports
import './LayoutElement.scss';

const PageContainer = (props) => {
    const { scene, parts, isHeader, headerText } = useSelector((store) => store.scene);
    return (
        <div className={"page-container-parent " + (isHeader ? "isHeader" : "")}>
            <div className="page-container">
                {props.children}
            </div>
        </div>
    );
};

export default PageContainer;