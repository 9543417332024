// library imports
import { useDispatch } from "react-redux";

// User Component imports
import { ApiRequest } from "_core/apis/ApiRequest";
import { setMasterData } from "_core/slices/datas/MasterSlice";
import { setApiTimestamp, setTransactionData } from "_core/slices/datas/TransactionSlice";


const AdminUserMovieAssetUploadApi = () => {
    const dispatch = useDispatch();

    // API固有の設定、URLとリクエスト
    const url = "/admin/user_movie_asset_upload";

    // API実行, asset_type, file ,file_name
    const ExecAdminUserMovieAssetUploadApi = async (t_user_id, name, idle_select_file, idle_file_name, talk_select_file, talk_file_name) => {
        // 必要なリクエストを定義
        const requestParams = {
            t_user_id: t_user_id,
            name: name,
        };

        const requestBinaryParams = {
            idle_file: {
                file: idle_select_file,
                filename: idle_file_name
            },
            talk_file: {
                file: talk_select_file,
                filename: talk_file_name
            }
        };
    
    
        let response = null;
        let error = null;
        // console.log(url + "API開始");
        try {
            response = await ApiRequest({  url:url, requestParams:requestParams,requestBinaryParams:requestBinaryParams});
            // console.log(response);
        } catch (e) {
            // console.log(e);
            error = e;
        } finally {
            // console.log(url + "　API終了");
        }

        // レスポンスがあれば、データ更新
        if (response !== null && response.error_code === 0)
        {
            dispatch(setApiTimestamp(response['now_time']));
            if(response['t_asset_list'] !=null)
            {
                dispatch(setTransactionData({
                    tAppearanceList:response['t_appearance_list'],
                    tAssetList:response['t_asset_list'],
                }));
            }
        }

        return { response, error };
    };

    return ExecAdminUserMovieAssetUploadApi;
};

export default AdminUserMovieAssetUploadApi;