// styles imports
import './Card.scss';

// メインのコンポーネント定義
export default function Card(props) {
  // コンポーネントの返却
  if (props.isLoading)
  {
    return (
      <div className={"card-block loading " + (props.className ? props.className : "")}>
        {props.children}
      </div>
    );
  }

  return (
    <div className={"card-block " + (props.className ? props.className : "")}>
      {props.children}
    </div>
  );
}
