// styles imports
import './Text.scss';

// メインのコンポーネント定義
export default function Text(props) {
   // コンポーネントの返却
	if (props.isLoading)
  {
    return (
      <div {...props} className={"Text loading " + (props.className ? props.className : "")}>{props.children}</div>
    );
  }

  return (
    <div  {...props} className={"Text " + (props.className ? props.className : "")}>
      {props.children}
    </div>
  );
}
