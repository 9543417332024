import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import "./PreviewIFrame.scss";

import { setLoading, setMenuState, setErrorModal, setInitializeState } from "_core/slices/scene/SceneSlice";
import AdminGetWebPageApi from "apis/AdminGetWebPageApi";
import { Stack } from "_core/components/common/UI";
import { InfoCard } from "components/common/parts";
import * as Constants from "_core/configs/Constants";

export const Comment = `
/**
 *
 * props の定義
 *
 * [set]
 *      key         ... key監視対象
 *      config      ... サイトに反映する設定情報
 *      hostName    ... プレビュー表示を行うWebサイトのホスト名
 *      tag         ... Webサイトに埋め込むscriptタグのテキスト
 *
 * 例)
 *      <PreviewIFrame
 *          config={}
 *          hostName="https://evolve.ne.jp"
 *          tag="<script src='prizmo.js' defer data-conf-url=sample.json></script>"
 *      />
 *
 */
`

export const PreviewIFrame = (props) => {
    const dispatch = useDispatch();
    const ExecAdminGetWebPageApi = AdminGetWebPageApi();
    const [isRequest, setIsRequest] = useState(false);
    const [isInsert, setIsInsert] = useState(false);
    const [isRendered, setIsRendered] = useState(false);

    let iframeRef = useRef(null);
    let srcDoc = useRef("");

    useEffect(() => {
        // iframeの初期化が終わっていない場合はスキップ
        if (!isInsert) {
            return;
        }

        // iframe内のページ情報を再初期化
        iframeRef.current.srcdoc = srcDoc.current;

        iframeRef.current.onload = () => {
            // scriptタグを作成して追加
            const scriptNode = document.createElement('script');
            scriptNode.src = "/prizmo.js";
            scriptNode.defer = true;
            scriptNode.setAttribute("data-conf-url", props.jsonPath);
            iframeRef.current.contentWindow.document.body.appendChild(scriptNode);

            // scriptの読み込みが終わったら、configの読み込みメソッドを実行する
            scriptNode.addEventListener("load", () => {
                iframeRef.current.contentWindow.loadConfigObject(props.config);
            });
            setIsRendered(true)
        }
    }, [props.config]);

    useEffect(() => {
        if (isRequest)
        {
            cleanupIframe();
            setIsRequest(false);
            setIsInsert(false);
            setIsRendered(false);

            const loadIframe = async () => {
                await handleIframeLoaded(); // 非同期処理を実行
            };

            loadIframe(); // 非同期処理を呼び出す
        }
    }, [props.hostName]);

    // 明示的にクリーンアップする関数
    const cleanupIframe = () => {
        const iframe = iframeRef.current;
        if (iframe) {
            iframe.src = "about:blank"; // 現在のリソースを解放
            iframe.contentWindow?.location.replace("about:blank"); // さらに安全にリセット
        }
    };

    // 相対パスを完全なURLに変換する関数
    const resolveFullURL = (baseURL, path) => {
        // URLコンストラクタで絶対URLを生成
        return new URL(path, baseURL).href;
    }

    const handleIframeLoaded = async () => {
        // iframeを取得する
        const iframe = document.getElementById("inner-frame");
        if (iframe && iframe.contentWindow)
        {
            let url = iframe.contentWindow.location.href
        }

        // リクエスト済みフラグが立っている場合はスキップ
        if (isRequest) {
            if (isInsert) {
                return;
            }
            if (props.config) {
                // scriptタグを作成して追加
                const scriptNode = document.createElement('script');
                scriptNode.src = "/prizmo.js";
                scriptNode.defer = true;
                scriptNode.setAttribute("data-conf-url", props.jsonPath);
                const body = iframe.contentWindow.document.body;
                body.appendChild(scriptNode);
    
                scriptNode.addEventListener("load", () => {
                    iframe.contentWindow.loadConfigObject(props.config);
                });
                setIsRendered(true)
            }


            setIsInsert(true);
            return;
        }

        // ページ取得APIをリクエストする
        await AdminGetWebPageRequest(props.hostName)
        .then((response) => {
            // 相対パスでリソースを取得している箇所を絶対パスに置き換える
            const responseText = response.replaceAll(
                /(src|href|srcset)=["'](?!https?:\/\/|\/\/|#)([^"']+)["']/g,
                (match, attr, path) => {
                    if (attr !== "srcset") {
                        if (path.endsWith(".js"))
                        {
                            return `${attr}="${Constants.BASE_URL}/admin/get_web_page_from_param?url=${resolveFullURL(props.hostName, path)}"`;
                        }

                        // if (attr === "href")
                        // {
                        //     console.log(`${attr}="${Constants.BASE_URL}/admin/get_web_page_from_param?url=${resolveFullURL(props.hostName, path)}"`);
                        //     return `${attr}="${Constants.BASE_URL}/admin/get_web_page_from_param?url=${resolveFullURL(props.hostName, path)}"`;
                        // }

                        // srcやhrefの場合の処理
                        return `${attr}="${resolveFullURL(props.hostName, path)}"`;
                    } else {    
                        // srcsetの場合の処理
                        const updatedSrcset = path
                            .split(",") // カンマで分割
                            .map(entry => {
                                const [url, descriptor] = entry.trim().split(/\s+/); // URLと解像度を分離
                                const resolvedURL = resolveFullURL(props.hostName, url); // URLを解決
                                return descriptor ? `${resolvedURL} ${descriptor}` : resolvedURL; // 解像度がある場合は付与
                            })
                            .join(", "); // 再度カンマ区切りで連結

                        return `${attr}="${updatedSrcset}"`;
                    }
                }
            );

            if (props.onFetched)
            {
                props.onFetched(responseText)
            }

            srcDoc.current = responseText;
            // iframeに反映する
            iframe.srcdoc = srcDoc.current;
        })
        .catch((error) => {
            console.error(error);
        });

        // リクエスト済みフラグを上げる
        setIsRequest(true);
    };

    const AdminGetWebPageRequest = async (url) => {
        // Webページ取得API

        // 通信中の表示を出す
        dispatch(setLoading(true));

        const requestParams = {
            url: url
        };

        const result = await ExecAdminGetWebPageApi(requestParams);

        // 通信中の表示を消す
        dispatch(setLoading(false));
        if (result.error || (result.response && result.response.error_code > 0))
        {
            // エラーモーダル表示
            dispatch(setErrorModal({ show: true, text: result.response.error_detail }));
        }
        else
        {
            return result.response.page;
        }
    };

    // コンポーネントの返却
    return (
        <div className={"preview-iframe-wrapper " + (props.className ? props.className : "")  + (! isRendered ? " isloading" : "")}>
            <iframe
                id="inner-frame"
                className={"preview-iframe "}
                srcDoc="<div></div>"
                title="Preview"
                onLoad={handleIframeLoaded}
                ref={iframeRef}
            />
            {(! isRendered) &&
                <Stack className="editor-preview-loading width-full height-full flex-center align-center">
                    <div className="loading-spinner">
                        <div className="loader"></div>
                    </div>
                </Stack>
            }
            {(props.hostName == "") &&
                <Stack className="editor-preview-loading width-full height-full flex-center align-center">
                    <InfoCard className="width-240px">プレビューするURLを入力してください</InfoCard>
                </Stack>
            }
        </div>
    );
};

export default PreviewIFrame;