import AssetsDownloadApi from "apis/AssetsDownloadApi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decodeBase64ToBlob } from "_core/utils/common";
import * as indexDB from "_core/utils/indexDB";

// API認証経由で取得する画像を表示するコンポーネント
export const WebImage = (props) => {
    const [src, setSrc] = useState(null)
    const [isLoad, setIsLoad] = useState(false)
    const ExecAssetsDownloadApi = AssetsDownloadApi();

    useEffect(() => {
        const AssetsDownloadApiRequest = async () => {
            // let imgDataBase64 = sessionStorage.getItem("media-" + props.hash)
            let imgDataBase64 = await indexDB.GetItem("media-" + props.hash)
            
            if (imgDataBase64 === undefined)
            {
                const result = await ExecAssetsDownloadApi({ hash: props.hash });
                if (!result.error && result.response && result.response.error_code === 0) {
                    imgDataBase64 = result.response.content;
                    await indexDB.SetItem("media-" + props.hash, imgDataBase64)
                }
            }

            if (imgDataBase64 !== undefined)
            {
                const blob = decodeBase64ToBlob(imgDataBase64);
                const url = URL.createObjectURL(blob);
                setSrc(url);
                setIsLoad(true)
                // 画像が不要になった場合にURLを解放
                return () => URL.revokeObjectURL(url);
            }
        };
        AssetsDownloadApiRequest();
    }, []);

    return (
        <div className="WebImage">
            <img src={src} alt="" />
        </div>
    );
};

export default  WebImage;