// library imports
import { createSlice } from "@reduxjs/toolkit";

import * as Constants from "_core/configs/Constants";
import * as LocalStorageUtils from "_core/utils/localstorage";

const savedSid = LocalStorageUtils.GetItem(Constants.LOCALSTORAGE_KEYS.sid);
const savedAdminSid = LocalStorageUtils.GetItem(Constants.LOCALSTORAGE_KEYS.adminSid);

const initialState = {
    apiTimestamp: "0",
    sid: savedSid ? savedSid : "",
    adminSid: savedAdminSid ? savedAdminSid : "",
    tUser: null,
    tUserAdd: null,
    tAccount: null,
    tAssetList: null,
    tAppearanceList: null,
    tPayment: null,
    tSubscription: null,
    cardInfo: null
};

const TransactionSlice = createSlice({
    name: "transaction",
    initialState,
    reducers: {
        setApiTimestamp: (state, action) => {
            state['apiTimestamp'] = action.payload;
        },

        /**
         * トランザクションデータをセット
         * payload: array  {key:テーブル名, value:データ}
         */
        setSid: (state, action) => {
            LocalStorageUtils.SetItem(Constants.LOCALSTORAGE_KEYS.sid, action.payload);
            state["sid"] = action.payload;
        },

        /**
         * トランザクションデータをセット
         * payload: array  {key:テーブル名, value:データ}
         */
        setAdminSid: (state, action) => {
            LocalStorageUtils.SetItem(Constants.LOCALSTORAGE_KEYS.adminSid, action.payload);
            state["adminSid"] = action.payload;
        },


        /**
         * トランザクションデータをセット
         * payload: array  {key:テーブル名, value:データ}
         */
        setTransactionData: (state, action) => {
            const transactionData = action.payload;
            for (const key in transactionData) {
                state[key] = transactionData[key];
            }
        },

        /**
         * トランザクションデータをマージ
         * payload: array  {key:テーブル名, value:データ}
         */
        mergeTransactionData: (state, action) => {
            const transactionData = action.payload;

            for (const key in transactionData) {
                if(state[key].length === 0)
                {
                    state[key] = transactionData[key];
                    continue;
                }

                // idをキーとするマップを作成し、効率的にマージを行う
                const tArrayMap = new Map(state[key].map(item => [item.id, item]));
                // 配列のデータ分だけマージチェック
                transactionData[key].forEach(x => {
                    if(tArrayMap.has(x.id))
                    {
                        tArrayMap.set(x.id, { ...tArrayMap.get(x.id), ...x});
                    }
                    else
                    {
                        tArrayMap.set(x.id, x);
                    }
                });
                state[key] = Array.from(tArrayMap.values());
            }
        }
    },
});

export const { setApiTimestamp, setSid, setAdminSid, setTransactionData, mergeTransactionData } = TransactionSlice.actions;
export default TransactionSlice.reducer;