// library imports
import { Route, Routes } from "react-router-dom";

// User Component imports
import AdminUserInfo from "./parts/AdminUserInfo";
import * as SceneConfig from "_core/configs/SceneConfig";
import AdminUserEdit from "./parts/AdminUserEdit";
import AdminUserChatLog from "./parts/AdminUserChatLog";
import AdminUserProfileEdit from "./parts/AdminUserProfileEdit";
import AdminUserLearningEdit from "./parts/AdminUserLearningEdit";
import AdminCharacterCreate from "./parts/AdminCharacterCreate";

const AdminScene = () => {

    return (
        <div id="Admin" className="scene-parent-root">
            <Routes>
                <Route path='' element={<AdminUserInfo />} />
                <Route path={SceneConfig.PARTS_ADMIN_USEREDIT}                   element={<AdminUserEdit />} />
                <Route path={SceneConfig.PARTS_ADMIN_USER_CHATLOG}               element={<AdminUserChatLog />} />
                <Route path={SceneConfig.PARTS_ADMIN_USER_PROFILE_EDIT}          element={<AdminUserProfileEdit />} />
                <Route path={SceneConfig.PARTS_ADMIN_USER_LEARNING_EDIT}         element={<AdminUserLearningEdit />} />
                <Route path={SceneConfig.PARTS_ADMIN_CHARACTER_CREATE}           element={<AdminCharacterCreate />} />
            </Routes>
        </div>
    );
};

export default AdminScene;