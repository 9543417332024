// styles imports
import './Stack.scss';

// メインのコンポーネント定義
export default function Stack(props) {
  return (
    <div className={"stack " + (props.className ? props.className : "")}>
      {props.children}
    </div>
  );
}
