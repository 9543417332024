import React, { useEffect, useRef, useState } from "react";

import {Row, Stack, Icon} from "_core/components/common/UI";

import "./Stepper.scss";

export const Comment = `
/**
 *
 * props の定義
 *
 * [set]
 *      stepList         ... 各ステップ情報
 *
 * 例)
 *      const stepList = [
 *          {
 *              isEnabled : gender > 0,
 *              label: "性別設定"
 *          },
 *          {
 *               isEnabled : imageId != "",
 *              label: "見た目設定"
 *          },
 *          {
 *              isEnabled : voiceId > 0,
 *              label: "ボイス設定"
 *          }
 *      ]
 *  
 *      <Stepper
 *          stepList={stepList}
 *      />
 *  
 */
`

const StepItem = (props) => {
    return (
        <Row className={"step-item " + ((props.isEnabled) ? "" : "disable")}>
            <Stack className="spacing-8px align-center flex-center">
                <Icon
                    icon="check-circle-fill"
                    size={32}
                />
                <div>{props.label}</div>
            </Stack>
        </Row>
    );
};

const LineItem = (props) => {
    return (
        <Row className={"line-item " + ((props.isEnabled) ? "" : "disable")}>
            <Stack className="line flex-center align-center width-full height-full">
                <div className="line-image"></div>
            </Stack>
        </Row>
    );
};

export const Stepper = (props) => {
    return (
        <Row className= "stepper align-center">
            {(props.stepList && props.stepList.length > 0) &&
                props.stepList.map((step, index) => {
                    console.log(index);
                    const isFirst = (index == 0);
                    return (
                        <React.Fragment key={index}>
                            {!isFirst && 
                                <LineItem isEnabled={step.isEnabled}></LineItem>
                            }
                            <StepItem
                                isEnabled={step.isEnabled}
                                label={step.label}
                            />
                        </React.Fragment>
                    )
            })}
        </Row>
    )
}

export default Stepper;