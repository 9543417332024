// styles imports
import './Row.scss';

// メインのコンポーネント定義
export default function Row(props) {
  return (
    <div className={"row-stack " +  (props.className ? props.className : "")}>
      {props.children}
    </div>
  );
}
