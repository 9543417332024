// シーン定数
export const SCENE_ACCOUNT       = 'account';
export const SCENE_ADMIN         = 'admin';
export const SCENE_PAYMENT       = 'payment';
export const SCENE_ERROR         = 'error';

// パーツ定数、汎用
export const PARTS_TOP = 'top';

// パーツ定数、アカウント
export const PARTS_ACCOUNT_SIGNUP                    = 'signup';
export const PARTS_ACCOUNT_LOGIN                     = 'login';
export const PARTS_ACCOUNT_EDIT                      = 'edit';
export const PARTS_ACCOUNT_RESET_PASSWORD            = 'resetpassword';
export const PARTS_ACCOUNT_CHANGE_PASSWORD           = 'changepassword';
export const PARTS_ACCOUNT_COMPLETE_CHANGE_PASSWORD  = 'completechangepassword';
export const PARTS_ACCOUNT_COMPLETE_SEND_MAIL        = 'completesendmail';

// パーツ定数、管理画面
export const PARTS_ADMIN_USEREDIT                = 'useredit';
export const PARTS_ADMIN_USER_CHATLOG            = 'userchatlog';
export const PARTS_ADMIN_USER_PROFILE_EDIT       = 'userprofileedit';
export const PARTS_ADMIN_USER_LEARNING_EDIT      = 'userprolearningedit';
export const PARTS_ADMIN_CHARACTER_CREATE        = 'charactercreate';

// パーツ定数、管理画面
export const PARTS_PAYMENT_PLAN        = 'plan';
export const PARTS_PAYMENT_SETTING     = 'setting';
export const PARTS_PAYMENT_HISTORY     = 'history';

// パーツ定数、インフォ
export const PARTS_DETAIL = 'detail';
export const PARTS_POLICY = 'policy';
export const PARTS_PRIVACY_POLICY = 'privacy_policy';

// パーツ定数、エラー
export const PARTS_ERROR_403 = '403';
export const PARTS_ERROR_404 = '404';

// demo
export const SCENE_DEMO_WEB_SOCKET = 'd-websocket';

export const ADMIN_CHECK_SCENE_LIST= [
    "admin",
    "payment",
];

export const PAGES = {
    ACCOUNT_CHANGE_PASSWORD                 : "/" + SCENE_ACCOUNT + "/" + PARTS_ACCOUNT_CHANGE_PASSWORD,
    ACCOUNT_COMPLETE_CHANGE_PASSWORD        : "/" + SCENE_ACCOUNT + "/" + PARTS_ACCOUNT_COMPLETE_CHANGE_PASSWORD,
    ACCOUNT_COMPLETE_SEND_MAIL              : "/" + SCENE_ACCOUNT + "/" + PARTS_ACCOUNT_COMPLETE_SEND_MAIL,
    ACCOUNT_LOGIN                           : "/" + SCENE_ACCOUNT + "/" + PARTS_ACCOUNT_LOGIN,
    ACCOUNT_RESET_PASSWORD                  : "/" + SCENE_ACCOUNT + "/" + PARTS_ACCOUNT_RESET_PASSWORD,
    ACCOUNT_SIGNUP                          : "/" + SCENE_ACCOUNT + "/" + PARTS_ACCOUNT_SIGNUP,
    ACCOUNT_TOP                             : "/" + SCENE_ACCOUNT + "/" + PARTS_TOP,
    ADMIN_USER_EDIT                         : "/" + SCENE_ADMIN   + "/" + PARTS_ADMIN_USEREDIT,
    PARTS_ADMIN_USER_CHATLOG                : "/" + SCENE_ADMIN   + "/" + PARTS_ADMIN_USER_CHATLOG,
    PARTS_ADMIN_USER_PROFILE_EDIT           : "/" + SCENE_ADMIN   + "/" + PARTS_ADMIN_USER_PROFILE_EDIT,
    PARTS_ADMIN_USER_LEARNING_EDIT          : "/" + SCENE_ADMIN   + "/" + PARTS_ADMIN_USER_LEARNING_EDIT,
    PARTS_ADMIN_CHARACTER_CREATE            : "/" + SCENE_ADMIN   + "/" + PARTS_ADMIN_CHARACTER_CREATE,
    PAYMENT_HISTORY                         : "/" + SCENE_PAYMENT + "/" + PARTS_PAYMENT_HISTORY,
    PAYMENT_PLAN                            : "/" + SCENE_PAYMENT + "/" + PARTS_PAYMENT_PLAN,
    PAYMENT_SETTING                         : "/" + SCENE_PAYMENT + "/" + PARTS_PAYMENT_SETTING,
    ERROR_403                               : "/" + SCENE_ERROR   + "/" + PARTS_ERROR_403,
    ERROR_404                               : "/" + SCENE_ERROR   + "/" + PARTS_ERROR_404,
};

