// library imports
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// User Component imports
import { Button, Icon, Radio, Row, Stack, TextInput, Card, HTMLRenderer, Text } from "_core/components/common/UI";

// styles imports
import './PlanCard.scss';

const PlanCard = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { tSubscription } = useSelector(store => store.transaction)

    return (
    <Card className="PlanCard border-card">
        {(props.tPayment && props.tPayment.card_id !== "") &&
            ((tSubscription && tSubscription.m_payment_id == props.mPayment.id) &&
                <Row className="selected-label flex-center align-center text-bold">現在のプラン</Row>
            )
        }
        <Stack className="spacing-16px flex-both height-full">
            <Stack className="spacing-16px">
                <Row className="flex-center">
                    <Text isLoading={props.isLoading} className="text-size-large text-bold">
                        {props.mPayment ? props.mPayment.name : "スタンダードプラン"}
                    </Text>
                </Row>
                <Card  isLoading={props.isLoading} className="info-card">
                    <Row className="flex-center spacing-4px">
                        <Stack className="flex-bottom">
                            <Text className="text-size-small">
                                月額
                            </Text>
                        </Stack>
                        <Stack className="flex-bottom">
                            <Text className="text-size-large text-bold price-text">
                                {props.mPayment ? props.mPayment.price.toLocaleString() : "10000"}
                            </Text>
                        </Stack>
                        <Stack className="flex-bottom">
                            <Text className="text-size-small text-bold">
                            円
                            </Text>
                        </Stack>
                    </Row>
                </Card>
                <hr/>
                <Stack className="detail">
                    <HTMLRenderer className="height-full" isLoading={props.isLoading} htmlContent={props.mPayment ? props.mPayment.detail : ""}/>
                </Stack>
            </Stack>
            {(props.tPayment && props.tPayment.card_id !== "") ?
                ((tSubscription && tSubscription.m_payment_id == props.mPayment.id) ?
                    <Button isLoading={props.isLoading} className="primary disabled medium" onClick={() => {props.onClick(props.mPayment.id)}}>登録</Button>
                    : 
                    <Button isLoading={props.isLoading} className="primary medium" onClick={() => {props.onClick(props.mPayment.id)}}>登録</Button>
                )
            :
                <Button isLoading={props.isLoading} className="primary disabled medium">登録</Button>
            }
        </Stack>

    </Card>
    );
};

export default PlanCard;