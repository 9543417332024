export const timeCurrentIso8601 = () => (new Date()).toISOString();

// ファイルをbase64にエンコードする
export const encodeFileToBase64 = (fileBlob, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    reader.onload = () => {
        const base64 = reader.result;
        const dataPart = base64.split(',')[1]; // 'data:image/png;base64,' の後の部分を取得
        callback(dataPart);
    };
    reader.onerror = (error) => {
        console.error('Error converting file to base64!', error);
    };
};

// Base64文字列をBlobデータにデコードする
export const decodeBase64ToBlob = (base64, mimeType) => {
    // Base64文字列をバイナリデータに変換する
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);

    // 文字列をバイト配列に変換
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    // Uint8Arrayを作成
    const byteArray = new Uint8Array(byteNumbers);

    // Blobデータを作成
    return new Blob([byteArray], { type: mimeType });
};

// 現在時間を%Y-%m-%dT%H:%M:%S形式で取得する
export const getTime = () => {
    const now = new Date();

    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZone: 'Asia/Tokyo'
    };

    const formattedDate = new Intl.DateTimeFormat('en-CA', options).format(now);

    // フォーマットを整える
    const [date, time] = formattedDate.split(', ');
    const formattedDateTime = date.replace(/\//g, '-') + ' ' + time;

    return formattedDateTime;
};

export const waitSecond = (seconds) => {
    return new Promise(resolve => setTimeout(resolve, (seconds * 1000)));  // 1000ミリ秒 = 1秒
}

export const ReverseKeyValue = (obj) => {
    const invertedObj = {};

    for (let key in obj) {
        const value = obj[key];
        
        // 重複するバリューがあった場合は無視
        if (!(value in invertedObj)) {
            invertedObj[value] = key;
        }
    }

    return invertedObj;
};


export const FormatDate = (isoString) =>  {
    const date = new Date(isoString);
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');  // 月は0から始まるので+1
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}年${month}月${day}日`;
}

export const HexToRgb =  (hex) => {
    // "#"を取り除く
    hex = hex.replace(/^#/, "");

    // 3桁の場合を6桁に展開
    if (hex.length === 3) {
        hex = hex.split("").map(char => char + char).join("");
    }

    // R, G, B値を取得
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return { r, g, b };
}