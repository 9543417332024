// library imports
import { useDispatch } from "react-redux";

// User Component imports
import { ApiRequest } from "_core/apis/ApiRequest";
import { setApiTimestamp, setAdminSid, setTransactionData } from "_core/slices/datas/TransactionSlice";
import dataManagerInstance from "_core/managers/DataManager";


const AdminUserLoginApi = () => {
    const dispatch = useDispatch();

    // API固有の設定、URLとリクエスト
    const url = "/admin/userlogin";

    // API実行
    const ExecAdminUserLoginApi = async () => {
        const sid = dataManagerInstance.getSid();

        // 必要なリクエストを定義
        const requestParams = {
            sid: sid,
        };

        let response = null;
        let error = null;
    
        // console.log(url + "API開始");
        try {
            response = await ApiRequest({ url, requestParams, auth:false });
            // console.log(response);
        } catch (e) {
            // console.log(e);
            error = e;
        } finally {
            // console.log(url + "　API終了");
        }

        // レスポンスがあれば、データ更新
        if (response !== null && response.error_code === 0) {
            dispatch(setApiTimestamp(response['now_time']));
            dispatch(setAdminSid(response['t_account']['sid']));
            dispatch(setTransactionData({
                tAccount:response['t_account']}
            ));
        }
        return { response, error };
    };

    return ExecAdminUserLoginApi;
};

export default AdminUserLoginApi;