// library imports
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// User Component imports
import * as SceneConfig from "_core/configs/SceneConfig";
import * as Constants from "_core/configs/Constants";
import { setLoading, setMenuState, setErrorModal, setInitializeState } from "_core/slices/scene/SceneSlice";
import UsersCreateApi from "apis/UsersCreateApi";
import UsersLoginCheckApi from "apis/UsersLoginCheckApi";

import logo from 'images/common/app_logo_transparent.png';
import {Stack, Button, TextInput} from "_core/components/common/UI";

// styles imports
import './AccountSignup.scss';

const AccountSignup = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ExecUsersCreateApi = UsersCreateApi();
    const ExecUsersLoginCheckApi = UsersLoginCheckApi();

    const { initializeState } = useSelector((store) => store.scene);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        // ヘッダー情報更新
        dispatch(setMenuState({ isHeader: false, isMenu: false, isFooter: false, isFullScreen: true   }));

    }, [dispatch]);

    useEffect(() => {
        if(initializeState === Constants.INITIALIZE_STATE.allComplete)
        {
            dispatch(setInitializeState(Constants.INITIALIZE_STATE.pageDataFetchComplete))
        }
    }, [initializeState]);

    const UsersLoginCheckApiRequest = async () => {
        // 通信中の表示を出す
        dispatch(setLoading(true));

        const result = await ExecUsersLoginCheckApi({ email: email });
        // 通信中の表示を消す
        dispatch(setLoading(false));
        if (result.error || (result.response && result.response.error_code > 0)) {
            // エラーモーダル表示
            dispatch(setErrorModal({ show: true, text: result.response.error_detail }));
        }
        else 
        {
            setShowPassword(!result.response['is_check']);
        }
    };

    const UsersCreateApiRequest = async () => {
        // 通信中の表示を出す
        dispatch(setLoading(true));

        const result = await ExecUsersCreateApi({ email: email, password: password });
        // 通信中の表示を消す
        dispatch(setLoading(false));
        if (result.error || (result.response && result.response.error_code > 0)) {
            // エラーモーダル表示
            dispatch(setErrorModal({ show: true, text: result.response.error_detail }));
        }
        else 
        {
            navigate(SceneConfig.PAGES.ADMIN_USER_EDIT);
        }
    };


    return (
        <div id="AccountSignup">
            <div className="paper-login">
                <Stack className="spacing-16px align-center">
                    {/* 上部 */}
                    <div className="block-center">
                        <img src={logo} alt="" className="logo"/>
                    </div>
                    <h4 className="block-center">アカウント作成</h4>
                    {/* 内部 */}
                    <TextInput className="width-320px" type="email" onchange={(e) => setEmail(e.target.value)} placeholder="メールアドレス"/>
                    {!showPassword && (<Button className="primary width-320px" onClick={() => UsersLoginCheckApiRequest()}>続ける</Button>)}
                    {showPassword && (
                        <>
                            <TextInput type="password width-320px" onchange={(e) => setPassword(e.target.value)} placeholder="パスワード"/>
                            <Button className="primary width-320px" onClick={() => UsersCreateApiRequest()}>作成</Button>
                        </>)}
                </Stack>
            </div>
        </div>
    );
};

export default AccountSignup;