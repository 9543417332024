import React from 'react'
import { useSelector } from 'react-redux';
import * as Constants from "_core/configs/Constants";

import './Loading.scss';

const Loading = () => {
    const { isLoading, initializeState } = useSelector((store) => store.scene);

    function ShowLoading() {
        return (
            <aside className='loading-container'>
            </aside>
        );
    }

    const showLoading = (isLoading || initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete)

    return showLoading && <ShowLoading />;
};

export default Loading;