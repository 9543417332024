import React from 'react';
// User Component imports
import {Row, Stack, Card, Icon} from "_core/components/common/UI";

import './InfoCard.scss';

export const InfoCard = (props) => {
    return (
        <Card className={"info-card " + (props.className ? props.className : "")}>
            <Row className="spacing-16px">
                <Icon icon="info-circle-fill" size={16} className=""/>
                <Stack className="align-left">
                    {props.children}
                </Stack>
            </Row>
        </Card>
    );
};

export default InfoCard;
