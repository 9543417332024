// library imports
import { useEffect, useState,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PageContent, PageHeader, PageWrapper, PageContainer } from 'components/common/layout_element/LayoutElement';
import * as Constants from "_core/configs/Constants";

// User Component imports
import {Textarea, Row, Button, TextInput, Stack, Icon, Uploader, Card} from "_core/components/common/UI";
import { DocsItem, InfoCard } from "components/common/parts";
import { setLoading, setMenuState, setErrorModal, setInitializeState } from "_core/slices/scene/SceneSlice";
import AdminUserAssetUploadApi from "apis/AdminUserAssetUploadApi";
import AdminUserEditApi from "apis/AdminUserEditApi";
import AdminUserAssetDeleteApi from "apis/AdminUserAssetDeleteApi";

// styles imports
import './AdminUserLearningEdit.scss';


const AdminUserLearningEdit = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const docUploaderRef = useRef();

    const ExecAdminUserAssetUploadApi = AdminUserAssetUploadApi();
    const ExecAdminUserAssetDeleteApi = AdminUserAssetDeleteApi();
    const ExecAdminUserEditApi = AdminUserEditApi();

    const { tUser } = useSelector((store) => store.transaction);
    const { tUserAdd } = useSelector((store) => store.transaction);
    const { tAccount } = useSelector((store) => store.transaction);
    const { tAssetList } = useSelector((store) => store.transaction);
    const { initializeState } = useSelector((store) => store.scene);

    const [larningUrl, setLarningUrl] = useState('');
    const [larningX, setLarningX] = useState('');
    const [larningFacebook, setLarningFacebook] = useState('');
    const [larningDocsData, setLarningDocsData] = useState([]);

    useEffect(() => {
        dispatch(setMenuState({ isHeader: false, isMenu: true, isFooter: false, isFullScreen: false }));
    }, [dispatch]);
    
    useEffect(() => {
        if(initializeState !== Constants.INITIALIZE_STATE.allComplete)
        {
            return;
        }

        // 現在の設定で初期表示設定を行う
        setLarningUrl(tUserAdd.larning_url);
        setLarningX(tUserAdd.larning_x);
        setLarningFacebook(tUserAdd.larning_facebook);
        // DocとしてアップしているtAseet情報取得
        const docAssetList =tAssetList.filter((tAsset) => tAsset.asset_type==Constants.ASSET_TYPE.DOC);
        setLarningDocsData(docAssetList)

        dispatch(setInitializeState(Constants.INITIALIZE_STATE.pageDataFetchComplete));

    }, [initializeState]);

      
    useEffect(() => {
        // 改行の数から、URL入力件数を計算
        const lineCount = (larningUrl.match(/\n/g) || []).length;
        // 入力URL数
        const urlCount = lineCount+1
      }, [larningUrl]);

    //改行コードを標準化
    const normalizeNewlines = (text) => text.replace(/\r\n/g, '\n').replace(/\r/g, '\n');
    function GetIsUpdateProfile()
    {
        // 変更箇所が存在するか
        if (tUserAdd && normalizeNewlines(tUserAdd["larning_url"]) !== normalizeNewlines(larningUrl))
        {
            return true;
        }

        if (tUserAdd && tUserAdd["larning_x"] != larningX)
        {
            return true;
        }

        if (tUserAdd && tUserAdd["larning_facebook"] != larningFacebook)
        {
            return true;
        }
        return false;
    }

    const AdminUserEditApiRequest = async () => {
        // 編集API

        // 通信中の表示を出す
        dispatch(setLoading(true));

        const requestParams = {
            t_user_id: tUser["id"]
        };

        // 変更箇所のみ、リクエストに含める
        if (tUserAdd["larning_url"] != larningUrl)
        {
            requestParams["larning_url"] = larningUrl;
        }

        if (tUserAdd["larning_x"] != larningX)
        {
            requestParams["larning_x"] = larningX;
        }

        if (tUserAdd["larning_facebook"] != larningFacebook)
        {
            requestParams["larning_facebook"] = larningFacebook;
        }

        const result = await ExecAdminUserEditApi(requestParams);
    
        // 通信中の表示を消す
        dispatch(setLoading(false));
        if (result.error || (result.response && result.response.error_code > 0)) {
            // エラーモーダル表示
            dispatch(setErrorModal({ show: true, text: result.response.error_detail }));
        }
    };

    const AdminAssetsUploadApiRequest = async (t_user_id, asset_type, file, file_name) => {
        // アセットアップロードAPI
        
        // 通信中の表示を出す
        dispatch(setLoading(true));

        const result = await ExecAdminUserAssetUploadApi(t_user_id, asset_type, file, file_name);
        // 通信中の表示を消す
        dispatch(setLoading(false));
        if (result.error || (result.response && result.response.error_code > 0))
        {
            // エラーモーダル表示
            dispatch(setErrorModal({ show: true, text: result.response.error_detail }));
        }
        else
        {
            // DocとしてアップしているtAseet情報再取得
            const docAssetList = result.response.t_asset_list.filter((tAsset) => tAsset.asset_type==Constants.ASSET_TYPE.DOC);
            setLarningDocsData(docAssetList)
        }
    };

    const ExecAdminUserAssetDeleteApiRequest = async (tid, asset_type) => {
        // アセット削除API

        // 通信中の表示を出す
        dispatch(setLoading(true));

        const result = await ExecAdminUserAssetDeleteApi(tid, asset_type);

        // 通信中の表示を消す
        dispatch(setLoading(false));

        if (result.error || (result.response && result.response.error_code > 0)) {
            // エラーモーダル表示
            dispatch(setErrorModal({ show: true, text: result.response.error_detail }));
        }
        else
        {
            // DocとしてアップしているtAseet情報再取得
            const docAssetList =result.response.t_asset_list.filter((tAsset) => tAsset.asset_type==Constants.ASSET_TYPE.DOC);
            setLarningDocsData(docAssetList)
        }
    };


     // ダウンロードボタンの処理
    const ExecDownload = (tAsset) => {
        
        const link = document.createElement('a');
        link.href = tAsset.sas;
        link.download = tAsset.path;
        link.target ="_blank"
        // リンクをクリックしてダウンロードを開始
        link.click();
    };

    // 削除ボタンの処理
    const ExecDelete = (id) => {
        // 削除確認ダイアログを表示
        const userConfirmed = window.confirm("本当に削除しますか？")
        if (!userConfirmed)
        {
            // キャンセルした場合は削除を行わない
            return;
        }
        ExecAdminUserAssetDeleteApiRequest(id,Constants.ASSET_TYPE.DOC)
    };

    // ファイル選択後の処理
    function HandleDocFileUpload(file,assetType)
    {
        AdminAssetsUploadApiRequest(tUser.id,assetType,file,file.name)
    };

    // アップロードボタンのクリック時処理
    const handleDocUploadButtonClick = () => {
        if (docUploaderRef.current)
        {
            docUploaderRef.current.openFileDialog();
        }
    };

    return (
    <PageContent pageId="AdminUserLearningEdit">
            <PageHeader>
                    <Stack className="spacing-4px">
                        <div className="page-header-title">RAG設定</div>
                        <div className="page-header-detail">広報担当AIが参照するRAG情報を登録/更新します</div>
                    </Stack>
            </PageHeader>
            <PageWrapper>
                <PageContainer>
                    {/* ここにページ内コンテンツのコンポーネントを記述 */}
                    <div className="block-center">
                        <Card className="base-card">
                            <Stack className="spacing-32px align-left">
                                <Row className= "input-row-area align-center spacing-16px">
                                    <Stack className="input-label-area flex-center">
                                        <div className="input-label input-label-text text-size-medium text-bold">URL</div>
                                    </Stack>
                                    <Stack className="input-form-area flex-center spacing-16px">
                                        <Textarea 
                                                className="large-area"
                                                name="weburl"
                                                default={larningUrl}
                                                onchange={(e) => setLarningUrl(e.target.value)} 
                                                placeholder="URLを入力" 
                                                isLoading={(initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false}
                                            />

                                        <InfoCard>
                                                <div>情報を参照するページのURLを入力してください。</div>
                                                <div>複数URLを読み込ませる場合は、1行1WEBサイトになるように改行してください。</div>
                                                <div>認証情報がある場合、認証パラメータを含めたURLを入力してください。</div>
                                        </InfoCard>
                                    </Stack>
                                </Row>
                                <hr/>
                                <Row className= "input-row-area align-center spacing-16px">
                                    <Stack className="input-label-area flex-center">
                                        <div className="input-label input-label-text text-size-medium text-bold">ドキュメント</div>
                                    </Stack>
                                    <Stack className="input-form-area flex-center spacing-16px">
                                        <Button className="primary vlong-button" onClick={() => handleDocUploadButtonClick()}>アップロード</Button>
                                        <InfoCard>
                                                <div>アップロードされた情報は広報担当AIが返答のために参照します。</div>
                                                <div>非公開情報がファイルに含まれないか事前に確認を行って下さい。</div>
                                        </InfoCard>
                                        <Uploader
                                            ref={docUploaderRef}
                                            className="custom-uploader"
                                            accept={Constants.ACCEPT_DOC_EXTENSION_LIST}
                                            onUpload={(file) => HandleDocFileUpload(file, Constants.ASSET_TYPE.DOC)}
                                        />
                                    </Stack>
                                </Row>
                                    {larningDocsData.map(tAsset => (
                                        <DocsItem
                                            label=""
                                            id ={tAsset.id}
                                            RowClassName="align-top spacing-16px"
                                            LabelClassName="input-label input-label-text"
                                            TextInputClassName="middle-input"
                                            DlClassName="middle-button"
                                            DelClassName="middle-button"
                                            value ={tAsset.path}
                                            isLoading={(initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false}
                                            onDownload={(e) => ExecDownload(tAsset)}
                                            onDelete={(e) => ExecDelete(tAsset.id)}
                                        />
                                    ))}
                                <hr/>
                                
                                <Row className= "input-row-area align-center spacing-16px">
                                    <Stack className="input-label-area stack flex-center">
                                        <div className="input-label input-label-text text-size-medium text-bold">SNS</div>
                                    </Stack>
                                    <Stack className="input-form-area stack flex-center spacing-16px">
                                        <Row className= "input-row-area align-top spacing-16px">
                                            <Icon icon="twitter-x" size={32} className="x-icon"/>
                                            <TextInput 
                                                name="x-learn"
                                                default={larningX}
                                                onchange={(e) => setLarningX(e.target.value)} 
                                                placeholder="X設定を入力" 
                                                isLoading={(initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false}
                                            />
                                        </Row>
                                        <Row className= "input-row-area align-top spacing-16px">
                                            <Icon icon="facebook" size={32} className="facebook-icon"/>
                                            <TextInput 
                                                name="facebook-larn"
                                                onchange={(e) => setLarningFacebook(e.target.value)} 
                                                default={larningFacebook}
                                                placeholder="facebook設定を入力" 
                                                isLoading={(initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false}
                                            />
                                        </Row>
                                    </Stack>
                                </Row>
                            </Stack>
                            <Stack className="spacing-32px align-center margin-top-60px">
                                <Button className="primary width-320px" onClick={() => AdminUserEditApiRequest()}>更新</Button>
                            </Stack>
                        </Card>
                    </div>
                </PageContainer>
            </PageWrapper>
    </PageContent>
    );
};

export default AdminUserLearningEdit;