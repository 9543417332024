// library imports
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mPayment: null,
};


const MasterSlice = createSlice({
    name: "master",
    initialState,
    reducers: {
        /**
         * マスターデータをセット
         * payload: array  {key:テーブル名, value:データ}
         */
        setMasterData: (state, action) => {
            const masterData = action.payload;
            for (const key in masterData) {
                state[key] = masterData[key];
            }
        }
    },
});

export const { setMasterData } = MasterSlice.actions;
export default MasterSlice.reducer;