// library imports
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from 'reportWebVitals';

// User Component imports
import Initialize from '_core/components/common/Initialize';
import Footer from '_core/components/common/Footer';
import Header from '_core/components/common/Header';
import Loading from '_core/components/common/Loading';
import MainScene from '_core/components/common/MainScene';
import Menu from '_core/components/common/Menu';
import ErrorModal from '_core/components/common/ErrorModal';
import CommonModal from '_core/components/common/ErrorModal';
import { SceneRoot } from 'components/common/layout_element/LayoutElement';
import { store } from '_core/store';

// styles imports
import 'sanitize.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'globalStyles/index.scss';
import 'globalStyles/styles.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <Provider store={store}>
        <Router>
          <Initialize/>
          <Loading />
          <Menu/>
          <SceneRoot>
            <Header />
            <MainScene/>
          </SceneRoot>
          <ErrorModal/>
          <CommonModal/>
          <Footer />
        </Router>
      </Provider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
