// library imports
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// User Component imports
import * as SceneConfig from "_core/configs/SceneConfig";
import * as Constants from "_core/configs/Constants";
import { setLoading, setMenuState, setErrorModal, setInitializeState } from "_core/slices/scene/SceneSlice";
import UsersLoginApi from "apis/UsersLoginApi";
import logo from 'images/common/app_logo_transparent.png';
import videoSrc from 'images/common/manami_talk.mp4';
import {Stack, Button, Row, Card, TextInput, Video} from "_core/components/common/UI";
import { deviceType, useDevice } from '_core/managers/ResponsiveManager';

// styles imports
import './AccountTop.scss';

const AccountTop = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ExecUsersLoginApi = UsersLoginApi();

    const { initializeState } = useSelector((store) => store.scene);
    const [email, setEMail] = useState('');
    const [password, setPassword] = useState('');
    const device = useDevice();


    useEffect(() => {
        dispatch(setMenuState({ isHeader: false, isMenu: false, isFooter: false , isFullScreen: true  }));
    }, [dispatch]);

    useEffect(() => {
        if(initializeState === Constants.INITIALIZE_STATE.allComplete)
        {
            dispatch(setInitializeState(Constants.INITIALIZE_STATE.pageDataFetchComplete))
        }
    }, [initializeState]);

    const UsersLoginApiRequest = async () => {
        // 通信中の表示を出す
        dispatch(setLoading(true));

        const result = await ExecUsersLoginApi({ email: email, password: password });
        // 通信中の表示を消す
        dispatch(setLoading(false));
        if (result.error || (result.response && result.response.error_code > 0)) {
            // エラーモーダル表示
            dispatch(setErrorModal({ show: true, text: result.response.error_detail }));
        }
        else 
        {
            navigate(SceneConfig.PAGES.ADMIN_USER_EDIT);
        }
    };

    return (
        <div id="AccountTop">
            <Row className="width-full height-full">
                <Stack className="divide-half-area input-area height-full flex-center align-center">
                    <Stack className="height-full flex-both align-center width-320px">
                        <Stack>
                        </Stack>
                        <Stack className="spacing-16px align-center">
                            {/* 上部 */}
                            <div className="block-center">
                                <img src={logo} alt="" className="logo"/>
                            </div>
                            <h4 className="block-center">ログイン</h4>
                            <Stack className= "width-full spacing-8px margin-bottom-16px">
                                <div>メールアドレス</div>
                                <TextInput className="width-320px" type="email" onchange={(e) => setEMail(e.target.value)} placeholder="メールアドレス"/>
                            </Stack>
                            <Stack className= "width-full spacing-8px margin-bottom-16px">
                                <div>パスワード</div>
                                <TextInput className="width-320px" type="password" onchange={(e) => setPassword(e.target.value)} placeholder="パスワード"/>
                            </Stack>
                            <Button className="primary width-320px" onClick={() => UsersLoginApiRequest()}>ログイン</Button>
                        </Stack>
                        <Stack className="width-full spacing-16px align-center">
                            <hr />
                            <div className="info-text">©EVOLVE, Inc. All Rights Reserved.</div>
                        </Stack>
                    </Stack>
                </Stack>
                {! (device === deviceType.mobile.type ) && 
                    <Stack className="divide-half-area info-area height-full flex-center">
                        {/* <Video className="horizontal-fit" src={videoSrc}/> */}
                        <Stack className="flex-center align-center width-full">
                            <div className="copy-text">あなたの企業らしさ、</div>
                            <div className="copy-text">PRizmoでどう伝わるか試してみませんか？</div>
                        </Stack>
                    </Stack>
                }
            </Row>
        </div>
    );
};

export default AccountTop