// styles imports
import './HTMLRenderer.scss';

// メインのコンポーネント定義
export default function HTMLRenderer(props) {
  return (
    <div 
      className={"HTMLRenderer " + (props.isLoading ? "loading " : "") + (props.className ? props.className : "")}
      dangerouslySetInnerHTML={{ __html: props.htmlContent }} 
      />
  );
}
