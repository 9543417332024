import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { initFincode, getCardToken } from '@fincode/js';

import { setLoading, setMenuState, setErrorModal } from "_core/slices/scene/SceneSlice";
import * as SceneConfig from "_core/configs/SceneConfig";
import { Button, Icon, Radio, Row, Stack, TextInput, TextInputWithLabel } from "_core/components/common/UI";
import * as Constants from "_core/configs/Constants";
import dataManagerInstance from "_core/managers/DataManager";

import PaymentCreateApi from "apis/PaymentCreateApi";

// styles imports
import './FinCodeCardInput.scss';

const FinCodeCardInput = (props) => {
    // 各種情報の取得
	let transaction = useSelector(store => store.transaction)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [fincodeInstance, setFincodeInstance] = useState(null);

    const [cardNo, setCardNo] = useState(props.cardInfo ? props.cardInfo.card_no : "");
    const [expireMonth, setExpireMonth] = useState(props.cardInfo ? props.cardInfo.expire.substring(2, 4) : "");
    const [expireYear, setExpireYear] = useState(props.cardInfo ? props.cardInfo.expire.substring(0, 2) : "");
    const [holderName, setHolderName] = useState(props.cardInfo ? props.cardInfo.holder_name : "");
    const [securityCode, setSecurityCode] = useState(props.cardInfo ? "●●●" : "");

    const ExecPaymentCreateApi = PaymentCreateApi();

    useEffect(() => {
        const initializeFincode = async () => {
            const fincode = await initFincode({
                publicKey: Constants.FINCODE_PUBLIC_KEY,
                isLiveMode: false,
            });

            setFincodeInstance(fincode);
        };

        initializeFincode();
    }, []); 

    const onClickCardButton = async () => {

        // 顧客情報未登録の場合は先に登録
        if(! dataManagerInstance.getTPayment())
        {
            await PaymentCreate();
            return;
        }
         // 顧客情報登録済みの場合はカードトークンを取得して後処理を実行
        if (dataManagerInstance.getTPayment())
        {
            await GetCardToken()
            return;
        }
    }

    const PaymentCreate = async () => {
        // 通信中の表示を出す
        dispatch(setLoading(true));

        const result = await ExecPaymentCreateApi({});
        // 通信中の表示を消す
        dispatch(setLoading(false));
        if (result.error || (result.response && result.response.error_code > 0)) {
            // エラーモーダル表示
            dispatch(setErrorModal({ show: true, text: result.response.error_detail }));
        }
        else
        {
            await GetCardToken()
        }
    }

    const GetCardToken = async () => {
        // カードトークン取得に必要なデータを宣言
        const card = {
            // カード番号
            card_no : cardNo,
            // 有効期限 yymm形式
            expire : expireYear + expireMonth,
            // カード名義人
            holder_name: holderName,
            // セキュリティコード
            security_code: securityCode,
            // トークン発行数
            number: 1
        }

        fincodeInstance.tokens(card,
            function (status, response) {
                if (200 === status) {
                    // リクエスト正常時の処理
                    if(props.onGetCardToken)
                    {
                        props.onGetCardToken(response.list[0].token);
                    }
                } else {
                    // リクエストエラー時の処理
                }
            },
            function () {
                // 通信エラー処理
            }
        );
    }

  // html要素の返却
    return (
        <div className='FinCodeCardInput'>
            <Stack className="spacing-16px">
                <Row className="spacing-16px">
                    <TextInputWithLabel 
                        isLoading={props.isLoading} 
                        label="カード番号" type="text" 
                        onchange={(e) => setCardNo(e.target.value)} 
                        placeholder="0000 0000 0000 0000" 
                        default={props.cardInfo ? props.cardInfo.card_no : ""} 
                        firstClear={true}/>
                </Row>
                <Row className="spacing-16px">
                    <TextInputWithLabel 
                        isLoading={props.isLoading} 
                        label="有効期限(月)" type="text" 
                        onchange={(e) => setExpireMonth(e.target.value)} 
                        placeholder="01-12" 
                        default={props.cardInfo ? props.cardInfo.expire.substring(2, 4) : ""} 
                        firstClear={true}/>
                    <TextInputWithLabel 
                        isLoading={props.isLoading} 
                        label="有効期限(年)" type="text" 
                        onchange={(e) => setExpireYear(e.target.value)} 
                        placeholder="25" 
                        default={props.cardInfo ? props.cardInfo.expire.substring(0, 2) : ""} 
                        firstClear={true}/>
                </Row>
                <Row>
                    <TextInputWithLabel 
                        isLoading={props.isLoading} 
                        label="セキュリティコード" type="text" 
                        onchange={(e) => setSecurityCode(e.target.value)} 
                        placeholder="000" parentclassName="width-50percent" 
                        default={props.cardInfo ? "●●●" : ""} 
                        firstClear={true}/>
                </Row>
                <Row className="spacing-16px">
                    <TextInputWithLabel 
                        isLoading={props.isLoading} 
                        label="カード名義人" type="text" 
                        onchange={(e) => setHolderName(e.target.value)} 
                        placeholder="YOUR NAME"  
                        default={props.cardInfo ? props.cardInfo.holder_name : ""} 
                        firstClear={true}/>
                </Row>
                <Row className="spacing-16px">
                    <Button className="primary margin-top-16px" 
                        isLoading={props.isLoading} onClick={onClickCardButton}>{props.buttonlabel}</Button>
                </Row>
            </Stack>
        </div>
    );
};

export default FinCodeCardInput;
