// library imports

// styles imports
import './LayoutElement.scss';

const PageContent = (props) => {
    return (
        <div id={props.pageId} className="page-content">
            {props.children}
        </div>
    );
};

export default PageContent;