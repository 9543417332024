// library imports

// styles imports
import './LayoutElement.scss';

const SceneRoot = (props) => {
    return (
        <div className="scene-root">
            {props.children}
        </div>
    );
};

export default SceneRoot;