// styles imports
import './TextButton.scss';

export const Comment = `
	/**
	 *
	 * props の定義
	 *
	 * [set]
	 * 		id				...	スタイルシートid指定の文字列
	 * 		class			...	スタイルシートクラス指定の文字列
	 *
	 * [act]
	 * 		onclick			...	クリック時に実行したい処理
	 */
	 `

export const TextButton = (props) => {
	return (
			<div
				id={props.id}
				className={"textbutton " + (props.className ? props.className : "")}
				onClick={(props.onClick) ? props.onClick : null}>
				{props.children}
			</div>
	);
}
export default TextButton