// library imports
import { useDispatch } from "react-redux";

// User Component imports
import { ApiRequest } from "_core/apis/ApiRequest";
import { setApiTimestamp, setTransactionData } from "_core/slices/datas/TransactionSlice";
import { setMasterData } from "_core/slices/datas/MasterSlice";

const PaymentHistoryApi = () => {
    const dispatch = useDispatch();

    // API固有の設定、URLとリクエストd
    const url = "/payment/history";

    // API実行
    const ExecPaymentHistoryApi = async ({}) => {
        // 必要なリクエストを定義

        const requestParams = {
        };

        let response = null;
        let error = null;

        // console.log(url + "API開始");
        try {
            response = await ApiRequest({ url, requestParams});
            // console.log(response);
        } catch (e) {
            // console.log(e);
            error = e;
        } finally {
            // console.log(url + "　API終了");
        }

        // レスポンスがあれば、データ更新
        if (response !== null && response.error_code === 0) {
            dispatch(setApiTimestamp(response['now_time']));
        }
        return { response, error };
    };

    return ExecPaymentHistoryApi;
};

export default PaymentHistoryApi;