// library imports
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { initFincode, getCardToken } from "@fincode/js"

// User Component imports
import { setLoading, setMenuState, setErrorModal, setInitializeState } from "_core/slices/scene/SceneSlice";
import { PageContent, PageHeader, PageSideContents, PageWrapper, PageContainer } from 'components/common/layout_element/LayoutElement';
import { Button, Icon, Radio, Row, Stack, TextInput, Card } from "_core/components/common/UI";
import FinCodeCardInput from "_core/components/common/fincode/FinCodeCardInput";
import * as Constants from "_core/configs/Constants";

import PaymentRegisterCardApi from "apis/PaymentRegisterCardApi";
import PaymentInfoApi from "apis/PaymentInfoApi";


// styles imports
import './PaymentSetting.scss';
import * as SceneConfig from "_core/configs/SceneConfig";

const PaymentSetting = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ExecPaymentRegisterCardApi = PaymentRegisterCardApi();
    const ExecPaymentInfoApi = PaymentInfoApi();
    const { mPayment } = useSelector(store => store.master)
    const { cardInfo } = useSelector(store => store.transaction)
    const { initializeState } = useSelector((store) => store.scene);

    useEffect(() => {
        dispatch(setMenuState({ isHeader: false, isMenu: true, isFooter: false, isFullScreen: false }));
    }, [dispatch]);

    useEffect(() => {
        if(initializeState !== Constants.INITIALIZE_STATE.allComplete)
        {
            return
        }

        if(mPayment)
        {
            dispatch(setInitializeState(Constants.INITIALIZE_STATE.pageDataFetchComplete))
            return
        }

        dispatch(setInitializeState(Constants.INITIALIZE_STATE.pageDataFetching))
        GetInfo();

    }, [initializeState]);

    const GetInfo = async () => {
        dispatch(setLoading(true));
        const result = await ExecPaymentInfoApi({});
        // 通信中の表示を消す
        dispatch(setLoading(false));
        if (result.error || (result.response && result.response.error_code > 0)) {
            // エラーモーダル表示
            dispatch(setErrorModal({ show: true, text: result.response.error_detail }));
        }
        else
        {
            dispatch(setInitializeState(Constants.INITIALIZE_STATE.pageDataFetchComplete))
        }
    }

    const RegisterCard = async (cardToken) => {
        const result = await ExecPaymentRegisterCardApi({"cardToken" : cardToken});
        // 通信中の表示を消す
        dispatch(setLoading(false));
        if (result.error || (result.response && result.response.error_code > 0)) {
            // エラーモーダル表示
            dispatch(setErrorModal({ show: true, text: result.response.error_detail }));
        }
        else
        {
        }
    }

    return (
        <PageContent pageId="PaymentSetting">
            <PageHeader>
                {/* ここにページ内ヘッダー部分のコンポーネントを記述 */}
                <Stack className="spacing-4px">
                    <div className="page-header-title">お支払い設定</div>
                    <div className="page-header-detail">決済に使用するお支払情報の登録/更新を行います</div>
                </Stack>
            </PageHeader>
            <PageWrapper>
                <PageContainer>
                    <Row className="flex-center">
                        <Card className="base-card width-full">
                            <Row className="flex-center">
                                <Card className="card-input-area">
                                    <Stack className="spacing-16px">
                                        <Row className="flex-center">
                                            <div className="text-size-large">
                                                お支払い方法
                                            </div>
                                        </Row>
                                        <FinCodeCardInput 
                                            onGetCardToken={RegisterCard}
                                            cardInfo={cardInfo}
                                            buttonlabel="カード情報登録"
                                            isLoading={(initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false}
                                        />
                                    </Stack>
                                </Card>
                            </Row>
                        </Card>
                    </Row>
                </PageContainer>
            </PageWrapper>
        </PageContent>
    );
};

export default PaymentSetting;