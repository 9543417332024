// library imports
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// User Component imports
import { PageContent, PageHeader, PageWrapper, PageContainer } from 'components/common/layout_element/LayoutElement';
import * as Constants from "_core/configs/Constants";
import * as LocalStorageUtils from "_core/utils/localstorage";
import {Row, Button, TextInput, Stack, Select, ColorPicker, Uploader, Icon, Card, Video, Tooltip, Switch} from "_core/components/common/UI";
import {InfoCard} from "components/common/parts";
import { setLoading, setMenuState, setErrorModal, setInitializeState, setSceneParam } from "_core/slices/scene/SceneSlice";
import { PreviewIFrame } from "components/admin/parts/PreviewIFrame";

// styles imports
import './AdminUserProfileEdit.scss';
import * as SceneConfig from "_core/configs/SceneConfig";
import AdminUserEditApi from "apis/AdminUserEditApi";
import AdminUserAssetDeleteApi from "apis/AdminUserAssetDeleteApi";
import AdminUserMovieAssetUploadApi from "apis/AdminUserMovieAssetUploadApi";


const AdminUserProfileEdit = () => {
    const dispatch = useDispatch();
    const idleUploaderRef = useRef();
    const talkUploaderRef = useRef();

    const ExecAdminUserEditApi = AdminUserEditApi();
    const ExecAdminUserAssetDeleteApi = AdminUserAssetDeleteApi();
    const ExecAdminUserMovieAssetUploadApi = AdminUserMovieAssetUploadApi();

    const { tUser } = useSelector((store) => store.transaction);
    const { tUserAdd } = useSelector((store) => store.transaction);
    const { tAccount } = useSelector((store) => store.transaction);
    const { tAppearanceList } = useSelector((store) => store.transaction);
    const { tAssetList } = useSelector((store) => store.transaction);
    const { initializeState } = useSelector((store) => store.scene);
    const {sceneParam} = useSelector((store) => store.scene);
    const [createdAppearance, setCreatedAppearance] = useState(0);
    const [tag, setTag] = useState("");
    const [targetTag, setTargetTag] = useState("");
    const [jsonPath, setJsonPath] = useState("");
    const [welcome, setWelcome] = useState("");
    const [buttonText, setButtonText] = useState("");
    const [topTargetId, setTopTargetId] = useState("");
    const [topWelcome, setTopWelcome] = useState("");
    const [selectedColor, setSelectedColor] = useState('');
    const [selectedHoverColor, setSelectedHoverColor] = useState('');
    const [selectedTextColor, setSelectedTextColor] = useState('#FFFFFF');
    const [widjetPosition, setWidjetPosition] = useState('right');
    const [selectedChatColor, setSelectedChatColor] = useState('');
    const [selectedAppearance, setSelectedAppearance] = useState(null);
    const [canUpdateProfile, setCanUpdateProfile] = useState(false);
    const [config, setConfig] = useState();
    const [isMovieSelect, setIsMovieSelect] = useState(false);
    const [idleFile, setIdleFile] = useState(null);
    const [talkFile, setTalkFile] = useState(null);
    const [movieSetName, setMovieSetName] = useState("");
    const [previewUrl, setPreviewUrl] = useState("");
    const [previewUrlTemp, setPreviewUrlTemp] = useState("");
    const [isTargetExist, setIsTargetExist] = useState(true);
    const [currentHTML, setCurrentHTML] = useState("");
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);
    const [isSPLayout, setIsSPLayout] = useState(false);

    const [isMaintainer, setIsMaintainer] = useState(false);
    
    useEffect(() => {
        dispatch(setMenuState({ isHeader: false, isMenu: true, isFooter: false, isFullScreen: true }));

        // ローカルストレージからプレビューURLを取得
        const savedPreviewUrl = LocalStorageUtils.GetItem(Constants.LOCALSTORAGE_KEYS.previewUrl);
        if (savedPreviewUrl == null || savedPreviewUrl === "null"){
            setPreviewUrl("")
            setPreviewUrlTemp("")
            LocalStorageUtils.SetItem(Constants.LOCALSTORAGE_KEYS.previewUrl, "");
        }
        if (savedPreviewUrl) {
            setPreviewUrl(savedPreviewUrl)
            setPreviewUrlTemp(savedPreviewUrl)
        }

        if (sceneParam && sceneParam.selectedTAppearance && sceneParam.selectedTAppearance > 0)
        {
            setCreatedAppearance(sceneParam.selectedTAppearance)
        }
    }, [dispatch]);
    
    const handleChangePreviewUrl = () => {
        if (previewUrlTemp) {
            setPreviewUrl(previewUrlTemp)
            LocalStorageUtils.SetItem(Constants.LOCALSTORAGE_KEYS.previewUrl, previewUrlTemp);
        }
        else
        {
            LocalStorageUtils.SetItem(Constants.LOCALSTORAGE_KEYS.previewUrl, "");
        }
    }

    // IDLE動画変更検知
    const getVideoUrl = (tAssetId) => {
        try {
            const selectedIdleTAsset = tAssetList.find(tAsset => tAsset.id === tAssetId)
            let sasUrl = ""
            let canDelete = false
            if (selectedIdleTAsset)
            {
                sasUrl = selectedIdleTAsset.sas
            }

            // videoUrlを設定
            return sasUrl
        } catch (error) {
            return null
        }
    };

    // 見た目情報の関連情報を全て取得
    const getAssetInfo = (tAppearance) => {
        if (! tAppearance)
        {
            return
        }
        if (! tAssetList)
        {
            return
        }

        const idleTAsset = tAssetList.find(tAsset => tAsset.id === tAppearance.idle_asset)
        const idleSasUrl = getVideoUrl(idleTAsset.id)

        const talkTAsset = tAssetList.find(tAsset => tAsset.id === tAppearance.talk_asset)
        const talkSasUrl = getVideoUrl(talkTAsset.id)

        // 見た目情報に関する情報を収集して一つのオブジェクトとして返却する
        return {
            tAppearance : tAppearance,
            idleTAsset : idleTAsset,
            idleSasUrl : idleSasUrl,
            talkTAsset : talkTAsset,
            talkSasUrl : talkSasUrl,
        }
    }

    useEffect(() => {
        const isUpdate = GetIsUpdateProfile()
        setCanUpdateProfile(isUpdate)
        updatePreviewContent();
    }, [selectedColor, selectedHoverColor, selectedTextColor, widjetPosition, selectedChatColor, welcome, buttonText, topTargetId, topWelcome]);
        
    useEffect(() => {
        updatePreviewContent();
    }, [selectedAppearance]);

    useEffect(() => {
        if(initializeState !== Constants.INITIALIZE_STATE.allComplete)
        {
            return
        }
        // 初期設定
        setWidjetPosition(tUserAdd["position"])
        setSelectedColor(tUserAdd["button_color"])
        setSelectedHoverColor(tUserAdd["button_hover_color"])
        setSelectedTextColor(tUserAdd["text_color"])
        setSelectedChatColor(tUserAdd["chat_color"])
        setWelcome(tUserAdd["welcome_msg"])
        setButtonText(tUserAdd["button_msg"])
        setTopTargetId(tUserAdd["top_target_id"])
        setTopWelcome(tUserAdd["top_welcome_msg"])
        if (tUserAdd.last_json_path)
        {
            // タグの記載内容を取得
            const currentTag = GetCurrentScriptTag(tUserAdd.last_json_path)
            setJsonPath(tUserAdd.last_json_path)
            setTag(currentTag)
        }
        if (tUserAdd["top_target_id"] !== "")
        {
            // タグの記載内容を取得
            const currentTargetTag = GetCurrentTargetTag(tUserAdd["top_target_id"])
            setTargetTag(currentTargetTag)
        }
        UpdateSelectedAppearance(null)

        dispatch(setInitializeState(Constants.INITIALIZE_STATE.pageDataFetchComplete))
    }, [initializeState]);

    useEffect(() => {
        UpdateSelectedAppearance(null)
    }, [createdAppearance]);

    useEffect(() => {
        if (topTargetId !== "")
        {
            // タグの記載内容を取得
            const currentTargetTag = GetCurrentTargetTag(topTargetId)
            setTargetTag(currentTargetTag)
        }
        else
        {
            setTargetTag("")
        }
    }, [topTargetId]);

    function UpdateSelectedAppearance(response)
    {
        // セレクトボックスの設定

        let targetTAppearanceList = tAppearanceList
        if (response != null && response.t_appearance_list != null)
        {
            targetTAppearanceList = response.t_appearance_list
        }
        let targetTUserAdd = tUserAdd
        if (response != null && response.t_user_add != null)
        {
            targetTUserAdd = response.t_user_add
        }

        if (!targetTAppearanceList)
        {
            return;
        }

        // 見た目リスト取得
        let selectedAppearanceTemp = targetTAppearanceList.find(tAppearance => tAppearance.idle_asset === targetTUserAdd["idle_t_asset_id"]);
        if (createdAppearance > 0)
        {
            const tAppearanceId = createdAppearance
            selectedAppearanceTemp = targetTAppearanceList.find(tAppearance => tAppearance.id === tAppearanceId);
            dispatch(setSceneParam({}));
        }

        
        if (selectedAppearanceTemp)
        {
            const assetInfo = getAssetInfo(selectedAppearanceTemp)
            setSelectedAppearance(assetInfo)
        }
        else
        {
            const defaultAppearanceTemp = targetTAppearanceList.find(tAppearance => tAppearance.id === 1);
            const assetInfo = getAssetInfo(defaultAppearanceTemp)
            setSelectedAppearance(assetInfo)
        }
    }

    const ExecAdminUserAssetDeleteApiRequest = async (asset_type) => {
        // アセット削除API
        // 削除確認ダイアログを表示
        const userConfirmed = window.confirm("本当に削除しますか？");

        if (!userConfirmed)
        {
            // キャンセルした場合は削除を行わない
            return;
        }
        // 通信中の表示を出す
        dispatch(setLoading(true));

        const assetInfo = getAssetInfo(selectedAppearance)
        let selectedData = null;
        if (asset_type == Constants.ASSET_TYPE.IDLE)
        {
            selectedData = tAssetList.find(tAsset => tAsset.id === assetInfo.idleTAsset.id);
        }
        else
        {
            selectedData = tAssetList.find(tAsset => tAsset.path === assetInfo.talkTAsset.id);
        }
        const result = await ExecAdminUserAssetDeleteApi(selectedData.id,);

        // 通信中の表示を消す
        dispatch(setLoading(false));

        if (result.error || (result.response && result.response.error_code > 0))
        {
            // エラーモーダル表示
            dispatch(setErrorModal({ show: true, text: result.response.error_detail }));
        }
        else
        {
            UpdateSelectedAppearance(result.response)
            if(result.response.t_user_add != null)
            {
                // タグの記載内容を取得
                const currentTag = GetCurrentScriptTag(result.response.t_user_add.last_json_path)
                setJsonPath(result.response.t_user_add.last_json_path)
                setTag(currentTag)
            }
        }
    };

    const AdminAssetsUploadApiRequest = async (t_user_id, name, idle_file, idle_file_name, talk_file, talk_file_name) => {
        // アセットアップロードAPI
        
        // 通信中の表示を出す
        dispatch(setLoading(true));

        const result = await ExecAdminUserMovieAssetUploadApi(t_user_id, name, idle_file, idle_file_name, talk_file, talk_file_name);
        // 通信中の表示を消す
        dispatch(setLoading(false));
        if (result.error || (result.response && result.response.error_code > 0))
        {
            // エラーモーダル表示
            dispatch(setErrorModal({ show: true, text: result.response.error_detail }));
        }
        else
        {
            UpdateSelectedAppearance(result.response)
        }
    };


    const AdminUserEditApiRequest = async () => {
        // 編集API

        // 通信中の表示を出す
        dispatch(setLoading(true));

        const requestParams = {
            t_user_id: tUser["id"]
        };

        // 変更箇所のみ、リクエストに含める
        if (tUserAdd["position"] != widjetPosition)
        {
            requestParams["position"] = widjetPosition;
        }
        if (tUserAdd["button_color"] != selectedColor)
        {
            requestParams["button_color"] = selectedColor;
        }
        if (tUserAdd["button_hover_color"] != selectedHoverColor)
        {
            requestParams["button_hover_color"] = selectedHoverColor;
        }
        if (tUserAdd["chat_color"] != selectedChatColor)
        {
            requestParams["chat_color"] = selectedChatColor;
        }

        if (tUserAdd["text_color"] != selectedTextColor)
        {
            requestParams["text_color"] = selectedTextColor;
        }

        if (tUserAdd["welcome_msg"] != welcome)
        {
            requestParams["welcome_msg"] = welcome;
        }

        if (tUserAdd["button_msg"] != buttonText)
        {
            requestParams["button_msg"] = buttonText;
        }

        if (tUserAdd["top_target_id"] != topTargetId)
        {
            requestParams["top_target_id"] = topTargetId;
        }

        if (tUserAdd["top_welcome_msg"] != topWelcome)
        {
            requestParams["top_welcome_msg"] = topWelcome;
        }

        if (selectedAppearance && tUserAdd["idle_t_asset_id"] != selectedAppearance.idleTAsset.id)
        {
            requestParams["idle_t_asset_id"] = selectedAppearance.idleTAsset.id;
        }
        else if (!selectedAppearance && tUserAdd["idle_t_asset_id"] != 0)
        {
            requestParams["idle_t_asset_id"] = 0;
        }

        if (selectedAppearance && tUserAdd["talk_t_asset_id"] != selectedAppearance.talkTAsset.id)
        {
            requestParams["talk_t_asset_id"] = selectedAppearance.talkTAsset.id;
        }
        else if (!selectedAppearance && tUserAdd["talk_t_asset_id"] != 0)
        {
            requestParams["talk_t_asset_id"] = 0;
        }

        const result = await ExecAdminUserEditApi(requestParams);
        // タグの記載内容を取得
        const currentTag = GetCurrentScriptTag(result.response.t_user_add.last_json_path)
        setJsonPath(result.response.t_user_add.last_json_path)
        setTag(currentTag)

        // 通信中の表示を消す
        dispatch(setLoading(false));
        if (result.error || (result.response && result.response.error_code > 0))
        {
            // エラーモーダル表示
            dispatch(setErrorModal({ show: true, text: result.response.error_detail }));
        }
        else
        {
            setCanUpdateProfile(false);
        }
    };

    function GetCurrentScriptTag(replaceStr)
    {
        // 現在の最終作成jsonパスを使ってタグ情報を作成
        const currentTag = Constants.SCRIPT_TAG_BASE_STR.replace(Constants.SCRIPT_TAG_REPLACE_STR, replaceStr);
        return currentTag
    }

    function GetCurrentTargetTag(replaceStr)
    {
        // 現在の最終作成jsonパスを使ってタグ情報を作成
        const currentTag = Constants.TARGET_TAG_BASE_STR.replace(Constants.TARGET_TAG_REPLACE_STR, replaceStr);
        return currentTag
    }

    function GetIsUpdateProfile()
    {
        // 変更箇所が存在するか
        if (tUserAdd && tUserAdd["position"] != widjetPosition)
        {
            return true
        }

        // 変更箇所が存在するか
        if (tUserAdd && tUserAdd["button_color"] != selectedColor)
        {
            return true
        }

        if (tUserAdd && tUserAdd["button_hover_color"] != selectedHoverColor)
        {
            return true
        }
        
        if (tUserAdd && tUserAdd["text_color"] != selectedTextColor)
        {
            return true
        }
        
        if (tUserAdd && tUserAdd["chat_color"] != selectedChatColor)
        {
            return true
        }

        if (tUserAdd && tUserAdd["welcome_msg"] != welcome)
        {
            return true
        }
        if (tUserAdd && tUserAdd["button_msg"] != buttonText)
        {
            return true
        }

        if (tUserAdd && tUserAdd["top_target_id"] != topTargetId)
        {
            return true
        }

        if (tUserAdd && tUserAdd["top_welcome_msg"] != topWelcome)
        {
            return true
        }

        if (selectedAppearance && tUserAdd && tUserAdd["idle_t_asset_id"] != selectedAppearance.idleTAsset.id)
        {
            return true
        }

        return false
    }

    // 選択変更時の処理
    const handleChangeMovie = (tAppearance) => {
        const assetInfo = getAssetInfo(tAppearance)
        setSelectedAppearance(assetInfo)
        setIsMovieSelect(false)
    };

    // カラーピッカーからのカラー変更時の処理
    const handleColorChange = (color) => {
        setSelectedColor(color);
    };

    // カラーピッカーからのホバーカラー変更時の処理
    const handleHoverColorChange = (color) => {
        setSelectedHoverColor(color);
    };

    // カラーピッカーからのホバーカラー変更時の処理
    const handleTextColorChange = (color) => {
        setSelectedTextColor(color);
    };

    // カラーピッカーからのホバーカラー変更時の処理
    const handleChangePosition = (pos) => {
        setWidjetPosition(pos);
    };

    // カラーピッカーからのチャットカラー変更時の処理
    const handleChatColorChange = (color) => {
        setSelectedChatColor(color);
    };

    // ファイル選択後の処理
    function HandleIdleFileUpload(file,assetType)
    {
        setIdleFile({file: file, file_name: file.name})
    };

    // ファイル選択後の処理
    function HandleTalkFileUpload(file,assetType)
    {
        setTalkFile({file: file, file_name: file.name})
        
    };

    // ファイルアップボタンクリック時の処理
    function HandleFileUpload(){
        if (idleFile === null || talkFile === null)
        {
            return;
        }

        AdminAssetsUploadApiRequest(tUser.id, movieSetName, idleFile.file, idleFile.name, talkFile.file, talkFile.file_name)
    }

    // IDLEアップロードボタンのクリック時処理
    const handleIdleUploadButtonClick = () => {
        if (idleUploaderRef.current)
        {
            idleUploaderRef.current.openFileDialog();
        }
    };

    // TALKアップロードボタンのクリック時処理
    const handleTalkUploadButtonClick = () => {
        if (talkUploaderRef.current)
        {
            talkUploaderRef.current.openFileDialog();
        }
    };

    useEffect(() => {
        handleTargetHTMLChanged(currentHTML)
    }, [topTargetId]);

    const handleTargetHTMLChanged = (text) => {
        if (!topTargetId || topTargetId == "")
        {
            setIsTargetExist(false);
            return 
        }

        // id="で始まり"で終わる部分を正規表現で抽出
        const idMatches = [...text.matchAll(/id="([^"]+)"/g)];

        // 抽出されたIDリスト（スペースで分割されたサブIDを含む）
        const idLists = idMatches.map(match => match[1].split(/\s+/));

        // 指定された文字列が完全一致するかチェック
        const containsTargetId = idLists.some(idList => idList.includes(topTargetId));

        console.log("ターゲットIDをチェック");
        console.log(topTargetId);
        console.log(containsTargetId);
    
        setIsTargetExist(containsTargetId);
    }

    // コピー処理を実装
    const copyScriptTagToClipboard = () => {
        navigator.clipboard.writeText(tag)
            .then(() => {
                window.alert("タグをクリップボードにコピーしました");
            })
            .catch((err) => {

            });
    };

    // コピー処理を実装
    const copyTargetTagToClipboard = () => {
        navigator.clipboard.writeText(targetTag)
            .then(() => {
                window.alert("タグをクリップボードにコピーしました");
            })
            .catch((err) => {

            });
    };

    // プレビューを更新する
    function updatePreviewContent() {
        if (!selectedAppearance)
        {
            return
        }

        setConfig({
            prMovieUrl: selectedAppearance.idleSasUrl,
            prTalkMovieUrl: selectedAppearance.talkSasUrl,
            position: widjetPosition,
            buttonCol: selectedColor,
            buttonHoverCol: selectedHoverColor,
            buttonTextCol: selectedTextColor,
            chatMessageCol: selectedChatColor,
            chatWelcomText: welcome,
            buttonText: buttonText,
            topTargetId: topTargetId,
            topWelcomeText: topWelcome
        });
    };

    const editorDrawer = () => {
        return (
            <div className={"block-center margin-bottom-60px editor-drawer-setting " + ((isDrawerOpen && !isMovieSelect) ? "show" : "")}>
                <Stack className="spacing-32px align-left">
                    <Stack className="spacing-16px">
                        <Stack className="spacing-4px">
                            <div className="text-size-medium text-bold">外見</div>
                            <div className="text-size-small">広報担当AIの外見を選択できます</div>
                        </Stack>
                        <Row>
                            <Button className="video-button hidden width-full" onClick={() => {setIsMovieSelect(true)}}>
                                <Stack className="height-full width-full">
                                    <Row className="video-label flex-center align-center">
                                        {selectedAppearance && selectedAppearance.tAppearance.name}
                                    </Row>
                                    <Video className="vertical-fit" src={selectedAppearance ? selectedAppearance.idleSasUrl : ""}/>
                                </Stack>
                            </Button>
                        </Row>
                    </Stack>
                    <hr/>
                    <Stack className="spacing-16px">
                        <Stack className="spacing-4px">
                            <div className="text-size-medium text-bold">ウィジェット設定</div>
                            <div className="text-size-small">ウィジェット表示の見た目を設定できます</div>
                        </Stack>
                        <Row className= "width-full align-center spacing-16px flex-both">
                            <div>ボタンカラー</div>
                            <ColorPicker
                                name="colorPicker"
                                default={selectedColor}
                                color={selectedColor}
                                onchange={handleColorChange}
                                className="color-picker"
                            />
                        </Row>
                        <Row className= "width-full align-center spacing-16px flex-both">
                            <div>ボタンホバーカラー</div>
                            <ColorPicker
                                name="colorPicker"
                                default={selectedHoverColor}
                                color={selectedHoverColor}
                                onchange={handleHoverColorChange}
                                className="color-picker"
                            />
                        </Row>
                        <Row className= "width-full align-center spacing-16px flex-both">
                            <div>テキストカラー</div>
                            <ColorPicker
                                name="colorPicker"
                                default={selectedTextColor}
                                color={selectedTextColor}
                                onchange={handleTextColorChange}
                                className="color-picker"
                            />
                        </Row>
                        <Stack className= "width-full spacing-8px">
                            <div>ボタン表示</div>
                            <TextInput 
                                className = "width-full"
                                default={buttonText} 
                                onchange={(e) => setButtonText(e.target.value)} 
                                placeholder="ボタン表示メッセージを入力"  
                                isLoading={(initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false}
                            />
                        </Stack>
                        <Stack className= "width-full spacing-8px">
                            <div>表示位置</div>
                            <Select 
                                    className="long"
                                    name="videoSelect"
                                    value={widjetPosition}
                                    title={{"右下" : "right", "左下" : "left"}}
                                    onchange={handleChangePosition}
                            />
                        </Stack>
                    </Stack>
                    <hr/>

                    <Stack className="spacing-16px">
                        <Stack className="spacing-4px">
                            <div className="text-size-medium text-bold">チャット設定</div>
                            <div className="text-size-small">チャット表示の見た目を設定できます</div>
                        </Stack>
                        <Row className= "width-full align-center spacing-16px flex-both">
                            <div>チャットカラー</div>
                            <ColorPicker
                                name="colorPicker"
                                default={selectedChatColor}
                                color={selectedChatColor}
                                onchange={handleChatColorChange}
                                className="color-picker"
                            />
                        </Row>
                        <Stack className= "width-full spacing-8px">
                            <div>チャットWelcomeメッセージ</div>
                            <TextInput 
                                className = "width-full"
                                default={welcome} 
                                onchange={(e) => setWelcome(e.target.value)} 
                                placeholder="チャットWelcomeメッセージを入力"  
                                isLoading={(initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false}
                            />
                        </Stack>
                    </Stack>
                    <hr/>

                    <Stack className="spacing-16px">
                        <Stack className="spacing-4px">
                            <div className="text-size-medium text-bold">全体表示設定</div>
                            <div className="text-size-small">画面全体表示の見た目を設定できます</div>
                        </Stack>
                        <Stack className= "width-full spacing-8px margin-bottom-16px">
                            <div>トップWelcomeメッセージ</div>
                            <TextInput 
                                className="width-full"
                                default={topWelcome} 
                                onchange={(e) => setTopWelcome(e.target.value)} 
                                placeholder="トップWelcomeメッセージを入力"  
                                isLoading={(initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false}
                            />
                        </Stack>
                        <Stack className= "width-full spacing-8px">
                            <Row className="spacing-8px">
                                <div>ターゲットID</div>
                                <Tooltip>
                                    <div>全画面表示を行うHTML要素のidを指定します</div>
                                    <div>空欄の場合、全画面表示は行われません。</div>
                                </Tooltip>
                            </Row>
                            <TextInput 
                                className = "width-full"
                                default={topTargetId} 
                                onblur={(e) => setTopTargetId(e.target.value)} 
                                placeholder="ターゲットIDを入力"  
                                isLoading={(initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false}
                            />
                            { (topTargetId != "" && !isTargetExist) &&
                                <InfoCard className="danger text-size-small">
                                    <div>全画面表示の対象となるHTML要素が見つかりません。</div>
                                    <div>発行されたタグを、WEBサイト上のPRizmo<br/>表示箇所に挿入してください。</div>
                                </InfoCard>
                            }
                        </Stack>
                        <Stack className="spacing-8px">
                            <div>HTMLタグ</div>
                            <div className="clipboard-area relative">
                                <TextInput 
                                    className = "tag-input"
                                    value = {targetTag}
                                    placeholder="ターゲットIDを指定してください"  
                                    disabled={true}
                                    isLoading={(initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false}
                                />
                                <Button className={"clipboard-button "+(tag ? "hidden": "hidden disabled")} onClick={copyTargetTagToClipboard}><Icon icon="copy" size={20} className="copy-icon"/></Button>
                            </div>
                        </Stack>

                    </Stack>
                </Stack>
            </div>
        )
    }

    const movieSelectComponent = (tAppearance) => {
        const assetInfo = getAssetInfo(tAppearance)
        return (
            <Stack className="movie-select spacing-4px">
                <Button 
                    className={"video-button-row hidden width-full " + ((selectedAppearance && assetInfo.tAppearance.id === selectedAppearance.tAppearance.id) ? "selected" : "")}
                    onClick={() => {handleChangeMovie(tAppearance)}}
                    >
                    <Row className="height-full width-full flex-both">
                        <Stack className="video-label flex-center align-center text-size-normal">
                            {assetInfo.tAppearance.name}
                        </Stack>
                        <Video className="horizontal-fit" src={assetInfo.idleSasUrl}/>
                    </Row>
                    <Stack className="check-mark">
                        <Icon className="label-icon" icon="check" size={20} />
                    </Stack>
                </Button>
                {/* {urlObj.canDelete &&
                    <Button className="delete-button hidden" onClick={() => {ExecAdminUserAssetDeleteApiRequest(pathOption)}}>
                        <Icon className="label-icon" icon="x" size={18} />
                        <Stack className="flex-center align-center line-adjusted text-size-small">
                            動画を削除
                        </Stack>
                    </Button>
                } */}
            </Stack>
        )
    }

    const editorSelectMovie = () => {
        return (
            <div className={"block-center margin-bottom-60px editor-drawer-movie " + ((isDrawerOpen && isMovieSelect) ? "show" : "")}>
                <Stack className="spacing-16px align-left">
                    <Button className="hidden back-button" onClick={() => {setIsMovieSelect(false)}}>
                        <Row className="flex-left spacing-8px width-full">
                            <Icon className="label-icon" icon="chevron-left" size={18} />
                            <Stack className="flex-center align-center line-adjusted text-normal-weight">
                                戻る
                            </Stack>
                        </Row>
                    </Button>
                    <Stack className="spacing-16px">
                        <Stack className="spacing-4px">
                            <div className="text-size-medium text-bold">外見</div>
                            <div className="text-size-small">広報担当AIの外見を選択できます</div>
                        </Stack>
                        <Stack className="movie-select-list spacing-16px">
                            {tAppearanceList &&
                                tAppearanceList.map((tAppearance) => (
                                    movieSelectComponent(tAppearance)
                                ))
                            }
                        </Stack>
                    </Stack>

                    {isMaintainer && 
                        <Stack className="spacing-16px">
                            <hr/>
                            <Stack className="spacing-4px">
                                <div className="text-size-medium text-bold">動画アップロード</div>
                                <div className="text-size-small">外見に使用する動画をアップロードすることができます</div>
                            </Stack>
                            <Stack className="spacing-8px">
                                <div className="text-size-light-medium">待機動画</div>
                                <Row className= "width-full align-center spacing-8px">
                                    {idleFile ? 
                                        <Button className="movie-up-button hidden" onClick={handleIdleUploadButtonClick}>
                                            <Stack className="flex-center align-center line-adjusted">
                                                {idleFile.file_name}
                                            </Stack>
                                        </Button>
                                    :
                                        <Button className="movie-up-button hidden" onClick={handleIdleUploadButtonClick}>
                                            <Icon className="label-icon" icon="plus" size={24} />
                                            <Stack className="flex-center align-center line-adjusted">
                                                アップロード
                                            </Stack>
                                        </Button>
                                    }
                                    <Uploader
                                        ref={idleUploaderRef}
                                        className="custom-uploader"
                                        accept={Constants.ACCEPT_VIDEO_EXTENSION_LIST}
                                        onUpload={(file) => HandleIdleFileUpload(file, Constants.ASSET_TYPE.IDLE)}
                                    />
                                </Row>
                            </Stack>
                            <Stack className="spacing-8px">
                                <div className="text-size-light-medium">会話動画</div>
                                <Row className= "width-full align-center spacing-8px">
                                    {talkFile ? 
                                        <Button className="movie-up-button hidden" onClick={handleTalkUploadButtonClick}>
                                            <Stack className="flex-center align-center line-adjusted">
                                                {talkFile.file_name}
                                            </Stack>
                                        </Button>
                                    :
                                        <Button className="movie-up-button hidden" onClick={handleTalkUploadButtonClick}>
                                            <Icon className="label-icon" icon="plus" size={24} />
                                            <Stack className="flex-center align-center line-adjusted">
                                                アップロード
                                            </Stack>
                                        </Button>
                                    }

                                    <Uploader
                                        ref={talkUploaderRef}
                                        className="custom-uploader"
                                        accept={Constants.ACCEPT_VIDEO_EXTENSION_LIST}
                                        onUpload={(file) => HandleTalkFileUpload(file, Constants.ASSET_TYPE.TALK)}
                                    />
                                </Row>
                            </Stack>
                            <Stack className="spacing-8px">
                                <div className="text-size-light-medium">動画セット名</div>
                                <TextInput 
                                    className="width-full"
                                    default={movieSetName} 
                                    onchange={(e) => setMovieSetName(e.target.value)} 
                                    placeholder="動画セット名を入力"
                                    isLoading={(initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false}
                                />
                            </Stack>
                            <Button className="primary" onClick={HandleFileUpload}>
                                <Row className="flex-center align-center spacing-8px">
                                    <Icon className="label-icon" icon="arrow-bar-up" size={18} />
                                    <Stack className="flex-center align-center line-adjusted">
                                        登録
                                    </Stack>
                                </Row>
                            </Button>
                        </Stack>
                    }

                </Stack>
            </div>
        )
    }

    

    return (
        <PageContent pageId="AdminUserProfileEdit">
            <div className="editor-wrapper">
                <Stack className={"editor-drawer " + (isDrawerOpen ? "expand" : "")}>
                    <Row className={"drawer-header align-center " + (isDrawerOpen ? "flex-right" : "flex-center")}>
                        <Button className={"drawer-close-button hidden flex align-center " + (isDrawerOpen ? "flex-right" : "flex-center")} onClick={() => setIsDrawerOpen(!isDrawerOpen)}> 
                            <Icon icon={isDrawerOpen ? "chevron-left" : "chevron-right"} size={16} />
                        </Button>
                    </Row>
                    {/* ここにページ内コンテンツのコンポーネントを記述 */}
                    <Stack className="drawer-content">
                        {editorSelectMovie()}
                        {editorDrawer()}
                    </Stack>
                </Stack>
                <Stack className="editor-preview">
                    <div className="editor-header">
                        <Row className="flex-both spacing-8px">
                            <Row className="spacing-8px width-50percent">
                                <Stack className="spacing-8px width-full">
                                    <div>プレビューURL</div>
                                    <div className="clipboard-area width-full relative">
                                        <TextInput 
                                            className = "tag-input"
                                            default={previewUrl} 
                                            onchange={(e) => {
                                                setPreviewUrlTemp(e.target.value)
                                            }}
                                            placeholder="https://sample.co.jp"  
                                            isLoading={(initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false}
                                        />
                                        <Button className={"clipboard-button "+(tag ? "hidden": "hidden disabled")} onClick={handleChangePreviewUrl}><Icon icon="arrow-clockwise" size={20} className="copy-icon"/></Button>
                                    </div>
                                </Stack>
                                <Stack className="flex-bottom">
                                    <Switch onchange={(value) => {setIsSPLayout(value)}} default={false}></Switch>
                                </Stack>
                            </Row>
                            <Stack className="spacing-32px align-center">
                                <Row className= "width-full align-center spacing-16px flex-left">
                                    <Stack className="spacing-8px">
                                        <div>HTMLタグ</div>
                                        <div className="clipboard-area relative">
                                            <TextInput 
                                                className = "tag-input"
                                                default={tag} 
                                                value = {tag}
                                                placeholder="更新を行うとタグが発行されます"  
                                                disabled={true}
                                                isLoading={(initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false}
                                            />
                                            <Button className={"clipboard-button "+(tag ? "hidden": "hidden disabled")} onClick={copyScriptTagToClipboard}><Icon icon="copy" size={20} className="copy-icon"/></Button>
                                        </div>
                                    </Stack>
                                    <Stack className="spacing-32px align-center flex-bottom height-full">
                                        <Button
                                            className={"width-120px " +(canUpdateProfile ? "primary": "primary disabled")}
                                            onClick={() => AdminUserEditApiRequest()}>
                                            更新
                                        </Button>
                                    </Stack>
                                </Row>
                            </Stack>
                        </Row>
                    </div>
                    {/* プレビュー表示領域 */}
                    <Stack className="editor-preview-content flex-center align-center">
                        <PreviewIFrame
                            className={isSPLayout ? "sp" : ""}
                            config={config}
                            hostName={previewUrl}
                            tag={tag}
                            jsonPath={jsonPath}
                            onFetched={(text) => {
                                setCurrentHTML(text);
                                handleTargetHTMLChanged(text);
                            }}
                        />
                    </Stack>
                </Stack>
            </div>
    </PageContent>
    );
};

export default AdminUserProfileEdit;