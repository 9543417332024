export const APP_NAME = 'PRizmo | 管理画面';
export const APP_KEY = 'admin';
export const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
export const ENV = process.env.REACT_APP_ENVIRONMENT;

export const SaveAccount = 'Account';
export const SaveChat = 'Chat';

export const LOCALSTORAGE_KEYS = {
    sid : 'sid',
    adminSid : 'adminSid',
    isMenuOpen : 'isMenuOpen',
    previewUrl : 'previewUrl',
}

export const INITIALIZE_STATE = {
    before                      : 1,
    ready                       : 2,
    tUserFetching               : 3,
    tUserFetchComplete          : 4,
    tAccountFetching             : 7,
    tAccountFetchComplete        : 8,
    allComplete                 : 9,
    pageDataFetching            : 10,
    pageDataFetchComplete       : 11,
}

export const fileType = {
    "image/jpeg"        : 1,
    "image/png"         : 1,
    "image/svg+xml"     : 1,
    "video/mp4"         : 2,
    "video/webm"        : 2,
    "video/x-msvideo"   : 2,
    "image/gif"         : 3,
    "application/pdf"   : 4,
}


export const USER_AUTH_TYPE_NORMAL = 1;
export const USER_AUTH_TYPE_ADMIN = 10;
export const USER_AUTH_TYPE_OWNER = 20;

export const UserAuthType = {
    [USER_AUTH_TYPE_NORMAL]   :"一般",
    [USER_AUTH_TYPE_ADMIN]    :"管理者",
    [USER_AUTH_TYPE_OWNER]    :"オーナー",
}

export const PaymentType = {
    1: "サブスクリプション",
}

export const PAGE_ITEM_COUNT = {
    UserInfo : 10,
}

// fincodeのライブラリ読み込み先
export const FINCODE_SCRIPT_ENDPOINT = process.env.REACT_APP_FINCODE_SCRIPT_LOAD_ENDPOINT
export const FINCODE_PUBLIC_KEY = process.env.REACT_APP_FINCODE_PUBLIC_KEY

// アセットタイプ
export const ASSET_TYPE = {
    IDLE : 1,
    TALK : 2,
    JSON : 3,
    DOC  : 4,
    ORIGINAL_IDLE : 10,
    ORIGINAL_TALK : 20,
}

// アセットタイプ
export const GENDER_TYPE = {
    MALE : 1,
    FEMALE : 2,
}

// パスワード最小文字数
export const USER_PASSWORD_MIN_LENGTH = 8

// ファイルアップロード時の許可拡張子
export const ACCEPT_VIDEO_EXTENSION_LIST =".wmv, .mp4, .avi, .mov, .webm"
export const ACCEPT_DOC_EXTENSION_LIST =".pdf, .doc, .docx"

// 日付表示設定
export const RANGE_TYPE_SHORT  = 7;
export const RANGE_TYPE_MIDDLE = 28;
export const RANGE_TYPE_LONG   = 60;
export const RANGE_TYPE_CUSTOM = 999;

export const RangeLabelType = {
    [RANGE_TYPE_SHORT]   :"過去7日間",
    [RANGE_TYPE_MIDDLE]  :"過去28日間",
    [RANGE_TYPE_LONG]    :"過去60日間",
    [RANGE_TYPE_CUSTOM]  :"カスタム",
}

// ログファイルをDLする際に設定する名前
export const CHAT_LOG_CSV_FILE_NAME = 'ログデータ';

// タグ名
export const SCRIPT_TAG_BASE_STR = "<script src='prizmo.js' defer data-conf-url='#REPLACE_JSON_PATH#'></script>"
// タグ内の置き換え指定文字
export const SCRIPT_TAG_REPLACE_STR ="#REPLACE_JSON_PATH#"

// タグ名
export const TARGET_TAG_BASE_STR = "<div id='#REPLACE_TARGET_PATH#' style='all:unset; width:100%; height:100%; position:relative;'></div>"
// タグ内の置き換え指定文字
export const TARGET_TAG_REPLACE_STR ="#REPLACE_TARGET_PATH#"