import { useEffect } from "react";
import * as Constants from "_core/configs/Constants";

const Initialize = () => {
    useEffect(() => {
        // タブ名を変更する
        document.title = Constants.APP_NAME;
        // 動的にFaviconを変更する
        const favicon = document.querySelector('link[rel="icon"]') || document.createElement('link');
        favicon.rel = 'icon';
        favicon.href = '/favicon.png'; // 新しいFaviconのパス
        document.head.appendChild(favicon);
    }, []); // [] によって最初のレンダリング時のみ実行
    return <></>;
};

export default Initialize;
