// styles imports
import './Button.scss';

export const Comment = `
	/**
	 *
	 * props の定義
	 *
	 * [data-*]
	 * 		dataset			... [act] で指定の処理から e.target.dataset.json として呼び出したいJSON文字列
	 * [set]
	 * 		id				...	スタイルシートid指定の文字列
	 * 		class			...	スタイルシートクラス指定の文字列
	 * 		disabled		...	disabled 属性の指定のフラグ
	 * 		value		...	ボタンに表示する文字列
	 *
	 * [act]
	 * 		onclick			...	クリック時に実行したい処理
	 * 		onmousedown		...	クリック時にonBlurより先に実行したい処理
	 */
	 `

export const Button = (props) => {
	// コンポーネントの返却
	if (props.isLoading)
	{
		return (
		<div className={"button loading " + props.className}>{props.children}</div>
		);
	}

	return (
			<button
				id={props.id}
				className={"button " + props.className}
				{...(props.type ? { type: props.type } : {})}
				disabled={props.disabled}
				onClick={(props.onClick) ? props.onClick : null}
				onMouseDown={(props.onmousedown) ? props.onmousedown : null}
				onMouseOver={(props.onmouseover) ? props.onmouseover : null}
				onMouseOut={(props.onmouseout) ? props.onmouseout : null}
				data-json={props.dataset}
				ref={props.buttonRef}
			>
				<div className='button-label'>{props.children}</div>
			</button>
	);
}
export default Button