import { useDispatch } from "react-redux";
import { ApiRequest } from "../_core/apis/ApiRequest";
import { setMasterData } from "../_core/slices/datas/MasterSlice";
import { setApiTimestamp, setTransactionData } from "../_core/slices/datas/TransactionSlice";


const AdminReloadApi = () => {
    const dispatch = useDispatch();

    // API固有の設定、URLとリクエスト
    const url = "/admin/reload";

    // API実行
    const ExecAdminReloadApi = async () => {
        // 必要なリクエストなし

        let response = null;
        let error = null;
    
        // console.log(url + "API開始");
        try {
            response = await ApiRequest({ url });
            // console.log(response);
        } catch (e) {
            // console.log(e);
            error = e;
        } finally {
            // console.log(url + "　API終了");
        }

        // レスポンスがあれば、データ更新
        if (response !== null && response.error_code === 0) {
            dispatch(setApiTimestamp(response['now_time']));
            dispatch(setTransactionData({
                tAccount:response['t_account'],
            }));
            dispatch(setMasterData({
            }));
        }
        return { response, error };
    };

    return ExecAdminReloadApi;
};

export default AdminReloadApi;