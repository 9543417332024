import React, { useState, useEffect, useRef } from 'react';
import './Tooltip.scss';

// User Component imports
import {Row, Button, TextInput, Stack, Card, Icon} from "_core/components/common/UI";

export const Tooltip = (props) => {
  const [displayTooltip, setDisplayTooltip] = useState(false);
  const targetRef = useRef(null); // 対象要素
  const tipRef = useRef(null); // 対象要素
  const [fixedPosition, setFixedPosition] = useState({ top: 0, left: 0 }); // 座標情報

  const handleButtonClick = (displayTooltipValue) => {
    updatePosition();
    setDisplayTooltip(displayTooltipValue);
  };

  const updatePosition = () => {
    let position = {}
    if (targetRef.current) {
      const rect = targetRef.current.getBoundingClientRect(); // 要素の位置を取
      position = {
        top: rect.top,
        left: rect.left,
        bottom: rect.bottom,
        right: rect.right,
        height: 0,
        width: 0,
      }
    }

    if (tipRef.current){
      const tipRect = tipRef.current.getBoundingClientRect(); // 要素の位置を取得

      position.width = tipRect.width;
      position.height = tipRect.height;
    }

    setFixedPosition(position);
  };

  useEffect(() => {
    updatePosition();
  }, [displayTooltip]);

  useEffect(() => {
    // 初回の座標計算
    updatePosition();

    // スクロールやリサイズ時の座標更新
    window.addEventListener("scroll", updatePosition);
    window.addEventListener("resize", updatePosition);

    // クリーンアップ
    return () => {
      window.removeEventListener("scroll", updatePosition);
      window.removeEventListener("resize", updatePosition);
    };
  }, []);

  return (
    <div key={props.id} id={props.id} className={`tool-tip ${props.className || ''}`}>
      <button
        className='handle-button'
        onMouseOver={() => {handleButtonClick(true)}}
        onMouseOut={() => {handleButtonClick(false)}}
        ref={targetRef}
      ><Icon icon="question" size={16} className=""/></button>

      {displayTooltip && (
        <div ref={tipRef} className='tool-tip-float-area' style={{top : (fixedPosition.top - (fixedPosition.height + 12)), left : (fixedPosition.left  - ((fixedPosition.width * 0.3)))}}>
          <div className="tool-tip-float-area-group">
            <div className='tool-tip-float-shadow'></div>
            <Card className="tool-tip-float">
              {props.children}
            </Card>
          </div>
        </div>
      )}

    </div>
  );
};

export default Tooltip;
