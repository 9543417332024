import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import './Uploader.scss';

export const Uploader = forwardRef((props, ref) => {
	const fileInputRef = useRef(null);

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file && props.onUpload) {
			props.onUpload(file); // ファイルが選択されたら、ファイル情報を返す
		}
	};

	const openFileDialog = () => {
		fileInputRef.current.click(); // ファイル選択ダイアログを開く
	};

	useImperativeHandle(ref, () => ({
		openFileDialog,
	}));

	return (
		<div className={"uploader " + (props.className || '')}>
			<input
				type="file"
				ref={fileInputRef}
				accept={props.accept || '*'}
				style={{ display: 'none' }} // ボタンで代用するため非表示
				onChange={handleFileChange}
			/>
		</div>
	);
});

export default Uploader;
