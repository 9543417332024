import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { differenceInCalendarDays } from "date-fns"; 
import './CustomDateRangePicker.scss'
import Button from '../../../_core/components/common/UI/Button';
import Row from '../../../_core/components/common/UI/Row';
import Stack from "../../../_core/components/common/UI/Stack";


export const CustomDateRangePicker = (props) => {
	// 日数を計算する関数
	const calculateDays = (start, end) => {
		if (!start || !end) return null;
		return differenceInCalendarDays(new Date(end), new Date(start)) + 1; // 同日選択時は1日としてカウント
	};

	const selectedDays = calculateDays(props.startDate, props.endDate);

  	return (
  	  	<div id="custom-date-range-picker" className="date-range-picker">
			<div className="header">
				<Stack className="align-center">
					<Row className="spacing-8px input-row-area align-center">
						<span>
							{props.startDate ? props.startDate.toLocaleDateString("ja-JP") : "----/--/--"}
						</span>
						<span>-</span>
						<span>
							{props.endDate ? props.endDate.toLocaleDateString("ja-JP") : "----/--/--"}
						</span>
					</Row>
					{/* 日数が選択されている場合のみ表示 */}
					{selectedDays ? `${selectedDays}日間を選択しました` : "日付を選択してください"}
				</Stack>
			</div>

  	  		<DatePicker
  	  		  	selected={props.startDate}
  	  		  	onChange={props.onChange}
  	  		  	startDate={props.startDate}
  	  		  	endDate={props.endDate}
				selectsRange={props.selectsRange}
  	  		  	inline
				minDate={props.minDate}
				maxDate={props.maxDate}
				onCalendarClose={() => {
					// カレンダーを閉じたときに開始日が選択されている場合は終了日を選択可能にする
					if (props.startDate && !props.endDate) {
					  props.onChange([props.startDate, null]); // 開始日のみを保持
					}
				  }}
  	  		/>

  	  		<div className="buttons-area">
				<Row className="spacing-8px">
					<Button className="hidden" onClick={props.handleCancel}>キャンセル</Button>
					<Button className={props.startDate && props.endDate ? "primary" : "primary disabled"}  onClick={props.handleUpdate} disabled={!props.startDate || !props.endDate}>アップデート</Button>
				</Row>
  	  		</div>
  	  	</div>
  	);
}
export default CustomDateRangePicker 
