import React , { useEffect, useRef } from 'react'

import './Textarea.scss'

const Textarea = React.forwardRef((props, ref) => {
	/**
	 *
	 * props の定義
	 *
	 * [data-*]
	 * 		dataset			... [act] で指定の処理から e.target.dataset.json として呼び出したいJSON文字列
	 *
	 * [set]
	 * 		default			...	初期表示したいテキスト文章の文字列
	 * 		class				...	テキストエリアに施したいスタイルシートクラス指定の文字列
	 * 		name				...	テキストエリアに設定する id、および name 属性の指定の文字列
	 * 		line				...	テキストエリアが複数リストされる場合のindexとして機能
	 * 		placeholder	...	テキストエリアに設定する placeholder、および aria-label 属性の指定の文字列
	 * 		title				...	テキストエリアの設定する title 属性の指定の文字列
	 *
	 * 		disabled		...	テキストエリアの設定する disabled 属性の指定のフラグ
	 * 		change			...	テキスト文章にあわせてテキストエリアの高さを自動調整するフラグ
	 * 		html				...	テキストエリアがアンフォーカス時にHTMLとして解釈するフラグ
	 * 		escape			... mode=html の際に 危険な文字列をエスケープするかのフラグ。初期値は false である。
	 * 		startfromend... フォーカス時に文末に移動するかのフラグ。初期値は false である。
	 *
	 * 		events			...	［act］での指定を無視して、リッスンへの対応を上書きしたい場合の指定
	 *
	 * [act]
	 * 		onchange		...	テキスト変更時に実行したい処理
	 * 		onfocus			...	テキストエリアにフォーカスがあたったときに実行したい処理
	 * 		onblur			...	テキストエリアにフォーカスがなくなったときに実行したい処理
	 */






	/**
	 * 初期化と、変化への対応
	 */

	// ref指定が無い場合は自前で準備
	const thisref = useRef(null)
	ref = ref || thisref
	// アンフォーカス時のモードを決定
	let html = props.html != null ? props.html : false
	// ＨＴＭＬモードの際のエスケープ指示を決定
	const escape = props.escape != null ? props.escape : false
	// デフォルトテキストの決定
	const text = props.default ? props.default : '';
	// ダミーエリアへマーキング
	const refDummy = useRef(null);
	// テキストエリアのindexを指定。HTMLモードの場合の初期値はフォーカス要素とする。
	const line = (props.line != null) ? props.line : (html ? 0: false)
	// ＵＲＬ正規表現を準備
	const url_regexp = new RegExp("((https?|ftp)(://[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+))", 'g');
	// 文末からスタートするかのフラグを決定
	const startfromend = props.startfromend ? props.startfromend : false

	// レンダリング後の状態で決める初期化の処理
	useEffect(() => {
		// ダミーエリアにも同様にテキストを設定
		refDummy.current.textContent = text

		// ウインドウのリサイズ時イベントを設定
		window.addEventListener('resize', adjustheight);

		// コンポーネントの終了時処理
		return () => {
			window.removeEventListener('resize', adjustheight);
		}
	}, [])
	// レンダリング後の状態で決める初期化の処理と指定のデータ変化に応じた処理
	useEffect(() => {
		// 親要素のref指定がある場合、その変化に応じて
		if (ref !== null) {
			adjustheight();
		}
	}, [ref])

	/**
	 * リッスンへの対応
	 */

	// 指定の追加処理があれば実行
	const onChange = (e) => {
		// テキストに変更があった場合に、テキストエリアの高さを自動調整する指示があれば、調整を実行
		props.change && (ref.current.textContent = e.target.value +'\n');
		props.change && (refDummy.current.textContent = e.target.value +'\n');
		// HTMLモードの時は文書を正規表現で置き換える
		html && console.log(ref.current.innerHTML = text_change(e.target.value + '\n'))
		// その他、追加処理の指定があれば実行
		props.onchange && props.onchange(e)
	}
	const onClick = (e) => {
		// HTMLモードで、編集可の場合、編集モードへ移行
		html && ! props.disabled && onedit();
		// その他、追加処理の指定があれば実行
		props.onfocus && props.onfocus(e)
	}
	const onFocus = (e) => {
		// フォーカスを文字の最後尾に移動させる
		if (startfromend) {
			const length = e.target.value.length
			e.target.setSelectionRange(length, length)
		}

		// その他、追加処理の指定があれば実行
		props.onfocus && props.onfocus(e)
	}
	const onBlur  = (e) => {
		// テキストの両端の空白と改行を削除した状態にする。
		e.target.value = e.target.value.trim()
		props.change && (ref.current.textContent = e.target.value)
		props.change && (refDummy.current.textContent = e.target.value)
		html && (ref.current.innerHTML = text_change(e.target.value))

		// HTMLモードで、編集可の場合、編集モードを解除
		html && ! props.disabled && offedit();
		// その他、追加処理の指定があれば実行
		props.onblur  && props.onblur(e)
	}

	/**
	 * ローカル処理
	 */
	// 文書を正規表現で置き換える
	const text_change = text => {
		// HTMLモードの場合、URLをAタグに変更
		text = html ? text.replace(url_regexp, '<a href="javascript:document.activeElement.blur();open(\'$1\', \'_blank\' );">$1</a>') : text
		// 内容が空文字で、HTMLモードで、編集可である場合、placeholder で指定の文字列があれば、それを表示する。
		text = (text == '' && html && ! props.disabled) ? ((props.placeholder) ? props.placeholder : "<span className='placeholder'>input text</span>") : text
		return text
	}
	// HTMLモードで編集モードへ移行
	const onedit = () => {
		//console.log(refDummy)
		//console.log(ref)
		ref.current.style.visibility = 'hidden'
		refDummy.current.style.visibility = 'visible'
		refDummy.current.focus();
	}
	// HTMLモードで編集モードを解除
	const offedit = () => {
		ref.current.style.visibility = 'visible'
		refDummy.current.style.visibility = 'hidden'
	}
	// テキストエリアの高さを調整する
	const adjustheight = () => {
		refDummy.current.style.minHeight = window.getComputedStyle(ref.current).getPropertyValue("min-height");
		if (! props.change) {
			refDummy.current.style.height = window.getComputedStyle(ref.current).getPropertyValue("height");
		}
	}

	/**
	 * コンポーネントの返却
	 */

	return (
	<div className='FlexTextarea'>
		{(escape)
			? <div className={'textarea_html ' + (props.className ? props.className : "")} aria-hidden='true' style={{visibility: (html ? 'visible' : 'hidden')}} ref={html ? ref : refDummy}>{text}</div>
			: <div className={'textarea_html ' + (props.className ? props.className : "")} aria-hidden='true' style={{visibility: (html ? 'visible' : 'hidden')}} ref={html ? ref : refDummy}  tabindex={line} onClick={onClick} dangerouslySetInnerHTML={{__html: text_change(text)}} />
		}
		<textarea
			/* set */
			line={props.line}
			id={props.name}
			name={props.name}
			className={(props.className ? props.className : "")}
			defaultValue={text}
			placeholder={(props.placeholder) ? props.placeholder : "input text"}
			aria-label={(props.placeholder) ? props.placeholder : "input text"}
			title={props.title}
			disabled={props.disabled}
			tabIndex={line}
			/* dataset */
			data-json={props.dataset}
			/* act */
			onChange={onChange}
			onFocus={onFocus}
			onBlur={onBlur}
			{...props.events}
			/* ref */
			ref={html ? refDummy : ref}
			/* style */
			style={{visibility: (html ? 'hidden' : 'visible')}}
		/>
	</div>
	)
})
export default Textarea
