import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import * as SceneConfig from "_core/configs/SceneConfig";


const Footer = () => {
    const navigate = useNavigate();
    const { scene, parts, isFooter } = useSelector(store => store.scene);

    /**
     * フッター用Html
     * @returns 
     */
    function GetFooterHtml() {
        return <>
            <div className="footer-shadow"></div>
            <footer className="footer">
                {/* コース選択 */}
                {/* <div className="footer-button" onClick={() => navigate("/"+SceneConfig.SCENE_MESSAGES+"/"+SceneConfig.PARTS_HOME_CHAPTER)}>
                    <img src="/img/icon_account.png" alt="" />
                    <span>コース選択</span>
                </div> */}
                {/* お知らせボタン */}
                {/* <div className="footer-button" onClick={() => navigate(SceneConfig.SCENE_INFO)}>
                    <img src="/img/icon_osirase.png" alt="" />
                    <span>お知らせ</span>
                </div> */}
            </footer>
        </>;
    }

    /**
     * フッター表示条件のチェック
     * @returns 
     */
    function GetFooterFromScene() {
        if (
            (scene === SceneConfig.SCENE_ACCOUNT && parts === SceneConfig.PARTS_ACCOUNT_EDIT)
            // || (scene === SceneConfig.SCENE_HOME && parts === SceneConfig.PARTS_HOME_CHAPTER)
        ) {
            return <>{isFooter && <GetFooterHtml />}</>;
        }
        return;
    }

    return <GetFooterFromScene />;
};


export default Footer;
