// library imports
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// User Component imports
import { PageContent, PageHeader, PageSideContents, PageWrapper, PageContainer } from 'components/common/layout_element/LayoutElement';
import { Button, List, Row, Stack, TextInput, Modal, Divider, Text, Card } from "_core/components/common/UI";
import { setLoading, setMenuState, setErrorModal, setInitializeState } from "_core/slices/scene/SceneSlice";
import * as Constants from "_core/configs/Constants";
import PaymentHistoryApi from "apis/PaymentHistoryApi";
import PaymentHistoryDetailApi from "apis/PaymentHistoryDetailApi";
import { FormatDate } from "_core/utils/common";

// styles imports
import './PaymentHistory.scss';
import * as SceneConfig from "_core/configs/SceneConfig";

const PaymentHistory = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ExecPaymentHistoryApi = PaymentHistoryApi();
    const ExecPaymentHistoryDetailApi = PaymentHistoryDetailApi();
    
    const { initializeState } = useSelector((store) => store.scene);
    // 編集モーダルのステート
	const [detailModal, setDetailModal] = useState({ class: '', body: <></> });

    // メンバーリストデータ
	const [paymentHistoryListData, setPaymentHistoryListData] = useState({
        columsize: {
            order_id:3, type:2, target_id:2, amount:3, regdate:2
        },
        title: {
            order_id    : "決済ID",
            type        : "種別",
            target_id   : "対象ID",
            amount      : "決済金額",
            regdate     : "決済日時",
        },
		value: []
	});

    useEffect(() => {
        dispatch(setMenuState({ isHeader: false, isMenu: true, isFooter: false, isFullScreen: false }));
    }, [dispatch]);

    useEffect(() => {
        if(initializeState !== Constants.INITIALIZE_STATE.allComplete)
        {
            return
        }

        dispatch(setInitializeState(Constants.INITIALIZE_STATE.pageDataFetching))
        // API実行
        PaymentHistoryApiRequest();

    }, [initializeState]);

    const PaymentHistoryApiRequest = async () => {
        // 通信中の表示を出す
        dispatch(setLoading(true));

        const result = await ExecPaymentHistoryApi({});
        
        // 通信中の表示を消す
        dispatch(setLoading(false));
        if (result.error || (result.response && result.response.error_code > 0)) {
            // エラーモーダル表示
            dispatch(setErrorModal({ show: true, text: result.response.error_detail }));
        }
        else 
        {
            dispatch(setInitializeState(Constants.INITIALIZE_STATE.pageDataFetchComplete))
            CreatePaymentHistoryListData(result.response.t_payment_history_list)
        }
    };

    const PaymentHistoryDetailApiRequest = async (tPaymentHistoryId) => {
        // 通信中の表示を出す
        dispatch(setLoading(true));
        openDetailModal()

        const result = await ExecPaymentHistoryDetailApi({t_payment_history_id : tPaymentHistoryId});
        
        // 通信中の表示を消す
        dispatch(setLoading(false));
        if (result.error || (result.response && result.response.error_code > 0)) {
            // エラーモーダル表示
            dispatch(setErrorModal({ show: true, text: result.response.error_detail }));
        }
        else 
        {
            updateDetailModal(result.response)
        }
    };

    function CreatePaymentHistoryListData(userDataList)
    {
        // List用データの生成
		const listData = {
            columsize: {
                order_id:3, type:2, target_id:2, amount:3, regdate:2
            },
            title: {
                order_id    : "決済ID",
                type        : "種別",
                target_id   : "対象ID",
                amount      : "決済金額",
                regdate     : "決済日時",
            },
			value: []
		}

		// 使用するデータ
		let paymentHistoryList;
		paymentHistoryList = Object.values(userDataList);
		if(paymentHistoryList.length > 0) {		
			paymentHistoryList.forEach((paymentHistory) => {

				// データの整形
				const paymentHistoryData = {};
				// そのまま使えるデータを入れる
				paymentHistoryData.order_id = paymentHistory.order_id
                paymentHistoryData.type = Constants.PaymentType[paymentHistory.type]
                paymentHistoryData.target_id = paymentHistory.target_id
                paymentHistoryData.amount = paymentHistory.amount.toLocaleString() + " 円"
                paymentHistoryData.regdate = paymentHistory.regdate.replace("T", " ")

				// リスト用データの作成
				listData.value.push({
					data: paymentHistoryData,
					title: paymentHistoryData,
					onclick: () => PaymentHistoryDetailApiRequest(paymentHistory.id)
				});
			});
		}

		setPaymentHistoryListData(listData);
    }

    // 編集モーダルを開く
	const openDetailModal = () => {
		setDetailModal({
			class: 'show',
			body: detailModalBody(null)
		});
	};

    // 編集モーダルを開く
	const updateDetailModal = (response) => {
		setDetailModal({
			class: 'show',
			body: detailModalBody(response)
		});
	};

    //モーダルのボディ
	const detailModalBody = (response) => {
        let isLoading = false;

        // レスポンスがない場合は、ローディング表示用のデータをセット
        if (! response){
            isLoading = true;
            response = {}
            response.order_info = {}
            response.order_info.holder_name = "XXXXXXXXXXXXXX"
            response.order_info.card_no = "****-****-****-****"
            response.t_payment_history = {}
            response.t_payment_history.amount = 10000
            response.t_user_subscription_list=[
                { start_date: "2024-01-01 10:00:00", end_date: "2024-01-01 10:00:00", usage: "1000"},
                { start_date: "2024-01-01 10:00:00", end_date: "2024-01-01 10:00:00", usage: "1000"},
                { start_date: "2024-01-01 10:00:00", end_date: "2024-01-01 10:00:00", usage: "1000"},
                { start_date: "2024-01-01 10:00:00", end_date: "2024-01-01 10:00:00", usage: "1000"},
                { start_date: "2024-01-01 10:00:00", end_date: "2024-01-01 10:00:00", usage: "1000"},
            ]
        }

		return(
            <Stack className="spacing-16px">
                <Stack className="spacing-4px">
                    <Text className="text-size-small text-label">決済日</Text>
                    <Text isLoading={isLoading}className="text-size-large">{FormatDate(response.t_payment_history.regdate)}</Text>
                </Stack>
                <Divider/>
                <Stack className="spacing-4px">
                    <Text className="text-size-small text-label">請求先</Text>
                    <Row className="flex-both">
                        <Text isLoading={isLoading}>{response.order_info.holder_name}</Text>
                        <Text isLoading={isLoading}>{response.order_info.card_no}</Text>
                    </Row>
                </Stack>
                <Divider/>
                <Stack className="spacing-4px">
                    <Text className="text-size-small text-label">明細</Text>
                    <Stack className="spacing-4px detail-scroll">
                        {response.t_user_subscription_list.map((t_user_subscription) => {
                            return (
                                <Row className="flex-both">
                                    <Row className="spacing-16px">
                                        <Text isLoading={isLoading}>サブスクリプション利用</Text>
                                        <Text isLoading={isLoading}>{FormatDate(t_user_subscription.start_date)} ~ {FormatDate(t_user_subscription.end_date)}</Text>
                                    </Row>
                                    <Row className="spacing-16px">
                                        <Text isLoading={isLoading}>{t_user_subscription.usage.toLocaleString()}円</Text>
                                    </Row>
                                </Row>
                            )
                        })}

                    </Stack>
                    
                </Stack>
                <Divider/>
                <Stack className="spacing-4px">
                    <Text className="text-size-small text-label">合計</Text>
                    <Text  isLoading={isLoading} className="text-size-large">{response.t_payment_history.amount}円</Text>
                </Stack>
            </Stack>
		)
	}

    // 編集モーダルを閉じる
	const closeDetailModal = () => {
		setDetailModal({
			class: '',
			body: detailModalBody()
		});
	};

    return (
    <PageContent pageId="PaymentHistory">
        <PageHeader>
            {/* ここにページ内ヘッダー部分のコンポーネントを記述 */}
            <Stack className="spacing-4px">
                <div className="page-header-title">請求書</div>
                <div className="page-header-detail">過去に行われた決済の請求書情報を確認します</div>
            </Stack>
        </PageHeader>
        <PageWrapper>
            <PageContainer>
                <Card className="base-card">
                    <List
                        list={paymentHistoryListData}
                        header={true}
                        scrollbar={"scrollbar"}
                        className=""
                        isLoading={(initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false}
                    />
                    {/* {((initializeState >= Constants.INITIALIZE_STATE.pageDataFetchComplete) && (!paymentHistoryListData.listData || !paymentHistoryListData.listData.length === 0)) && 
                        <Row className="empty-state flex-center align-center">
                            <div>
                                表示できる履歴情報がありません。
                            </div>
                        </Row>
                    } */}
                </Card>
            </PageContainer>
        </PageWrapper>
        <Modal
            className={detailModal.class}
            dialogclass="modal-dialog-centered"
            name="detail-modal"
            title="お支払い詳細"

            showClose={true}
            close={() => {closeDetailModal()}}
            
            showConfirm={false}
            confirm={(e) => {}}
            confirmbtnclass="primary"
            confirmvalue={"送信"}
            
            showCancel={false}
            cancel={() => {closeDetailModal()}}
            cancelbtnclass="primary-border"
            cancelvalue={"キャンセル"}
            
            body={detailModal.body}
        />
    </PageContent>
    );
};

export default PaymentHistory;