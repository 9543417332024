// library imports
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PageContent, PageHeader, PageWrapper, PageContainer } from 'components/common/layout_element/LayoutElement';
import * as Constants from "_core/configs/Constants";

// User Component imports
import {Row, Button, TextInput, Stack, Card, Icon} from "_core/components/common/UI";
import { InfoCard } from "components/common/parts";
import { setLoading, setMenuState, setErrorModal, setInitializeState } from "_core/slices/scene/SceneSlice";

// styles imports
import './AdminUserEdit.scss';
import * as SceneConfig from "_core/configs/SceneConfig";
import AdminUserEditApi from "apis/AdminUserEditApi";


const AdminUserEdit = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ExecAdminUserEditApi = AdminUserEditApi();

    const RequestType = {name: "1", mail: "2", password: "3", domain: "4"}
    const [userName, setUserName] = useState("");
    const [email, setEMail] = useState("");
    const [password, setPassword] = useState("");
    const [domain, setDomain] = useState("");
    const [canUpdateName, setCanUpdateName] = useState(false);
    const [canUpdateEmail, setCanUpdateEmail] = useState(false);
    const [canUpdatePassword, setCanUpdatePassword] = useState(false);
    const [canUpdateDomain, setCanUpdateDomain] = useState(false);
    const { tUser } = useSelector((store) => store.transaction);
    const { tUserAdd } = useSelector((store) => store.transaction);
    const { tAccount } = useSelector((store) => store.transaction);
    const { initializeState } = useSelector((store) => store.scene);
    const [isFocus, setIsFocus] = useState(false);
    
    const GetDomain = () => {
        if (!domain || domain == "")
        {
            return ""
        }

        if (domain.includes("https://")) 
        {
            const parsedUrl = new URL(domain); // URLオブジェクトを生成
            return parsedUrl.hostname; // ホスト名を取得
        }
        else
        {
            const parsedUrl = new URL("https://" + domain); // URLオブジェクトを生成
            return parsedUrl.hostname; // ホスト名を取得
        }

    }
    const SetFullDomain = (fullDomain) => {
        if (!fullDomain || fullDomain == "")
        {
            return ""
        }

        if (fullDomain.includes("https://")) 
        {
            const parsedUrl = new URL(fullDomain); // URLオブジェクトを生成
            setDomain(parsedUrl.hostname); // ホスト名を取得
        }
        else
        {
            const parsedUrl = new URL("https://" + fullDomain); // URLオブジェクトを生成
            setDomain(parsedUrl.hostname); // ホスト名を取得
        }
    }

    useEffect(() => {
        dispatch(setMenuState({ isHeader: false, isMenu: true, isFooter: false, isFullScreen: false }));
    }, [dispatch]);
    
    useEffect(() => {
        if(initializeState !== Constants.INITIALIZE_STATE.allComplete)
        {
            return
        }
        setUserName(tUser["username"])
        setEMail(tAccount["email"])
        SetFullDomain(tUserAdd["domain"])
        dispatch(setInitializeState(Constants.INITIALIZE_STATE.pageDataFetchComplete))
    }, [initializeState]);

    useEffect(() => {
        if (tUser == null)
        {
            return;
        }
        setCanUpdateName(userName && tUser["username"] != userName)
    }, [userName]);

    useEffect(() => {
        if (tAccount == null)
        {
            return;
        }
        setCanUpdateEmail(email && tAccount["email"] != email)
    }, [email]);

    useEffect(() => {
        if (tUser == null)
        {
            return;
        }
        setCanUpdatePassword(password.length >= Constants.USER_PASSWORD_MIN_LENGTH)
    }, [password]);

    useEffect(() => {
        if (tUserAdd == null)
        {
            return;
        }
        setCanUpdateDomain(domain && tUserAdd["domain"] != domain)
    }, [domain]);

    const AdminUserEditApiRequest = async () => {

        // 通信中の表示を出す
        dispatch(setLoading(true));

        const requestParams = {
            t_user_id: tUser["id"]
        };

        if (tUser["username"] != userName)
        {
            requestParams["name"] = userName;
        }

        if (tAccount["email"] != email)
        {
            requestParams["email"] = email;
        }

        if (tUserAdd["domain"] != domain)
        {
            requestParams["domain"] = domain;
        }

        if(password != "")
        {
            requestParams["password"] = password;
        }

        const result = await ExecAdminUserEditApi(requestParams);
        // 通信中の表示を消す
        dispatch(setLoading(false));
        if (result.error || (result.response && result.response.error_code > 0))
        {
            // エラーモーダル表示
            dispatch(setErrorModal({ show: true, text: result.response.error_detail }));
        }
        else
        {
            setCanUpdateName(userName && result.response.t_user["username"] != userName)
            setCanUpdateEmail(email && result.response.t_account["email"] != email)
            setCanUpdateDomain(domain && result.response.t_user_add["domain"] != domain)
            if ("password" in requestParams)
            {
                setCanUpdatePassword(false)
            }
        }
    };

    function ResetDummyPassword()
    {
        if (isFocus)
        {
            return;
        }
        setIsFocus(true)
        const passwordInput =  document.getElementById('password-input');
        passwordInput.value = ""
    };

    return (
    <PageContent pageId="AdminUserEdit">
            <PageHeader>
                    <Stack className="spacing-4px">
                        <div className="page-header-title">基本情報</div>
                        <div className="page-header-detail">登録されている基本情報を更新します</div>
                    </Stack>
            </PageHeader>
            <PageWrapper>
                <PageContainer>
                    {/* ここにページ内コンテンツのコンポーネントを記述 */}
                    <div className="block-center">
                        <Card className="base-card">
                            <Stack className="spacing-32px align-center">
                                <Row className= "input-row-area align-center spacing-16px">
                                    <Stack className="input-label-area stack flex-center">
                                        <div className="input-label input-label-text text-size-medium text-bold">会社名</div>
                                    </Stack>
                                    <Stack className="input-form-area stack flex-center">
                                        <TextInput 
                                            default={userName} 
                                            onchange={(e) => setUserName(e.target.value)} 
                                            placeholder="会社名を入力" 
                                            isLoading={(initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false}
                                        />
                                    </Stack>
                                </Row>
                                <hr/>
                                <Row className= "input-row-area align-center spacing-16px">
                                    <Stack className="input-label-area stack flex-center">
                                        <div className="input-label input-label-text text-size-medium text-bold">メールアドレス</div>
                                    </Stack>
                                    <Stack className="input-form-area stack flex-center">
                                        <TextInput 
                                            type="email" 
                                            default={email} 
                                            onchange={(e) => setEMail(e.target.value)} 
                                            placeholder="メールアドレスを入力"  
                                            isLoading={(initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false}
                                        />
                                    </Stack>
                                </Row>
                                <hr/>
                                <Row className= "input-row-area align-center spacing-16px">
                                    <Stack className="input-label-area stack flex-center">
                                        <div className="input-label input-label-text text-size-medium text-bold">パスワード</div>
                                    </Stack>
                                    <Stack className="input-form-area stack flex-center">
                                        <TextInput 
                                            name="password-input"  
                                            type="password" 
                                            onfocus={() => ResetDummyPassword()} 
                                            onchange={(e) => setPassword(e.target.value)} 
                                            default="********" 
                                            firstClear={true}
                                            placeholder="パスワードを入力" 
                                            isLoading={(initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false}
                                        />
                                    </Stack>
                                </Row>
                                <hr/>
                                <Row className= "input-row-area align-center spacing-16px">
                                    <Stack className="input-label-area stack flex-center">
                                        <div className="input-label input-label-text text-size-medium text-bold">許可ドメイン</div>
                                    </Stack>
                                    <Stack className="input-form-area stack flex-center spacing-16px">
                                        <TextInput 
                                            default={GetDomain()} 
                                            onchange={(e) => SetFullDomain(e.target.value)} 
                                            placeholder="許可ドメインを入力"  
                                            isLoading={(initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false}
                                            disabled
                                        />
                                        <InfoCard>
                                            <div>PRizmoを設置するWEBページのドメイン名です。</div>
                                            <div>表示されているドメイン外からのアクセスはPRizmoの動作対象外です。</div>
                                        </InfoCard>
                                    </Stack>
                                </Row>
                            </Stack>
                            <Stack className="align-center margin-top-60px">
                                <Button className={"width-320px " +((canUpdateName || canUpdateEmail || canUpdatePassword || canUpdateDomain) ? "primary": "primary disabled")}  onClick={() => AdminUserEditApiRequest()}>更新</Button>
                            </Stack>
                        </Card>
                    </div>
                </PageContainer>
            </PageWrapper>
    </PageContent>
    );
};

export default AdminUserEdit;