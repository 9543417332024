import { useState, useEffect } from 'react';
import Textarea from './Textarea';
import { Link } from 'react-router-dom';

import './List.scss';

export const Comment = `
	/**
	 *
	 * props の定義
	 *
	 * [set]
	 * 		id					...	スタイルシートid指定の文字列
	 * 		class				...	スタイルシートクラス指定の文字列
	 * 		list				...	表示する列のcol情報、タイトル、内容をまとめたもの 基本となるサンプルは下に記載
	 * 		columsize			...	列の col の情報
	 * 		title				...	各列のタイトル
	 * 		value				...	1行分の情報({data(必須), display, onclick, link, tooltip})
	 *		onClick			... 1行分のクリック時処理
	 * 		header				...	リストの最上段に列のタイトルを表示するかのフラグ 指定しない場合は表示しない
	 * 		startfromend		...	文末からスタートするかのフラグ (デフォルトはfalse)
	 * 		edit				... 編集可能なセルにする際に指定 全てのセルが編集可能になる (デフォルトはfalse)
	 * 		cell				... データが配列でもオブジェクトでもない場合の変更指示の配列 該当のセルを置き換えて表示
	 * 		html				... 施したいスタイルシートクラス指定の文字列
	 * 		scrollbar			... スクロール の指定文字列
	 * 		sortColumnList ... ソートさせたいカラム名リスト
	 * 		initSortColumn	... 初期でソートをかけるカラム名
	 *
	 * [act]
	 * 		onclick				...	各セルをクリックした時に実行したい処理
	 * 		onchange			...	各セルを変更した時に実行したい処理
	 * 		onfocus				...	各セルをクリックした時に実行したい処理
	 * 		onblur				...	各セルのファーカスを外した時に実行したい処理
	 *
	 *
	 *
	 *		list={{
	 *			columsize: {
	 *				colum1: 3, colum2: 3, colum3: 3, cell: 3
	 *			},
	 *			title: {
	 *				colum1: "カラム1",
	 *				colum2: "カラム2",
	 *				colum3: "カラム3"
	 *				cell: "置き換え"
	 *			},
	 *			value: [
	 *				{colum1: "カラム1-1", colum2: "カラム1-2", colum3: "カラム1-3", cell: 1},
	 *				{colum1: "カラム2-1", colum2: "カラム2-2", colum3: "カラム2-3", cell: 2},
	 *				{colum1: "カラム3-1", colum2: "カラム3-2", colum3: "カラム3-3", cell: 3}
	 *			]
	 *		}}
	 *
	 *	cell の指定
	 *
	 *		cell={{
	 *			cell:{1:"置き換え1", 2:"置き換え2", 3:"置き換え3"}
	 *		}}
	 *
	 */
	 `;

export const List = (props) => {
	const columsize = Object.values(props.list.columsize);
	const titleList = props.list.title;
	const value = Object.values(props.list.value);
	// ソート対象のカラムリスト
	const sortColumnList = props.sortColumnList != null ? props.sortColumnList : [];
	// 初期でソートをかけるカラム
	let initSortColumn = props.initSortColumn != null ? props.initSortColumn : '';
	if (! sortColumnList.includes(initSortColumn)) {
		initSortColumn = '';
	}
	// ソート中のカラム名
	const [sortingColumnName, setSortingColumnName] = useState(initSortColumn);
	// 昇順降順管理ステートの初期化
	let initAscendingList = {};
	sortColumnList.forEach((column) => {
		initAscendingList[column] = false;
		if (sortingColumnName==column) {
			initAscendingList[column] = true;
		}
	});

	// タブインデックスの準備
	let tabindex = 0;
	// 文末からスタートするかのフラグを決定(デフォルトはfalse)
	const startfromend = props.startfromend ? props.startfromend : false;

	// 処理の準備
	const selectList = (select, value) => {
		if (props.onclick) {
			props.onclick(select, value, props.id);
		}
	};

	// 値リストのステート
	const [valueList, setValueList] = useState([]);
	// 昇順降順管理リストのステート
	const [ascendingList, setAscendingList] = useState(initAscendingList);

	// 値リスト更新時の処理
	useEffect(() => {
		// ソート情報が無ければそのまま更新
		if (sortingColumnName=='') {
			setValueList(Object.values(props.list.value));
		}
		// ソート情報がある場合は、ソートをかけた後の値で更新をかける
		else {
			const sortedList = sortList(Object.values(props.list.value), sortingColumnName, ! ascendingList[sortingColumnName]);
			setValueList(sortedList);
		}
	}, [props.list]);

	// 値リスト変更時の処理
	useEffect(() => {
		if(props.onChangeSort){
			props.onChangeSort();
		}
	}, [valueList]);

	// ソートを行う
	const handleSort = (key) => {
		// ソート実行後の配列を取得する
		let sortedList = sortList(valueList, key);
		// ソート実行後の配列をステートに登録
		setValueList(sortedList);
		// ソートを行ったカラム名を登録
		setSortingColumnName(key);

		// 昇順降順を反転させる
		let tempAscendingList = {...ascendingList};
		tempAscendingList[key] = ! ascendingList[key];
		setAscendingList(tempAscendingList);
	}

	/**
	 * 受け取った配列に対してソートを行う
	 *
	 * @author evl_nhayano
	 * @param {Array.<Object>} targetList ソート対象の配列
	 * @param {string} key ソートを行うカラム名
	 * @param {boolean} order 降順昇順の真偽値(trueで昇順)
	 * @returns ソートをかけた後の配列
	 */
	const sortList = (targetList, key, order) => {
		// 作業用配列にコピー
		let tempList = [...targetList]
		// 不正な配列の場合は元の配列を返却
		if (tempList==undefined || tempList.length==0) {
			return targetList;
		}
		// 昇順フラグに応じて返却値を設定
		let orderBy = ascendingList[key] ? 1 : -1;
		if (order != null) {
			orderBy = order ? 1 : -1;
		}

		const sortBasis = (tempList[0]['title'] && tempList[0]['title'][key] != null) ? "title" : "data";
		// リストの値が数値の場合
		if (Number.isFinite(Number(tempList[0][sortBasis][key])) && tempList[0][sortBasis][key] !== '') {
			tempList.sort((a, b) => {
				for (let i = 0; i < tempList.length; i++) {
					if (Number(a[sortBasis][key]) < Number(b[sortBasis][key])) {
						return orderBy * -1;
					}
					if (Number(a[sortBasis][key]) > Number(b[sortBasis][key])) {
						return orderBy;
					}
				}
				return 0;
			});
		}
		// リストの値がDIVの場合
		else if (tempList[0][sortBasis][key].type == "div")
		{
			tempList.sort((a, b) => {
				for (let i = 0; i < tempList.length; i++)
				{
					return (Number(a[sortBasis][key].props.id) - Number(b[sortBasis][key].props.id)) * orderBy;
				}
				return 0;
			})
		}
		// それ以外(文字列)の場合
		else {
			tempList.sort((a, b) => {
				for (let i = 0; i < tempList.length; i++) {
					return a[sortBasis][key].localeCompare(b[sortBasis][key], 'ja') * orderBy;
				}
				return 0;
			});
		}
		// 結果を返却する
		return tempList;
	}

	// 昇順、または降順のSVGを取得する
	const getChevronSVG = (key) => {
		if (ascendingList[key]) {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
					<path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
				</svg>
			);
		}
		else {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
					<path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
				</svg>
			);
		}
	}

	// タイトル部分の表示
	const renderTitle = () => {
		// タイトルコンポーネントの返却
		return (
			<div className={'title list-row' + ((props.className) ? (' ' + props.className) : '')}>
				{
					Object.keys(titleList).map((titleKey, column) => (
						<div
							key={'title:' + column}
							className="listtitle"
							style={(columsize[column]==0) ? {width: '0px', overflow: 'hidden', padding: '0', border: '0'} : {flex: columsize[column]}}
						>
							<div className={'cell-item' + (sortColumnList.includes(titleKey) ? ' sort-cell' : '')}>
								<span className="cell-title-text">{titleList[titleKey]}</span>
								{
									sortColumnList.includes(titleKey) &&
									<div
										className={'button button-hidden sort-button' + (titleKey==sortingColumnName ? ' sorting-button' : '')}
										onClick={() => {
											handleSort(titleKey)
										}}
									>
										{getChevronSVG(titleKey)}
									</div>
								}
							</div>
						</div>
					))
				}
			</div>
		);
	};
	// 内容部分の表示
	const renderValues = () => {
		// タブインデックスの初期化
		tabindex = 0;

		let list = Object.values(valueList).map((value, line) => (
			renderValue(value, line)
		));

		return (
			list
		);
	};
	// 内容部分の表示(1行分)
	const renderValue = (value, line) => {
		// アクションを受け付けるかの詳細指定がある場合
		let action = (Array.isArray(props.action))
			// 指定された条件を満たしていたら
			? (value[props.action[0]]==props.action[1])
				? true
				: false
			// 詳細指定がない場合は、アクション指定をそのまま受け付ける
			: props.action;

		// onClickの最終指定
		const onClick = () => {
			action && selectList(line, value);
			value.onclick && value.onclick();
		};

		// 表示する値を切り分ける
		let displayValue = {};
		if (value['display'] != null) {
			displayValue = value['display'];
		}
		else {
			displayValue = value['data'];
		}

		// 内容の作成
		let values = (
			<div
				id={props.id + '_' + line}
				key={displayValue.id != null ? displayValue.id : props.id + '[' + line + ']'}
				onClick={() => onClick()}
				className={'value list-row' + ((action) ? ' list-group-item-action' : '') + ((props.className) ? ' ' + props.className : '') + (value.class ? ' ' + value.class : '')}
			>
				{
					Object.keys(displayValue).map((key, column) => (
						<div
							Key={'value:' + line + ',' + column}
							className={'listitem cell-' + key}
							style={(columsize[column]==0) ? {display: 'none'} : {flex: columsize[column]}}
							data-tip={key=='risk' ? value.tooltip : ''}
							title={value.title ? value.title[key] ? value.title[key] : '' : ''}
						>
							<div className={"cell-item" + (props.isLoading ? " loading" : "")}>
								{
									(props.edit)
									// 編集指示がある場合はテキストエリアにする
									? <Textarea
											key={"value:" + line + "," + column}
											line={columsize[column] > 0 ? ++tabindex : -1}
											name={line}
											default={displayValue[key]}
											change={true}
											html={props.html}
											startfromend={startfromend}
											onchange={props.onchange}
											onfocus={props.onfocus}
											onblur={props.onblur}
										/>
									// 編集指示が無く、データが配列の場合は
									: (Array.isArray(displayValue[key]))
										// カンマ区切りで羅列する
										? displayValue[key].join(' , ')
										// データが配列でもオブジェクトでもなく、変更指示がある場合は、（指定方法例 . cell={{status:{valid:"有効",invalid:"無効"}}}）
										: (props.cell && props.cell[key])
											// 変更して表示する
											? props.cell[key][displayValue[key]]
											// そのまま表示する
											: displayValue[key]
								}
							</div>
						</div>
					))
				}
			</div>
		);
		// link指定があればLinkタグで囲む
		if (value.link) {
			values = <Link to={value.link}>{values}</Link>
		}

		// 内容コンポーネントの返却
		return (
			values
		);
	};

	// Loadingコンポーネントの返却
	if(props.isLoading)
	{
		if (! valueList || valueList.length == 0)
		{
			// ローディング中はタイトル列をコピーしてローディング行を表示
			let loadingListData = []
			const preRowCount = (props.loadingCount === null || props.loadingCount === undefined) ? 5 : props.loadingCount
	
			// 指定された回数だけループしてリストに要素を追加
			for (let i = 0; i <= preRowCount; i++) {
				// リスト用データの作成
				loadingListData.push({
					data: titleList,
					title: titleList,
					onclick: null
				});
			}
	
			setValueList(loadingListData);
		}
	}

	// Listコンポーネントの返却
	return (
		<div
			id={props.id}
			className={'List' + ((props.scrollbar) ? ' ' + props.scrollbar : '')}
			title={props.title}
		>
			{(props.header) ? renderTitle() : <></>}
			<div className="listbody">
				{renderValues(value)}
			</div>
		</div>
	);
};
export default List;
