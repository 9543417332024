// library imports
import React, { useEffect, useState, useRef  } from "react";
import { useDispatch, useSelector } from "react-redux";

// User Component imports
import { PageContent, PageHeader, PageWrapper, PageContainer } from 'components/common/layout_element/LayoutElement';
import { Button, Row, Stack, Card, Pagination} from "_core/components/common/UI";
import { CustomDateRangePicker, DateRadio, FitList} from "components/common/parts";
import * as Constants from "_core/configs/Constants";
import { setLoading, setMenuState, setErrorModal, setInitializeState } from "_core/slices/scene/SceneSlice";
import AdminUserChatLogInfoApi from "apis/AdminUserChatLogInfoApi";

// styles imports
import './AdminUserChatLog.scss';

const AdminUserChatLog = () => {
    const dispatch = useDispatch();
    const ExecAdminUserChatLogInfoApi = AdminUserChatLogInfoApi();
    const { tUser } = useSelector((store) => store.transaction);
    const { initializeState } = useSelector((store) => store.scene);

    const RangeLabelTypeKeys= Object.keys(Constants.RangeLabelType)
    const DispRangeLabelTypeValues= Object.values(Constants.RangeLabelType).filter(value => value !== Constants.RangeLabelType[Constants.RANGE_TYPE_CUSTOM])

    // 表示期間ラジオボタン
    const [dateRadioCheck, setDateRadioCheck] = useState(Constants.RANGE_TYPE_SHORT)
    // 日付カスタムラジオボタン表示内容
    const [dispCustomDate, setDispCustomDate] = useState(Constants.RangeLabelType[Constants.RANGE_TYPE_CUSTOM]);
    // 日付カスタムラジオボタンは文字が変動するため変数に格納しておく
    DispRangeLabelTypeValues.push(dispCustomDate)

    // 触っている日付範囲の制御
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    // 最終API実行対象日付範囲
    const [execApiStartDate, setExecApiStartDate] = useState(null);
    const [execApiEndDate, setExecApiEndDate] = useState(null);

    // 表示終了日は当日
    const maxDate = new Date();
    // 表示開始日はその一年前の日付
    const minDate = new Date(maxDate);
    minDate.setFullYear(maxDate.getFullYear() - 1);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isDownloadCsv, setIsDownloadCsv] = useState(false);

    // カレンダー設定
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const dialogRef = useRef(null);
    const radioLabelOptions = { month: 'long', day: 'numeric' };

    const DEFAULT_PAGE = 1
    const DEFAULT_LIMIT_FLG = 1

    // チャットログリストデータ
	const [chatLogListData, setChatLogListData] = useState({
        columsize: {
            ip_address:1, question:3,  answer:3, regdate:2
        },
        title: {
            ip_address: "IP",
            question: "質問",
            answer: "返答",
            regdate: "日時",
        },
		value: []
	});

    useEffect(() => {
        dispatch(setMenuState({ isHeader: false, isMenu: true, isFooter: false, isFullScreen: false }));
    }, [dispatch]);

    useEffect(() => {
        if(initializeState !== Constants.INITIALIZE_STATE.allComplete)
        {
            return
        }
        dispatch(setInitializeState(Constants.INITIALIZE_STATE.pageDataFetching))

        // 表示対象日付の設定
        const { nextStartDate, nextEndDate } = SetRangeByTargetDay(dateRadioCheck)

        // API実行
        AdminUserChatLogInfoApiRequest(DEFAULT_PAGE, DEFAULT_LIMIT_FLG, nextStartDate, nextEndDate)

    }, [initializeState]);

    useEffect(() => {
        setIsDownloadCsv(chatLogListData.value.length > 0)
      }, [chatLogListData]);

    useEffect(() => {
        if (isDialogVisible) 
        {
          document.addEventListener('mousedown', handleClickOutside);
        } 
        else 
        {
          document.removeEventListener('mousedown', handleClickOutside);
        }
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [isDialogVisible]);

    // カレンダーの外側をクリック
    const handleClickOutside = (event) => {
        if (dialogRef.current && !dialogRef.current.contains(event.target)) 
        {
            setIsDialogVisible(false);
            setDateRange([startDate, endDate]); 
            if(startDate && endDate)
            {
                setDispCustomDate(startDate.toLocaleDateString('ja-JP', radioLabelOptions)+"-"+endDate.toLocaleDateString('ja-JP', radioLabelOptions))
            }
        }
    };
    
    // カレンダーから表示日付範囲一時変更
    const handleDateChange = (update) => {
        // startDateとendDateを一度に更新
        setDateRange(update); 
    };

    // カレンダーでキャンセル
    const handleCancel = () => {
        // 日付選択をリセット
        setDateRange([null, null]); 
    };
   
    // カレンダーから表示日付範囲変更確定
    const handleUpdate = () => {
        // カレンダーを非表示
        setIsDialogVisible(false);
        // カスタムラジオボタンに表示する日付更新
        setDispCustomDate(startDate.toLocaleDateString('ja-JP', radioLabelOptions)+"-"+endDate.toLocaleDateString('ja-JP', radioLabelOptions))
        // API実行
        AdminUserChatLogInfoApiRequest(DEFAULT_PAGE, DEFAULT_LIMIT_FLG, startDate, endDate)
    };

    // 指定日数間をデータにセット
    function SetRangeByTargetDay(targetDay)
    {
        // 取得必要日数の調整のため、マイナス１する
        targetDay = Number(targetDay)-1

        // 現在の日付を取得
        const nextEndDate = new Date();
        // 今日の日付を計算
        nextEndDate.setDate(nextEndDate.getDate());
        // 指定された日数分前の日付を計算
        const nextStartDate = new Date(nextEndDate);
        nextStartDate.setDate(nextEndDate.getDate() - targetDay);

        // 日付範囲を設定
        setDateRange([nextStartDate, nextEndDate]);

        return {
            nextStartDate: nextStartDate,
            nextEndDate: nextEndDate,
        };
    }

    const AdminUserChatLogInfoApiRequest = async (page, is_limit, nextStartDate, nextEndDate) => {
        const tmpPage=currentPage
        setCurrentPage(page);
        // リクエスト値をチェック
        let startDateStr = ""
        let nextStartDateStr = ""
        let endDateStr = ""
        let nextEndDateStr = ""

        if (execApiStartDate)
        {
            startDateStr = execApiStartDate
        }
        if (nextStartDate)
        {
            nextStartDateStr = nextStartDate.toLocaleDateString('ja-JP', radioLabelOptions) 
        }
        if (execApiEndDate)
        {
            endDateStr = execApiEndDate
        }
        if (nextEndDate)
        {
            nextEndDateStr = nextEndDate.toLocaleDateString('ja-JP', radioLabelOptions)
        }

        // 変化が無い場合はAPI実行しない
        if (startDateStr == nextStartDateStr && endDateStr == nextEndDateStr)
        {
            return
        }

        setExecApiStartDate(nextStartDate.toISOString())
        setExecApiEndDate(nextEndDate.toISOString())

        // リクエスト値の設定
        const t_user_id = tUser.id
        const range_start_year = nextStartDate.getFullYear();
        const range_start_month = nextStartDate.getMonth() + 1;
        const range_start_day = nextStartDate.getDate();
        const range_end_year = nextEndDate.getFullYear();
        const range_end_month = nextEndDate.getMonth() + 1;
        const range_end_day = nextEndDate.getDate();

        // 通信中の表示を出す
        dispatch(setLoading(true));
        const result = await ExecAdminUserChatLogInfoApi({
            "page":page,
            "is_limit":is_limit,
            "t_user_id": t_user_id,
            "range_start_year": range_start_year,
            "range_start_month": range_start_month, 
            "range_start_day": range_start_day, 
            "range_end_year": range_end_year,
            "range_end_month": range_end_month, 
            "range_end_day": range_end_day
        });

        // 通信中の表示を消す
        dispatch(setLoading(false));
        if (result.error || (result.response && result.response.error_code > 0))
        {
            setCurrentPage(tmpPage);
            // エラーモーダル表示
            dispatch(setErrorModal({ show: true, text: result.response.error_detail }));
        }
        else 
        {
            CreateChatLogListData(result.response.t_chat_log_list)
            setTotalPages(result.response.max_page)
            dispatch(setInitializeState(Constants.INITIALIZE_STATE.pageDataFetchComplete))
        }
    };

    const AdminUserChatLogInfoApiRequestForCsvDownload = async (page, is_limit, nextStartDate, nextEndDate) => {

        // リクエスト値の設定
        const t_user_id = tUser.id
        const range_start_year = nextStartDate.getFullYear();
        const range_start_month = nextStartDate.getMonth() + 1;
        const range_start_day = nextStartDate.getDate();
        const range_end_year = nextEndDate.getFullYear();
        const range_end_month = nextEndDate.getMonth() + 1;
        const range_end_day = nextEndDate.getDate();

        // 通信中の表示を出す
        dispatch(setLoading(true));
        const result = await ExecAdminUserChatLogInfoApi({
            "page":page,
            "is_limit":is_limit,
            "t_user_id": t_user_id,
            "range_start_year": range_start_year,
            "range_start_month": range_start_month, 
            "range_start_day": range_start_day, 
            "range_end_year": range_end_year,
            "range_end_month": range_end_month, 
            "range_end_day": range_end_day
        });

        // 通信中の表示を消す
        dispatch(setLoading(false));
        if (result.error || (result.response && result.response.error_code > 0))
        {
            // エラーモーダル表示
            dispatch(setErrorModal({ show: true, text: result.response.error_detail }));
        }
        else 
        {
            dispatch(setInitializeState(Constants.INITIALIZE_STATE.pageDataFetchComplete));
            const csvData = generateCSV(result.response.t_chat_log_list);
            const blob = new Blob([csvData], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = Constants.CHAT_LOG_CSV_FILE_NAME;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }
    };

    // CSVデータを生成する関数
    function generateCSV(data) {
        // ヘッダー行を定義
        const headers = ["IP", "質問", "返答", "日時"];
        
        // CSV用の配列を作成し、ヘッダーを追加
        const csvRows = [headers.join(",")];

        // データ行を作成
        data.forEach(item => {
            const dateObject = new Date(item.regdate);
            const year = dateObject.getFullYear();
            const month = dateObject.getMonth() + 1; // 月は0から始まるため+1する
            const day = dateObject.getDate();
            const hours = dateObject.getHours();
            const minutes = dateObject.getMinutes();
            const seconds = dateObject.getSeconds();
            const row = [
                item.ip_address,
                `"${item.question}"`,  // カンマや特殊文字がある可能性があるため、ダブルクオートで囲む
                `"${item.answer}"`,    // 同上
                item.regdate = `${year}年${month}月${day}日${hours}時${minutes}分${seconds}秒`
            ];
            csvRows.push(row.join(","));
        });

        // CSVのテキストを結合
        return csvRows.join("\n");
    }

    // 表示期間ラジオボタンの変更
    const ChangeDateRadio = (value) => {

        // 選択中のラジオボタンをセット
        setDateRadioCheck(value);
        // 日付選択をリセット
        setDispCustomDate(Constants.RangeLabelType[Constants.RANGE_TYPE_CUSTOM])

        // カスタムの場合
        if (value == Constants.RANGE_TYPE_CUSTOM)
        {
            return
        }

        const { nextStartDate, nextEndDate } = SetRangeByTargetDay(value)
        
        // API実行
        AdminUserChatLogInfoApiRequest(DEFAULT_PAGE, DEFAULT_LIMIT_FLG, nextStartDate, nextEndDate)
    };

    // 表示期間ラジオボタンのクリック
    const ClickDateRadio = (value) => {
        // カスタムの場合
        if (value == Constants.RANGE_TYPE_CUSTOM)
        {
            setIsDialogVisible(true)
        }
    };

    function CreateChatLogListData(responseTChatLogList)
    {
        // List用データの生成
		const listData = {
            columsize: {
                ip_address:1, question:3,  answer:3, regdate:2
            },
            title: {
                ip_address: "IP",
                question: "質問",
                answer: "返答",
                regdate: "日時",
            },
			value: []
		}

		// 使用するデータ
		let tChatLogList;
		tChatLogList = Object.values(responseTChatLogList);

		if(tChatLogList.length > 0) {		
			tChatLogList.forEach((tChatLog) => {

				// データの整形
				const tChatLogData = {};
				// そのまま使えるデータを入れる
				tChatLogData.ip_address = tChatLog.ip_address;
                tChatLogData.question=tChatLog.question;
                tChatLogData.answer=tChatLog.answer
                const dateObject = new Date(tChatLog.regdate);
                const year = dateObject.getFullYear();
                const month = dateObject.getMonth() + 1; // 月は0から始まるため+1する
                const day = dateObject.getDate();
                const hours = dateObject.getHours();
                const minutes = dateObject.getMinutes();
                const seconds = dateObject.getSeconds();
                tChatLogData.regdate = `${year}年${month}月${day}日${hours}時${minutes}分${seconds}秒`

				// 各データのクリック時処理
				const onClick = () => {
                    
				};

				// リスト用データの作成
				listData.value.push({
					data: tChatLogData,
					title: tChatLogData,
					onclick: onClick
				});
			});
		}
		setChatLogListData(listData);
    }

    const handlePageChange = (page) => {
        AdminUserChatLogInfoApiRequest(page, DEFAULT_LIMIT_FLG, startDate, endDate)
    };

    const csvDownload = () => {
        AdminUserChatLogInfoApiRequestForCsvDownload(DEFAULT_PAGE, 0, startDate, endDate)
    };

    return (
    <PageContent pageId="AdminUserChatLog">
        <PageHeader>
            {/* ここにページ内ヘッダー部分のコンポーネントを記述 */}
            <Stack className="spacing-4px">
                <div className="page-header-title">ログ</div>
                <div className="page-header-detail">広報担当AIが応答したチャットのログを確認できます</div>
            </Stack>
        </PageHeader>
        <PageWrapper>
            <PageContainer>
                <Card className = "base-card">
                    <div className="data-radio-stack">
                        <Row className= "row-stack-between">
                            <DateRadio 
                                name="range-date" 
                                value={RangeLabelTypeKeys} 
                                diffValue={DispRangeLabelTypeValues} 
                                checked={dateRadioCheck} 
                                onclick={(e) => ClickDateRadio(e)} 
                                onchange={(e) => ChangeDateRadio(e)} 
                                isLoading={(initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false}
                            />
                            <Button className={"dl-button "+(isDownloadCsv ? "primary": "primary disabled")} onClick={csvDownload}>DL</Button>
                        </Row>
                    </div>
                    <Stack className="spacing-16px align-center addmergin">
                        <div className="calender-container">
                            {isDialogVisible && (
                            <div className="calender-dialog" ref={dialogRef}>
                                <CustomDateRangePicker
                                    selected={startDate}
                                    onChange={handleDateChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    inline
                                    handleCancel={handleCancel}
                                    handleUpdate={handleUpdate}
                                    minDate ={minDate}
                                    maxDate ={maxDate}
                                    isLoading={(initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false}
                                />
                            </div>
                            )}
                        </div>
                        <FitList
                            list={chatLogListData}
                            header={true}
                            scrollbar={"scrollbar"}
                            isLoading={(initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false}
                        />
                        <Pagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            onChange = {handlePageChange}
                            isLoading={(initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false}
                        />
                    </Stack>
                </Card>
            </PageContainer>
        </PageWrapper>
    </PageContent>
    );
};

export default AdminUserChatLog;