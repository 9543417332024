import * as Constants from "_core/configs/Constants";
import * as SceneConfig from "_core/configs/SceneConfig";
import dataManagerInstance from "_core/managers/DataManager";
import { getTime, waitSecond } from "_core/utils/common";

/**
 * 汎用のAPIリクエスト実行
 * @param {string} url 
 * @param {string} method 
 * @param {array} requestParams 
 * @returns 
 */
export const ApiRequest = async ({ url, method = 'POST', requestParams = {}, timeout = 60000 ,requestBinaryParams = {}, auth = true} ) => {

    // リクエストパラメータを取得(設定されたパラメータ内容によって変動)
    const GetRequestParams = (requestParams, requestBinaryParam) => {
        if(Object.keys(requestBinaryParam).length >= 1)
        {
            // リクエストパラメータを準備
            const params = new FormData();
            for (const key in requestParams) {
                if (requestParams.hasOwnProperty(key)) {
                    params.append(key, requestParams[key]);
                }
            }
            for ( const key in requestBinaryParam)
            {
                if (requestBinaryParam.hasOwnProperty(key)) {
                    params.append(key, requestBinaryParam[key]['file'], requestBinaryParam[key]['filename']);
                }
            }

            return params;
        }

        // リクエストパラメータを準備
        const params = new URLSearchParams();
        for (const key in requestParams) {
            if (requestParams.hasOwnProperty(key)) {
                params.append(key, requestParams[key]);
            }
        }
        return params;
    }

    const isBinaryRequestParam = Object.keys(requestBinaryParams).length >= 1;

    // リクエストパラメータを準備
    // const params = new URLSearchParams();
    // for (const key in requestParams) {
    //     if (requestParams.hasOwnProperty(key)) {
    //         params.append(key, requestParams[key]);
    //     }
    // }
    const params = GetRequestParams(requestParams, requestBinaryParams);

    // 多重実行チェック
    let nowTime = getTime()

    const oldApiTimestamp = dataManagerInstance.getApiTimestamp()
    // APIタイムスタンプが前回送信時と同じであれば、1秒待機後に送信する
    if(nowTime == oldApiTimestamp)
        {
        await waitSecond(1);
        nowTime = getTime()
    }

    if(auth)
    {
        params.append("api_timestamp", nowTime);
        params.append("is_editor", true);
    }

    const scene = url.split('/')[1];
    const isAdminScene = SceneConfig.ADMIN_CHECK_SCENE_LIST.includes(scene);

    const tUser = dataManagerInstance.getTUser();
    const tAccount = dataManagerInstance.getTAccount();
    const sid = dataManagerInstance.getSid();
    const adminSid = dataManagerInstance.getAdminSid()
    // const hasSid = (sid !== "")
    // const hasAdminSid = (adminSid !== "")

    // タイムアウト設定
    const controller = new AbortController();
    setTimeout(() => { controller.abort() }, timeout);

    try {
        let headers = {
            'accept': 'application/json',
            // APIサーバーとドメインが異なる場合は必要
            // 'Access-Control-Allow-Origin': 'http://localhost:3000'
        };
        if(isAdminScene)
        {
            headers['sid'] = adminSid;
        }
        else if(auth)
        {
            headers['sid'] = sid;
        }
        if(!isBinaryRequestParam)
        {
            headers['Content-Type'] = 'application/x-www-form-urlencoded';
        }

        // console.log('ApiRequest 開始 headers = '+ headers.toString());
        // APIリクエスト開始
        console.log('ApiRequest 開始 url = ' + Constants.BASE_URL + url);
        let option = {
            mode: 'cors',
            method: method,
            headers: headers,
            signal: controller.signal,
        };

        if (method === 'POST')
        {
            option['body'] =  isBinaryRequestParam ? params : params.toString();
        }

        const response = await fetch(Constants.BASE_URL + url, option);
        console.log('ApiRequest 終了');

        // レスポンスがOK出ない場合はエラーを返す
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.json();
        return result;
    } catch (error) {
        // console.log('ApiRequest エラー');
        throw error;
    }
};
