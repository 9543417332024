// library imports
import { Route, Routes } from "react-router-dom";

// User Component imports
import * as SceneConfig from "_core/configs/SceneConfig";
import PaymentPlan from "./parts/PaymentPlan";
import PaymentSetting from './parts/PaymentSetting';
import PaymentHistory from "./parts/PaymentHistory";

const PaymentScene = () => {

    return (
        <div id="Payment" className="scene-parent-root">
            <Routes>
                <Route path='' element={<PaymentPlan />} />
                <Route path={SceneConfig.PARTS_PAYMENT_PLAN}          element={<PaymentPlan />} />
                <Route path={SceneConfig.PARTS_PAYMENT_SETTING}       element={<PaymentSetting />} />
                <Route path={SceneConfig.PARTS_PAYMENT_HISTORY}       element={<PaymentHistory />} />
            </Routes>
        </div>
    );
};

export default PaymentScene;