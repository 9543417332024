// library imports
import { createSlice } from "@reduxjs/toolkit";

// User Component imports
import * as SceneConfig from "_core/configs/SceneConfig";
import * as Constants from "_core/configs/Constants";
import * as LocalStorageUtils from "_core/utils/localstorage";

const previousIsMenu = LocalStorageUtils.GetItem(Constants.LOCALSTORAGE_KEYS.isMenuOpen);

const initialState = {
    // シーンとパーツ
    scene: SceneConfig.SCENE_ACCOUNT,
    parts: SceneConfig.PARTS_TOP,
    // ローディング表示
    isLoading: false,
    // エラーモーダル表示とテキスト
    errorModalInfo: {
        show: false,
        text: '',
    },
    // モーダル表示とテキスト
    modalInfo: {
        show: false,
        text: '',
        onClick: null,
    },
    // ヘッダー表示とテキスト
    isHeader: true,
    headerText: 'ヘッダー',
    // フッター表示
    isFooter: true,
    // メニューバー表示
    isMenu: true,
    // メニューバー開閉
    isMenuOpen: (previousIsMenu === "true"),
    // 初期化状態
    initializeState: Constants.INITIALIZE_STATE.before,
    // get形式で渡されたクエリ
    sceneQuery:{},
    // シーン間で受け渡すオブジェクト
    sceneParam:{},
    // 全画面表示シーン
    isFullScreen: false,
};


const SceneSlice = createSlice({
    name: "scene",
    initialState,
    reducers: {
        /**
         * シーン情報をセット(MainScene.js以外で明示的に呼び出す想定なし)
         * payload: {
         *   scene:string  新しいscene(指定がなければ現状維持)
         *   parts:string  新しいparts(指定がなければTOP)
         * }  
         */
        setSceneParts: (state, action) => {
            if (('scene' in action.payload)) {
                state.scene = action.payload.scene;
            }
            if (!('parts' in action.payload)) {
                state.parts = SceneConfig.PARTS_TOP;
            }
            else {
                state.parts = action.payload.parts;
            }
        },

        /**
         * ローディング表示フラグ切り替え
         * payload: boolean  ローディング表示フラグ
         */
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },

        /**
         * モーダル表示フラグ切り替え、テキスト設定
         * payload: {
         *   show:boolean  モーダル表示フラグ
         *   text:string   モーダルテキスト
         * }
         */
        setErrorModal: (state, action) => {
            state.errorModalInfo = action.payload
        },

        /**
         * モーダル表示フラグ切り替え、テキスト設定
         * payload: {
         *   show:boolean  モーダル表示フラグ
         *   text:string   モーダルテキスト
         * }
         */
        setCommonModal: (state, action) => {
            state.modalInfo = action.payload
        },

        /**
         * ヘッダー、フッター、メニューの状態切替
         * payload: {
         *   isHeader:boolean   ヘッダー表示フラグ
         *   headerText:string  ヘッダーテキスト
         *   isFooter:boolean   フッター表示フラグ
         *   isMenuOpen:boolean     メニュー表示フラグ
         * }
         */
        setMenuState: (state, action) => {
            const update = action.payload;
            // console.log(update);
            const checkKeys = ['isHeader', 'headerText', 'isFooter', 'isMenu', 'isMenuOpen', 'isFullScreen'];
            for (const key in update) {
                // スライスで保持しているデータとキーが一致、かつ設定可能なキーの場合に更新
                if (key in state && checkKeys.includes(key)) {
                    state[key] = update[key];
                    LocalStorageUtils.SetItem(key, String(update[key]));
                }
            }
        },

        /**
         * スライス内に該当するデータを更新する
         * payload: {
         *   isLoading: boolean  ローディングを閉じて
         *   isModal: boolean    モーダルを表示する
         *   modalText: string   モーダルに表示するテキスト設定
         * }
         */
        setInitializeState: (state, action) => {
            state["initializeState"] = action.payload;
        },

        /**
         * スライス内に該当するデータを更新する
         * payload: {
         *   isLoading: boolean  ローディングを閉じて
         *   isModal: boolean    モーダルを表示する
         *   modalText: string   モーダルに表示するテキスト設定
         * }
         */
        setSceneData: (state, action) => {
            const update = action.payload;
            // 更新想定データをチェック
            for (const key in update) {
                // スライスで保持しているデータとキーが一致、かつ値が変わっていれば更新
                if (key in state && state[key] !== update[key]) {
                    state[key] = update[key];
                }
            }
        },

        setSceneParam: (state, action) => {
            const update = action.payload;
            // 更新想定データをチェック
            state["sceneParam"] = update;
        },

        setSceneQuery: (state, action) => {
            const update = action.payload;
            // 更新想定データをチェック
            state["sceneQuery"] = update;
        },

        /**
         * フルスクリーン表示フラグ切り替え
         * payload: boolean  フルスクリーン表示フラグ
         */
        setFullScreen: (state, action) => {
            state.isFullScreen = action.payload;
        },
    },
});

export const { setSceneParts, setLoading, setErrorModal, setCommonModal, setMenuState, setSceneData, setInitializeState, setSceneParam, setSceneQuery, setFullScreen} = SceneSlice.actions;
export default SceneSlice.reducer;