import React , { useEffect, useState, useRef } from 'react'
// styles imports
import './TextInput.scss';

const TextInput = (props) => {
	// State準備
	const [type, setType] = useState(props.type ? props.type : "text")
	const [value, setValue] = useState(props.default);
	const isTouched = useRef(false);  // useRefで状態を管理
	const inputRef = useRef(null);

	useEffect(() => {
		if (isTouched.current) 
		{
			return
		}

		setValue(props.default)
	
	}, [props.default]);

	useEffect(() => {
		if (! props.value){
			return
		}
		if (inputRef.current) {
			inputRef.current.value = props.value; // DOMを直接操作
			setValue(props.value)
		}
	}, [props.value]);

	const clearValueFirst = () => {
		if (isTouched.current) 
		{
			return
		}

		if(props.firstClear)
		{
			setValue("");
		}
		
		isTouched.current = true;  // フラグを更新
	}

	// 追加の処理があれば実行
	const onFocus = (e) => {
		if (props.type=="password") {
			setType("text")
		}
		clearValueFirst()
		props.onfocus && props.onfocus(e)
	}
	const onBlur = (e) => {
		if (props.type=="password") {
			setType("password")
		}
		props.onblur && props.onblur(e)
	}
	const onChange = (e) => {
		setValue(e.target.value);
		props.onchange && props.onchange(e);
	}

 // コンポーネントの返却
	if (props.isLoading)
	{
		return (
			<div
				/* set */
				id={props.name}
				className={"textinput loading"}
			>
			</div>
		);
	}

	return (
		<input
			/* set */
			id={props.name}
			name={props.name}
			type={type}
			value={value}
			className={"textinput " + (props.className ? props.className : "")}
			title={props.default}
			placeholder={(props.placeholder) ? props.placeholder : "input " + ((props.type) ? props.type : "text")}
			aria-label={(props.placeholder) ? props.placeholder : "input " + ((props.type) ? props.type : "text")}
			disabled={props.disabled}
			autoComplete={props.autocomplete ? props.autocomplete : "new-off"}
			/* dataset */
			data-json={props.dataset}
			/* act */
			onFocus={onFocus}
			onBlur={onBlur}
			onChange={onChange}
			/* ref */
			ref={inputRef}
		/>
	);
}
export default TextInput
