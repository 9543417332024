import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setErrorModal } from '../../slices/scene/SceneSlice';
import { Stack, Modal, HTMLRenderer, Text, Icon } from "_core/components/common/UI";

import './ErrorModal.scss'
import Row from './UI/Row';

const ErrorModal = () => {
    const dispatch = useDispatch();
    const { errorModalInfo } = useSelector((store) => store.scene);
    const [modalInstance, setErrorModalInstance] = useState({ class: '', body: <></> });

    useEffect(() => {
        UpdateModal();
    }, [errorModalInfo.show]);

    // 編集モーダルを開く
	const UpdateModal = () => {
		setErrorModalInstance({
			class: errorModalInfo.show ? 'show' : '',
			body: modalBody()
		});
	};

       //モーダルのボディ
	const modalBody = () => {

        let modalTextElement = "";
        if (errorModalInfo.text){
            modalTextElement = errorModalInfo.text.message.replace(/\r\n/g, '\n').replace(/\r/g, '\n').replace(/\r/g, '</br>')
            console.log(modalTextElement);
        }

		return(
            <Stack className="error-modal-message spacing-16px padding-16px">
                <Row className="spacing-16px">
                    <Stack className="flex-center">
                        <Icon icon="x-circle" size={42} className="error-icon"/>
                    </Stack>
                    <Stack className="spacing-8px">
                        <Text className="text-size-large">エラー: {errorModalInfo.text.code}</Text>
                        <HTMLRenderer htmlContent={modalTextElement}></HTMLRenderer>
                    </Stack>
                </Row>
            </Stack>
		)
	}

    function ShowModal() {
        return (
            <Modal
                className={modalInstance.class}
                dialogclass="modal-dialog-centered"
                name="error-modal"

                showClose={false}
                close={() => {dispatch(setErrorModal({ show: false, text: "" }));}}
                
                showConfirm={false}
                showCancel={true}
                cancel={() => {dispatch(setErrorModal({ show: false, text: "" }));}}
                cancelbtnclass="primary-border"
                cancelvalue={"確認"}
                
                body={modalInstance.body}
            />
        );
    }

    return <ShowModal />
}

export default ErrorModal