// library imports
import { useDispatch } from "react-redux";

// User Component imports
import { ApiRequest } from "_core/apis/ApiRequest";
import { setApiTimestamp, setTransactionData } from "_core/slices/datas/TransactionSlice";


const AdminUserInfoApi = () => {
    const dispatch = useDispatch();

    // API固有の設定、URLとリクエスト
    const url = "/admin/user_info";

    // API実行
    const ExecAdminUserInfoApi = async ({page, word}) => {

        // 必要なリクエストを定義
        const requestParams={
            page : page
        }
        if (word !== null && word !== "")
        {
            requestParams["word"] = word;
        }
        let response = null;
        let error = null;
    
        // console.log(url + "API開始");
        try {
            response = await ApiRequest({  url:url, requestParams:requestParams });
            // console.log(response);
        } catch (e) {
            // console.log(e);
            error = e;
        } finally {
            // console.log(url + "　API終了");
        }

        // レスポンスがあれば、データ更新
        if (response !== null && response.error_code === 0) {
            dispatch(setApiTimestamp(response['now_time']));
        }
        return { response, error };
    };

    return ExecAdminUserInfoApi;
};

export default AdminUserInfoApi;