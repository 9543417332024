import React , { useState } from 'react'
import { Stack, Text, TextInput } from "_core/components/common/UI";
// styles imports
import './TextInputWithLabel.scss';

const TextInputWithLabel = (props) => {

 // コンポーネントの返却
	return (
		<div className={'textinput-with-label ' + (props.parentclass ? props.parentclass : "")}>
			<Stack className="spacing-4px">
				<Text className={'label text-size-small' + (props.labelclass ? props.labelclass : "")} isLoading={props.isLoading}>{props.label}</Text>
				<TextInput {...props}></TextInput>
			</Stack>
		</div>
	);
}
export default TextInputWithLabel
