// library imports
import { useDispatch } from "react-redux";

// User Component imports
import { ApiRequest } from "_core/apis/ApiRequest";
import { setMasterData } from "_core/slices/datas/MasterSlice";
import { setApiTimestamp, setTransactionData } from "_core/slices/datas/TransactionSlice";


const AdminUserAssetDeleteApi = () => {
    const dispatch = useDispatch();

    // API固有の設定、URLとリクエスト
    const url = "/admin/user_asset_delete";

    // API実行, asset_type, file ,file_name
    const ExecAdminUserAssetDeleteApi = async (tid,asset_type) => {

        // 必要なリクエストを定義
        const requestParams = {
            tid: tid,
            asset_type:asset_type,
        };
    
        let response = null;
        let error = null;
    
        // console.log(url + "API開始");
        try {
            response = await ApiRequest({  url:url, requestParams:requestParams});
            // console.log(response);
        } catch (e) {
            // console.log(e);
            error = e;
        } finally {
            // console.log(url + "　API終了");
        }

        // レスポンスがあれば、データ更新
        if (response !== null && response.error_code === 0) {
            dispatch(setApiTimestamp(response['now_time']));
            if(response['t_user_add'] !=null)
            {
                dispatch(setTransactionData({
                    tUserAdd:response['t_user_add'],
                }));
            }

            if(response['t_asset_list'] !=null)
            {
                dispatch(setTransactionData({
                    tAssetList:response['t_asset_list'],
                }));
            }
        }
        return { response, error };
    };

    return ExecAdminUserAssetDeleteApi;
};

export default AdminUserAssetDeleteApi;