// library imports
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// User Component imports
import { Button, List, Row, Stack, TextInput, Icon } from "_core/components/common/UI";
import * as Constants from "_core/configs/Constants";
import { setLoading, setMenuState, setErrorModal, setInitializeState } from "_core/slices/scene/SceneSlice";
import { ReverseKeyValue } from "_core/utils/common";
import AdminUserInfoApi from "apis/AdminUserInfoApi";
import { PageContainer, PageContent, PageHeader, PageWrapper } from 'components/common/layout_element/LayoutElement';


// styles imports
import * as SceneConfig from "_core/configs/SceneConfig";
import './AdminUserInfo.scss';

const AdminUserInfo = () => {
    const dispatch = useDispatch();
    const { tAccount } = useSelector((store) => store.transaction);
    const navigate = useNavigate();
    const ExecAdminUserInfoApi = AdminUserInfoApi();

    const INITIAL_PAGE = 1
    const [searchWord, setSearchWord] = useState("");
    const [tempSearchWord, setTempSearchWord] = useState("");
    const [currentPage, setCurrentPage] = useState(INITIAL_PAGE);
    const { initializeState } = useSelector((store) => store.scene);

    const [maxPage, setMaxPage] = useState(INITIAL_PAGE);
    const [minPage, setMinPage] = useState(INITIAL_PAGE);

    useEffect(() => {
        dispatch(setMenuState({ isHeader: true, isMenu: true, isFooter: false, isFullScreen: false }));
    }, [dispatch]);

    useEffect(() => {
        if(initializeState !== Constants.INITIALIZE_STATE.allComplete)
        {
            return
        }
        dispatch(setInitializeState(Constants.INITIALIZE_STATE.pageDataFetching))
        // API実行
        AdminUserInfoApiRequest(1, searchWord);

    }, [initializeState]);

    function MoveUserEdit(tUserId)
    {
        navigate(SceneConfig.PAGES.ADMIN_USER_EDIT+"?id="+tUserId);

    };
    function MoveUserCreate()
    {
        navigate(SceneConfig.PAGES.ADMIN_USER_CREATE);
    };
    function MoveUserMultiCreate()
    {
        navigate(SceneConfig.PAGES.ADMIN_USER_MULTI_CREATE);
    };

    function MovePage(isNext) {
        if (isNext && currentPage < maxPage) 
        {
            setCurrentPage(currentPage + 1);
            // API実行
            AdminUserInfoApiRequest(currentPage + 1, searchWord);
        }
        else if (!isNext && currentPage > minPage) 
        {
            setCurrentPage(currentPage - 1);
            // API実行
            AdminUserInfoApiRequest(currentPage - 1, searchWord);
        }
    }

    function ExecSerch()
    {
        setSearchWord(tempSearchWord)
        setCurrentPage(INITIAL_PAGE)
        // API実行
        AdminUserInfoApiRequest(INITIAL_PAGE, tempSearchWord);
    }

    const AdminUserInfoApiRequest = async (nextPage, requestWord) => {
        // 通信中の表示を出す
        dispatch(setLoading(true));

        // const result = await ExecAdminUserInfoApi({page : nextPage, word: requestWord});
        
        // 通信中の表示を消す
        dispatch(setLoading(false));
        dispatch(setInitializeState(Constants.INITIALIZE_STATE.pageDataFetchComplete))
        // if (result.error || (result.response && result.response.error_code > 0)) {
        //     // エラーモーダル表示
        //     dispatch(setErrorModal({ show: true, text: result.response.error_detail }));
        // }
        // else 
        // {
        //     dispatch(setInitializeState(Constants.INITIALIZE_STATE.pageDataFetchComplete))
        //     const pageCount = Math.max(1, Math.ceil(result.response.count / Constants.PAGE_ITEM_COUNT.UserInfo));
        //     setMaxPage(pageCount)
        //     CreateMemberListData(result.response.t_user_list)
        // }
    };

    function CreateMemberListData(userDataList)
    {
        // List用データの生成
		const listData = {
            columsize: {
                name:2, address:5, auth:1, register_date:3, service_status:2
            },
            title: {
                name: "名前",
                address: "メールアドレス",
                auth: "権限",
                register_date: "登録日",
                service_status: "ステータス",
            },
			value: []
		}

		// 使用するデータ
		let memberList;
		memberList = Object.values(userDataList);

		if(memberList.length > 0) {		
			memberList.forEach((member) => {

				// データの整形
				const memberData = {};
				// そのまま使えるデータを入れる
				memberData.name = member.name;
                memberData.address=member.address;
                memberData.auth=Constants.UserAuthType[parseInt(member.auth)];
                memberData.register_date=member.register_date.replace("T", " ");
                memberData.service_status=parseInt(member.service_status) === 1 ? "有効" : "無効";

				// 各データのクリック時処理
				const onClick = () => {
                    MoveUserEdit(member.id)
				};

				// リスト用データの作成
				listData.value.push({
					data: memberData,
					title: memberData,
					onclick: onClick
				});
			});
		}

		setMemberListData(listData);
    }

	// メンバーリストデータ
	const [memberListData, setMemberListData] = useState({
		columsize: {
			name:2, address:5, auth:1, register_date:3, service_status:2
		},
		title: {
			name: "名前",
			address: "メールアドレス",
			auth: "権限",
			register_date: "登録日",
            service_status: "ステータス",
		},
		value: []
	});

    return (
    <PageContent pageId="AdminUserInfo">
            <PageHeader>
                {/* ここにページ内ヘッダー部分のコンポーネントを記述 */}
                <div className="page-header-title">ユーザー一覧</div>
            </PageHeader>
            <PageWrapper>
                <PageContainer>
                    {/* ここにページ内コンテンツのコンポーネントを記述 */}
                    <Stack className= "spacing-16px">
                        <Row className="spacing-16px flex-both">
                            <Row className="search-area spacing-16px align-center">
                                <TextInput onchange={(e) => setTempSearchWord(e.target.value)} placeholder="検索するキーワードを入力"/>
                                <Button className="search-button hidden flex flex-center padding-8px" onClick={() => ExecSerch()}>
                                    <Stack className="flex-center">
                                        <Icon icon="search" size={16}/>
                                    </Stack>
                                </Button>
                            </Row>
                            <Row className="spacing-16px create-area">
                                <Row className="spacing-16px create-area">
                                    <Button className="primary" onClick={() => MoveUserCreate()}>ユーザー追加</Button>
                                    <Button className="primary" onClick={() => MoveUserMultiCreate()}>一括インポート</Button>  
                                </Row>
                                <Row className="paging-button-area spacing-16px align-center">
                                    <Button className="hidden flex flex-center" onClick={() => MovePage(false)}><Icon icon="chevron-left" size={16} /></Button>
                                    <div className="text-middle">{currentPage + "/" + maxPage}</div>
                                    <Button className="hidden flex flex-center" onClick={() => MovePage(true)}><Icon icon="chevron-right" size={16} /></Button>
                                </Row>
                            </Row>
                        </Row>
                        <List
                            list={memberListData}
                            header={true}
                            scrollbar={"scrollbar"}
                            className=""
                            isLoading={(initializeState < Constants.INITIALIZE_STATE.pageDataFetchComplete) ? true : false}
                        />
                    </Stack>
                </PageContainer>
            </PageWrapper>
    </PageContent>
    );
};

export default AdminUserInfo;