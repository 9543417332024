// library imports
import { useDispatch } from "react-redux";

// User Component imports
import { ApiRequest } from "_core/apis/ApiRequest";
import { setApiTimestamp, setTransactionData } from "_core/slices/datas/TransactionSlice";
import { setMasterData } from "_core/slices/datas/MasterSlice";

const PaymentInfoApi = () => {
    const dispatch = useDispatch();

    // API固有の設定、URLとリクエストd
    const url = "/payment/info";

    // API実行
    const ExecPaymentInfoApi = async ({}) => {
        // 必要なリクエストを定義

        const requestParams = {
        };

        let response = null;
        let error = null;

        // console.log(url + "API開始");
        try {
            response = await ApiRequest({ url, requestParams});
            // console.log(response);
        } catch (e) {
            // console.log(e);
            error = e;
        } finally {
            // console.log(url + "　API終了");
        }

        // レスポンスがあれば、データ更新
        if (response !== null && response.error_code === 0) {
            dispatch(setApiTimestamp(response['now_time']));
            dispatch(setMasterData({
                mPayment:response['m_payment_list']}
            ));
            dispatch(setTransactionData({
                tPayment:response['t_payment'],
                tSubscription:response['t_subscription'],
                cardInfo:response['card_info']
            }));
        }
        return { response, error };
    };

    return ExecPaymentInfoApi;
};

export default PaymentInfoApi;