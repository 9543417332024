import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import Error403 from '_core/components/error/Error403';
import Error404 from '_core/components/error/Error404';
import * as SceneConfig from '_core/configs/SceneConfig';
import dataManagerInstance from '_core/managers/DataManager';
import { setSceneQuery, setSceneParts, setInitializeState } from '_core/slices/scene/SceneSlice';
import * as Constants from "_core/configs/Constants";
import * as LocalStorageUtils from "_core/utils/localstorage";

import AccountScene from 'components/account/AccountScene';
import AdminScene from 'components/admin/AdminScene';
import PaymentScene from 'components/payment/PaymentScene';

import AdminUserLoginApi from 'apis/AdminUserLoginApi';
import AdminReloadApi from 'apis/AdminReloadApi';
import UsersReloadApi from 'apis/UsersReloadApi';

function MainScene() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const ExecAdminUserLoginApi = AdminUserLoginApi();
    const ExecAdminReloadApi = AdminReloadApi();
    const ExecUsersReloadApi = UsersReloadApi();

    const { initializeState, isFullScreen, isMenu } = useSelector((store) => store.scene);
    const sceneState = useSelector((store) => store.scene);

    // URLからscene、parts情報をセット
    const paths = location.pathname.split('/');
    const scene = (paths.length <= 1 || paths[1] === '') ? SceneConfig.SCENE_ACCOUNT : paths[1];
    const parts = (paths.length <= 2 || paths[2] === '') ? SceneConfig.PARTS_TOP : paths[2];

    const AdminUserLoginApiRequest = async () => {

        const result = await ExecAdminUserLoginApi();
        if (result.error || (result.response && result.response.error_code > 0)) {
            // エラーモーダル表示
            LocalStorageUtils.SetItem(Constants.LOCALSTORAGE_KEYS.sid, "");
            navigate(SceneConfig.PAGES.ACCOUNT_TOP);
        }
        else
        {
            dispatch(setInitializeState(Constants.INITIALIZE_STATE.tAccountFetchComplete))
        }
    };

    const AdminReloadApiRequest = async () => {

        const result = await ExecAdminReloadApi();
        if (result.error || (result.response && result.response.error_code > 0)) {
            // エラーモーダル表示
            LocalStorageUtils.SetItem(Constants.LOCALSTORAGE_KEYS.adminSid, "");
            AdminUserLoginApiRequest();
        }
        else
        {
            dispatch(setInitializeState(Constants.INITIALIZE_STATE.tAccountFetchComplete))
        }
    };

    const UsersReloadApiRequest = async () => {

        const result = await ExecUsersReloadApi();
        // 通信中の表示を消す
        if (result.error || (result.response && result.response.error_code > 0)) {
            // エラーモーダル表示
            LocalStorageUtils.SetItem(Constants.LOCALSTORAGE_KEYS.adminSid, "");
            navigate(SceneConfig.PAGES.ACCOUNT_TOP);
        }
        else
        {
            dispatch(setInitializeState(Constants.INITIALIZE_STATE.tUserFetchComplete))
        }
    };

    const TUserRefresh = async () => {
        // メイン機能で未ログインのチェック
        const tUser = dataManagerInstance.getTUser();
        if (tUser)
        {
            dispatch(setInitializeState(Constants.INITIALIZE_STATE.tUserFetchComplete))
            return
        }

        if (! tUser) {
            if (dataManagerInstance.getSid() !== "") {
                await UsersReloadApiRequest();
            }
            else 
            {
                navigate(SceneConfig.PAGES.ACCOUNT_TOP);
            }
        }
    }

    const TAccountRefresh = async () => {
        // 管理画面で未ログイン時のチェック
        if (SceneConfig.ADMIN_CHECK_SCENE_LIST.includes(scene)) {
            const tAccount = dataManagerInstance.getTAccount();
            if(! tAccount)
            {
                if (dataManagerInstance.getAdminSid() !== "") {
                    await AdminReloadApiRequest();
                }
                else
                {
                    await AdminUserLoginApiRequest();
                }
            }
            else
            {
                dispatch(setInitializeState(Constants.INITIALIZE_STATE.tAccountFetchComplete))
            }
        }
    }

    useEffect(() => {
        // URLが変更されていた場合のみ処理
        if(sceneState.scene !== scene || sceneState.parts !== parts)
        {
            const isEnablePage = Object.values(SceneConfig.PAGES).includes(location.pathname);
            if(! isEnablePage)
            {
                navigate(SceneConfig.PAGES.ERROR_404);
            }
            
            // URLからscene、parts情報をセット
            const sceneQuery = {}
            if (location.search.includes("?"))
            {
                const queries = location.search.split('?');
                queries
                .filter(query => query.includes("="))
                .forEach((query) => {
                    const keyvalue = query.split('=')
                    sceneQuery[keyvalue[0]] = keyvalue[1]
                });
            }
            
            dispatch(setSceneQuery(sceneQuery));
            dispatch(setSceneParts({ scene, parts }));
            dispatch(setInitializeState(Constants.INITIALIZE_STATE.ready))
        }
    }, [location]);

    // ユーザー情報再取得を管理するhook
    useEffect(() => {
        console.log(Object.keys(Constants.INITIALIZE_STATE).filter((key) => {return Constants.INITIALIZE_STATE[key] === initializeState;}));
        // エラーシーンの場合は再取得を行わない
        if (
            (scene === SceneConfig.SCENE_ERROR || (scene === SceneConfig.SCENE_ACCOUNT))
        ) 
        {
            dispatch(setInitializeState(Constants.INITIALIZE_STATE.pageDataFetchComplete))
            return;
        }
        
        // 取得実行中の場合はスキップ
        if(
            initializeState === Constants.INITIALIZE_STATE.tUserFetching
            || initializeState === Constants.INITIALIZE_STATE.tAccountFetching
            || initializeState === Constants.INITIALIZE_STATE.allComplete
            || initializeState === Constants.INITIALIZE_STATE.pageDataFetching
            || initializeState === Constants.INITIALIZE_STATE.pageDataFetchComplete
        )
        {
            return
        }

        // 取得準備完了の場合、ユーザー取得から順に実行開始
        if(initializeState === Constants.INITIALIZE_STATE.ready)
        {
            dispatch(setInitializeState(Constants.INITIALIZE_STATE.tUserFetching))
            TUserRefresh();
            return
        }

        // ユーザー取得実行完了の場合は、アカウント情報取得に移行
        if(initializeState === Constants.INITIALIZE_STATE.tUserFetchComplete)
        {
            dispatch(setInitializeState(Constants.INITIALIZE_STATE.tAccountFetching))
            TAccountRefresh();
            return
        }

        // 全ての工程を完了した場合にステートを更新
        if(initializeState === Constants.INITIALIZE_STATE.tAccountFetchComplete)
        {
            dispatch(setInitializeState(Constants.INITIALIZE_STATE.allComplete))
            return
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initializeState]);

    return (
        <div className={"content" + (isFullScreen ? " fullscreen" : "") + (isMenu ? "" : " menu-exclude") }>
            <Routes>
                <Route path='/' element={<AccountScene />} />
                <Route path='/account/*' element={<AccountScene />} />
                <Route path='/admin/*' element={<AdminScene />} />
                <Route path='/payment/*' element={<PaymentScene />} />

                {/* 想定外のURLはエラー */}
                <Route path='/error/403' element={<Error403 />} />
                <Route path='/error/404' element={<Error404 />} />
            </Routes>
        </div>
    );
}

export default MainScene;
