import { useEffect, useState } from "react";

// styles imports
import './Video.scss';

// メインのコンポーネント定義
export default function Video(props) {
  const [isLoaded, setIsLoaded] = useState(false)

  return (
    <div className={"video " + (props.className ? props.className : "") + (isLoaded ? "" : " loading")}>
      <div className="video-container">
        <video 
          className={"video-image"} 
          src={props.src} 
          onLoadedData={() => {setIsLoaded(true)}}
          autoPlay
          preload="auto"
          loop 
          muted 
          playsInline
          />
      </div>
    </div>
  );
}
