// Select.js

import React from 'react';
import './Select.scss';
import {Icon} from "_core/components/common/UI";

export const Select = (props) => {
    // 選択変更時のイベント
    const onChange = (e) => {
        props.onchange && props.onchange(e.target.value);
    };

    if (props.isLoading){
        return (<div className="select-box loading"></div>)
    }

    return (
        <div className="select-area">
            <select
                className={"select-box " +  (props.className ? props.className : "")}
                name={props.name}
                id={props.name}
                value={props.value} // 現在の選択中の値
                onChange={onChange}
                disabled={props.disabled}
            >
                {Object.keys(props.title).map((title, index) => {
                    return (<option key={index} value={props.title[title]}> {/* value を title に合わせる */}
                        {title}
                    </option>
                )})}
            </select>
            <Icon className="select-icon" icon="chevron-down" size={20} />
        </div>
    );
};

export default Select;
