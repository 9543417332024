// User Component imports
import { store } from "_core/store";

class DataManager {

    constructor() {
        this.master = store.getState().master;
        this.transaction = store.getState().transaction;
        // console.log("DataManager の constructor");
        store.subscribe(() => {
            // console.log("DataManager内で subscribe 検知");
            // ※master、transactionに関係ないデータが変わっても通知されるので負荷あれば修正検討
            this.master = store.getState().master
            this.transaction = store.getState().transaction
        });
    }
    
    // タイムスタンプ
    getApiTimestamp() {
        return this.transaction.apiTimestamp;
    }

    // マスターデータ

    /**
     * mChapterリストを取得
     * @returns 
     */
    getMChapterList() {
        // const state = store.getState().master;
        return this.master.mChapter;
    }

    /**
     * idを指定してmChapterを取得
     * @param {number} id 
     * @returns 
     */
    getMChapterById(id) {
        const data = this.master.mChapter.find(x => x.id === id);
        return data;
    }

    /**
     * mChapterIdを指定してmStageリストを取得
     * @param {number} mChapterId
     * @returns 
     */
    getMStageListByMChapterId(mChapterId) {
        // const state = store.getState().master;
        return this.master.mStage.filter(x => x.m_chapter_id === mChapterId);
    }

    /**
     * idを指定してmStageを取得
     * @param {number} id 
     * @returns 
     */
    getMStageById(id) {
        const data = this.master.mStage.find(x => x.id === id);
        return data;
    }
    

    // トランザクションデータ

    /**
     * sidを取得
     * @returns 
     */
    getSid()
    {
        return this.transaction.sid;
    }

    /**
     * 管理画面sidを取得
     * @returns 
     */
        getAdminSid()
        {
            return this.transaction.adminSid;
        }
    

    /**
     * tUserを取得
     * @returns 
     */
    getTUser()
    {
        return this.transaction.tUser;
    }

    /**
     * tAccountを取得
     * @returns 
     */
    getTAccount()
    {
        return this.transaction.tAccount;
    }

    /**
     * tUserを取得
     * @returns 
     */
    getTPayment()
    {
        return this.transaction.tPayment;
    }

    /**
     * tSubscriptionを取得
     * @returns 
     */
    getTSubscription()
    {
        return this.transaction.tSubscription;
    }
}

const dataManagerInstance = new DataManager();
export default dataManagerInstance;