import React, { useState, useEffect } from "react";
import "./Switch.scss";
import { Icon, Stack } from "_core/components/common/UI";

const Switch = (props) => {
    const [isChecked, setIsChecked] = useState(props.default);

    const handleToggle = () => {
        console.log(!isChecked);
        if (props.onchange)
        {
            props.onchange(!isChecked)
        }
        setIsChecked(!isChecked);
    };

    return (
        <label className="switch">
            <input
                type="checkbox"
                checked={isChecked}
                onChange={handleToggle}
            />
            <span className="slider">
                {/* 左側のアイコン */}
                <Stack className={`icon flex-center align-center width-full icon-right ${isChecked ? "" : "inactive"}`}>
                    <Icon icon="display" size={20} />
                </Stack>
                {/* 右側のアイコン */}
                <Stack className={`icon flex-center align-center width-full icon-left ${isChecked ? "inactive" : ""}`}>
                    <Icon icon="phone" size={20} />
                </Stack>
            </span>
        </label>
    );
};

export default Switch;
